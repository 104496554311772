<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="isLoading">
    Loading ...
  </div>
  <div v-else>
    <div class="clickable-div" style="margin-bottom: 0px; gap: 0px; top: 0px;" @click="expandedSymbol = !expandedSymbol">
      {{ expandedSymbol ? '▼' : '▶' }} Config settings
    </div>
    <div class="group-v" v-if="expandedSymbol">
      <div class="group-h" v-if="config === null">
        <button @click="loadConfig">Load config settings</button>
      </div>
      <div v-else>
        <button @click="loadConfig">Refresh config settings</button>
        <ConfigSettingsParSheetComponent
          :parSheet="config.Server.parSheet"
          :defaultValues="defaultValues.Server.parSheet"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/js/auth.js';
import { statusCodes } from '@/data/statusCodes.js';

import ConfigSettingsParSheetComponent from './ConfigSettingsParSheetComponent.vue';

export default {
  name: 'ConfigSettingsComponent',
  components: {
    ConfigSettingsParSheetComponent,
  },
  data() {
    return {
      isLoading: false,
      expandedSymbol: true,
      config: null,
      defaultValues: null,
    };
  },
  methods: {
    updateParSheetDefaultValues() {
      this.defaultValues.Server.parSheet.enabled = this.config.Server.parSheet.enabled;
      this.$root.settings.currentConfigHasParSheetEnabled = this.config.Server.parSheet.enabled;
    },
    toggleParSheetStatusValue() {
      this.config.Server.parSheet.enabled = !this.config.Server.parSheet.enabled;
    },
    loadConfig() {
      this.isLoading = true;
      const config = this.$root.getCurrentConfigId();
      fetch(`${this.$root.envVars.api}/api/admin2/configs/get?configId=${config}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.autToken()}`
          },
        })
        .then(response => {
          if (!response.ok) {
            if (response.status === statusCodes.StatusTokenIdMismatch) {
              auth.readWriteSet("");
              throw new Error('Ask for Write permissions');
            }
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          this.config = data.data.Info;
          this.$root.settings.currentConfigHasParSheetEnabled = this.config.Server.parSheet.enabled;
          this.defaultValues = JSON.parse(JSON.stringify(this.config));
          this.isLoading = false;
          this.$root.handleSuccess('Data loaded!');
        })
        .catch(error => {
          this.$root.handleError('Error fetching options:' + error);
          this.isLoading = false;
          this.config = null;
          this.defaultValues = null;
        });
    }
  },
  mounted() {
    this.loadConfig();
  }
}
</script>

<style scoped>
  .group-h {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    gap: 10px;
  }
  .config-settings-margin {
    margin-top: 10px;
  }
</style>
