<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="clickable-div" style="margin-bottom: 0px; gap: 0px; top: 0px;" @click="toggleSymbol()">
    <label :style="{ color: symbol.pays.pays[0] === 0 ? 'darkred' : 'inherit' }">
      {{ expandedSymbol ? '▼' : '▶' }} {{ symbol.name }}
    </label>
  </div>
  <div v-if="expandedSymbol">
    <hr class="separator" />
    <div>
      <!-- Display freqs per reel -->
      <div class="group-h" style="gap: 0px; border: 0px solid black;">
        <div v-for="i in numberOfReels" :key="i-1">
          <div class="group-v" style="gap: 0px; border: 1px solid black;">
            <label class="line-header" style="gap: 0px; border: 0px solid black;">Freq Reel {{ i }}</label>
            <input
              type="number"
              id="frequency"
              v-model.number="symbolsFreq.reelFrequencies[symbolIndex].freq[i - 1]"
              min="0"
              :max="magicNumber"
              class="line-symbol"
              @change="validateClamp()"
            />
            <input
              type="range"
              v-model.number="symbolsFreq.reelFrequencies[symbolIndex].freq[i - 1]"
              min="0"
              :max="magicNumber"
              class="line-symbol"
              @change="validateClamp()"
            />
            <hr class="separator" /> 
            <div class="group-h" style="display: inline-flex; align-items: center; gap: 4px;">
              <label style="white-space: nowrap; margin-left: 10px;">Anticipate</label>
              <input
                type="checkbox"
                v-model="symbol.pays.anticipate[i - 1]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="separator" />
    <!-- Display wins -->
    <div class="group-v" style="gap: 0px; border: 0px solid black;">
      <label>Wins {{symbol.pays.pays.length}}</label>
      <div v-for="i in symbol.pays.pays.length" :key="i-1">
        <div class="group-h" style="margin-bottom: 0px; gap: 0px;" v-if="showZeroPays || symbol.pays.pays[i - 1] !== 0"  :style="{ color: symbol.pays.pays[i - 1] === 0 ? 'darkred' : 'inherit' }">
          <label class="line-symbol">{{ symbol.name }}</label>
          <label class="line-symbol">x{{ i }}</label>
          <input type="number" class="line-symbol" v-model.number="symbol.pays.pays[i - 1]"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'PayTableScatterComponentPARSheet',
  props: ['symbolsFreq', 'symbol', 'numberOfReels', 'symbolsPerReel', 'showZeroPays', 'symbolIndex', 'isParSheetEnabled'],
  data() {
    return {
      expandedSymbol: true,
      // TODO(cab): magicNumber 20 value below is the same magic number like the one in symbols setup
      magicNumber: 20,
    };
  },
  // TODO(cab): for PAR Sheet it would be good to make validations to see if a payline is ever possible based on distribution
  methods: {
    validateClamp() {
      let max = 0;
      for (let i = 0; i < this.numberOfReels; i++) {
        const freq = this.symbolsFreq.reelFrequencies[this.symbolIndex].freq[i];
        max += freq > this.symbolsPerReel ? this.symbolsPerReel : freq;
      }

      // Create new arrays with adjusted sizes
      const newPays = this.adjustSize([...this.symbol.pays.pays], max, 0);
      const newAnticipate = this.adjustSize([...this.symbol.pays.anticipate], this.numberOfReels, false);

      // Create a new symbol object with updated pays
      const updatedSymbol = {
        ...this.symbol,
        pays: {
          ...this.symbol.pays,
          pays: newPays,
          anticipate: newAnticipate
        }
      };

      this.$emit('update:symbol', updatedSymbol);
      this.$props.symbol.pays.pays = newPays;
      this.$props.symbol.pays.anticipate = newAnticipate;
    },
    toggleSymbol() {
      this.expandedSymbol = !this.expandedSymbol;
    },
    adjustSize(array, targetSize, defaultValue = 0) {
      const currentSize = array.length;
      if (currentSize < targetSize) {
        array.push(...Array(targetSize - currentSize).fill(defaultValue));
      } else if (currentSize > targetSize) {
        array.length = targetSize;
      }
      return array;
    }
  },
  mounted() {
    this.validateClamp();
  }
}
</script>

<style scoped>
  .group-h {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    gap: 10px;
  }
  .group-h button {
    font-size: 13px;
  }
  .group-h input[type='number'] {
    width: 100px;
  }
  
  .group-v {
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  
  .line-header {
    border: 1px solid black;
    font-weight: bold;
    width: 150px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  
  .line-symbol,
  .line-symbol input[type='number'],
  .line-symbol input[type='range'] {
    border: 1px solid black;
    width: 150px !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .separator {
    border: none;
    border-top: 1px solid #000;
    margin: 10px 0;
  }
</style>