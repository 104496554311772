<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="clickable-div" style="margin-bottom: 0px; gap: 0px; top: 0px;" @click="toggleSymbol()">
    <label :style="{ color: symbol.pays.pays[0] === 0 ? 'darkred' : 'inherit' }">
      {{ expandedSymbol ? '▼' : '▶' }} {{ symbol.name }}
    </label>
  </div>
  <div v-if="expandedSymbol">
    <div class="group-h">
      <label>Enable Frequency Per Reel</label>
      <input type="checkbox" class="line-symbol" v-model="symbol.perReelFrequency"/>
    </div>
    <hr class="separator" />
    <div v-if="!symbol.perReelFrequency">
      <!-- Show generic frequency info -->
      <div class="group-h" style="gap: 0px;">
        <label class="reel-frequency-label">Frequency</label>
        <input
          type="number"
          id="frequency"
          v-model.number="symbolsFreq.symbolFrequencies[symbolIndex]"
          min="0"
          :max="symbolsFreq.precision"
          class="reel-frequency-label"
        />
        <input
          type="range"
          v-model.number="symbolsFreq.symbolFrequencies[symbolIndex]"
          min="0"
          :max="symbol.precision"
        />
      </div>

      <div class="group-h" style="gap: 0px; border: 0px solid black;">
        <div v-for="i in numberOfReels" :key="i-1">
          <div class="group-v" style="gap: 0px; border: 1px solid black;">
            <label class="line-header" style="gap: 0px; border: 0px solid black; border-bottom: 1px solid black;">Reel {{ i }}</label>
            <div class="group-h" style="display: inline-flex; align-items: center; gap: 4px;">
              <label style="white-space: nowrap; margin-left: 10px;">Anticipate</label>
              <input
                type="checkbox"
                v-model="symbol.pays.anticipate[i - 1]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- Display freqs per reel -->
      <div class="group-h" style="gap: 0px; border: 0px solid black;">
        <div v-for="i in numberOfReels" :key="i-1">
          <div class="group-v" style="gap: 0px; border: 1px solid black;">
            <label class="line-header" style="gap: 0px; border: 0px solid black;">Freq Reel {{ i }}</label>
            <input
              type="number"
              id="frequency"
              v-model.number="symbolsFreq.reelFrequencies[symbolIndex].freq[i - 1]"
              min="0"
              :max="symbolsFreq.precision"
              class="line-symbol"
            />
            <input
              type="range"
              v-model.number="symbolsFreq.reelFrequencies[symbolIndex].freq[i - 1]"
              min="0"
              :max="symbol.precision"
              class="line-symbol"
            />
            <div class="group-h" style="display: inline-flex; align-items: center; gap: 4px;">
              <label style="white-space: nowrap; margin-left: 10px;">Anticipate</label>
              <input
                type="checkbox"
                v-model="symbol.pays.anticipate[i - 1]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="separator" />

    <!-- Display clamping -->
    <div class="group-h" style="gap: 0px; border: 0px solid black;">
      <div class="clamping">
        <label>Clamping</label>
        <select v-model.number="symbol.clampPerReel" @change="validateClamp(symbolIndex)">
          <option v-for="(option, cindex) in clampOptions" :key="cindex" :value="cindex">
            {{ option }}
          </option>
        </select>
        <label for="clampTotal">max per reel</label>
        <label v-if="symbol.clampPerReel > 0" for="clampTotal">but not more than</label>
        <select v-if="symbol.clampPerReel > 0" v-model.number="symbol.clampTotal" @change="validateClamp(symbolIndex)">
          <option v-for="(option, cindex) in clampTotalOptions(symbol.clampPerReel * numberOfReels)" :key="cindex" :value="cindex">
            {{ option }}
          </option>
        </select>
      </div>
    </div>

    <hr class="separator" />
    <!-- Display wins -->
    <div class="group-v" style="gap: 0px; border: 0px solid black;">
      <div v-for="i in symbol.pays.pays.length" :key="i-1">
        <div class="group-h" style="margin-bottom: 0px; gap: 0px;" v-if="showZeroPays || symbol.pays.pays[i - 1] !== 0"  :style="{ color: symbol.pays.pays[i - 1] === 0 ? 'darkred' : 'inherit' }">
          <label class="line-symbol">{{ symbol.name }}</label>
          <label class="line-symbol">x{{ i }}</label>
          <input type="number" class="line-symbol" v-model.number="symbol.pays.pays[i - 1]"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'PayTableScatterComponent',
  props: ['symbolsFreq', 'symbol', 'numberOfReels', 'symbolsPerReel', 'showZeroPays', 'symbolIndex'],
  data() {
    return {
      expandedSymbol: true,
      clampOptions: [],
    };
  },
  methods: {
    validateClamp() {
      const symbol = this.symbol;
      const maxPerReel = this.symbolsPerReel;
      const reelsNo = this.numberOfReels;
              
      const pay = symbol.pays;
      let max = reelsNo;
      if (symbol.typeofSymbol == 1) {
          var smax = (symbol.clampPerReel == 0 ? maxPerReel : symbol.clampPerReel) * reelsNo;
          var maxtotal = symbol.clampPerReel == 0 ? 0 : symbol.clampTotal;
          if (maxtotal > 0 && maxtotal < smax) smax = maxtotal;
          if (smax > 0) max = smax;
          
          pay.pays = this.adjustSize(pay.pays, max, 0);
          pay.anticipate = this.adjustSize(pay.pays, max, 0);
      }
    },
    toggleSymbol() {
      this.expandedSymbol = !this.expandedSymbol;
    },
    adjustSize(array, targetSize, defaultValue = 0) {
      const currentSize = array.length;
      if (currentSize < targetSize) {
        array.push(...Array(targetSize - currentSize).fill(defaultValue));
      } else if (currentSize > targetSize) {
        array.length = targetSize;
      }
      return array;
    },
    clampTotalOptions(max) {
      const options = ["off"];
      for (let i = 1; i <= max; i++) {
        options.push(i.toString());
      }
      return options;
    },
    updateClampOptions(perReel) {
      this.clampOptions = ['off'];
      for (let i = 1; i <= perReel; i++) {
        this.clampOptions.push(i.toString());
      }
    },
  },
  mounted() {
    this.updateClampOptions(this.symbolsPerReel);
  }
}
</script>

<style scoped>
  .group-h {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    gap: 10px;
  }
  .group-h button {
    font-size: 13px;
  }
  .group-h input[type='number'] {
    width: 100px;
  }
  .group-h select {
    width: 50px;
  }
  
  .group-v {
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  
  .line-header {
    border: 1px solid black;
    font-weight: bold;
    width: 150px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  
  .line-symbol,
  .line-symbol input[type='number'],
  .line-symbol input[type='range'] {
    border: 1px solid black;
    width: 150px !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .clamping {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    gap: 10px;
  }
  
  .clamping select {
    display: flex;
    width: 50px;
  }

  .reel-frequency-label {
    width: 150px;
    margin-right: 10px;
  }

  .separator {
    border: none;
    border-top: 1px solid #000;
    margin: 10px 0;
  }
</style>