<template>
  <hr class="separator"/>
  <button @click="showPayTableStats = !showPayTableStats"> {{ showPayTableStats ? 'Hide Stats' : 'Show Stats' }}</button>  
  
  <div v-if="showPayTableStats">
    <hr class="separator"/>
    <div class="group-h">
      <label class="header-symbol">Symbol</label>
      <div v-for="i in numberOfReels" :key="i-1">
        <label class="header-symbol"> x{{ i }}</label>
      </div>
    </div>

    <div v-for="(symbol, index) in symbols" :key="index" class="group-v" style="margin-bottom: 0px; gap: 0px;">
      <template v-if="symbol.typeofSymbol !== 1 && symbol.active">
        <div class="group-h">
          <label class="header-symbol">{{ symbol.name }}</label>
          <div v-for="i in numberOfReels" :key="i-1">
            <label class="line-symbol">{{ symbol.pays.pays[i - 1] }}</label>
          </div>
        </div>
      </template>
    </div>
  </div>
  <hr class="separator"/>
</template>

<script>
export default {
  name: 'PayTablePreviewComponent',
  props: ['symbols', 'numberOfReels'],
  data() {
    return {
      showPayTableStats: false,
    };
  },
};
</script>

<style scoped>
  .separator {
    border: none;
    border-top: 1px solid #000;
    margin: 10px 0;
  }
  .page-content {
    padding: 20px;
  }
  .form-group {
    align-items: center;
    margin-bottom: 15px;
    background-color: #d0d0d0;
    padding: 10px;
  }
  .group-h {
    display: flex;
    align-items: left;
  }
  .line-symbol {
    border: 1px solid black;
    width: 150px !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-symbol {
    border: 1px solid black;
    width: 150px !important;
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>