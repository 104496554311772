<template>
  <div class="page-content">
    <h2>Symbols Page</h2>
    <div v-if="isLoading">
      <p>Loading...</p>
    </div>
    <form v-else @submit.prevent> 
      <SymbolsPageGeneral ref="child"/>
      <SymbolsPageSymbols ref="child"/>
    </form>
  </div>
  <div class="fixed-box">
    <button type="button" @click="addSymbol">Add Symbol</button>
    <button type="button" @click="fetchDefaultValues">Refresh from server</button>
    <button type="button" @click="saveSettings">Save symbols</button>
  </div>
</template>

<script>
  import SymbolsPageGeneral from './SymbolsPageGeneral.vue'
  import SymbolsPageSymbols from './SymbolsPageSymbols.vue'
  import { auth } from '@/js/auth.js';
  import { statusCodes } from '@/data/statusCodes.js';
  export default {
    name: 'SymbolsPage',
    components: {
      SymbolsPageGeneral,
      SymbolsPageSymbols,
    },
    data() {
      return {
        isLoading: true,
        symbolsInfo: {
          precision: 100,
          symbolTexs: [],
          symbolInfo: [],
          reelFrequencies: [],
          symbolFrequencies: [],
          parSheet: null,
        },
        parSheet: null,
        reelsNo: 5,
        symbsPerReel: 3,
        defaultValues: {},
        visiblePngImgTooltip: null,
      };
    },
    methods: {
      showPngImgTooltip(index) {
        this.visiblePngImgTooltip = index;
      },
      hidePngImgTooltip() {
        this.visiblePngImgTooltip = null;
      },
      deleteSymbol(index) {
        this.symbolsInfo.symbolTexs.splice(index, 1);
        this.symbolsInfo.symbolInfo.splice(index, 1);
        this.symbolsInfo.reelFrequencies.splice(index, 1);
        this.symbolsInfo.symbolFrequencies.splice(index, 1);
      },
      addSymbol() {
        this.symbolsInfo.symbolTexs.push('tex_dummy');
        this.symbolsInfo.symbolInfo.push({
          active: true,
          isWild: false,
          clampPerReel: 0,
          clampTotal: 0,
          perReelFrequency: false,
          linked: false,
          linkName: '',
          link: -1,
          linkPosition: 0,

          typeofSymbol: 0,
          scatterCount: 0,
          allowWilds: false,
          pays: {
            pays: Array(this.reelsNo).fill(0),
            anticipate: Array(this.reelsNo).fill(0)
          },
          name: 'dummy_name',
        });
        this.symbolsInfo.reelFrequencies.push({
          freq: Array(this.reelsNo).fill(1)
        });
        this.symbolsInfo.symbolFrequencies.push(0);
      },
      fetchDefaultValues() {
        if (this.$root.getCurrentConfigId() === null) {
          this.$root.handleError('Empty getCurrentConfigId() variable!');
        } else {
          fetch(`${this.$root.envVars.api}/api/admin2/symbols/symbol/read?configId=${this.$root.getCurrentConfigId()}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.autToken()}`
              },
            })
            .then(response => response.json())
            .then(data => {
              this.symbolsInfo = { ... data.symbols };
              this.parSheet = { ... data.parSheet };
              this.reelsNo = data.reelsNo;
              this.symbsPerReel = data.symbsPerReel;
              this.isLoading = false;
              this.defaultValues = { 
                ... JSON.parse(JSON.stringify(this.symbolsInfo)), 
                parSheet: JSON.parse(JSON.stringify(this.parSheet))
              };
              this.$root.handleSuccess('Data loaded!');
            })
            .catch(error => {
              this.$root.handleError('Error fetching data from server - ' + error);
            });
        }
      },
      saveSettings() {
        if (this.$root.getCurrentConfigId() === null) {
          this.$root.handleError('Empty getCurrentConfigId() variable!');
        } else {
          fetch(`${this.$root.envVars.api}/api/admin2/symbols/symbol/write?configId=${this.$root.getCurrentConfigId()}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.autToken()}`
              },
              body: JSON.stringify(this.symbolsInfo),
            })
            .then(response => {
              if (!response.ok) {
                if (response.status === statusCodes.StatusTokenIdMismatch) {
                  auth.readWriteSet("");
                  throw new Error('Ask for Write permissions');
                }
                throw new Error('Network response was not ok');
              }
            })
            .then(() => {
              this.$root.handleSuccess('Data sent to server!');
              this.defaultValues = { 
                ... JSON.parse(JSON.stringify(this.symbolsInfo)), 
                parSheet: JSON.parse(JSON.stringify(this.parSheet))
              };
            })
            .catch(error => {
              this.$root.handleError(error);
            });
        }
      },
      isParSheetEnabled() {
        return this.parSheet.enabled;
      },
      
    },
    mounted() {
      this.fetchDefaultValues();
    }
  };
</script>

<style scoped>
  .fixed-box {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 70px;
    right: 20px;
    background-color: green;
    color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    gap: 10px;
  }
  .page-content {
    position: relative;
    width: 500px;
  }
</style>