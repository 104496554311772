<template>
  <div style="width: 500px;" v-if="getSymbol(symbolIndex).active">
    <div class="group-v">
      <label style="font-weight: bold;">Match Pays:</label>
      <div class="group-grid group-h">
        <div v-for="(pay, pIndex) in getSymbol(symbolIndex).pays.pays" :key="pIndex" class="pay-item">
          <label style="margin-right: 5px;">{{ (pIndex + 1 < 10 ? '0' : '') + (pIndex + 1) }}</label>
          <input type="number" v-model.number="getSymbol(symbolIndex).pays.pays[pIndex]" style="margin-right: 5px;"/>
          <label title="Enable anticipation for this symbol" style="margin-right: 5px;">Ant</label>
          <input type="checkbox" v-model="getSymbol(symbolIndex).pays.anticipate[pIndex]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SymbolsPageSymbolPaySetup',
    props: {
      symbolIndex: {
        type: Number,
        required: true
      }
    },
    methods: {
      getSymbol(index) {
        const symbol = this.$parent.$parent.symbolsInfo.symbolInfo[index];
        return symbol;
      },
    }
  };
</script>

<style scoped>
  .group-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  .pay-item {
    flex: 0 0 calc(50% - 10px);
    box-sizing: border-box;
    border: 2px solid #000000;
  }
  .pay-item label {
    margin-left: 3px;
  }
  .group-v {
    display: flex;
    align-items: left;
    flex-direction: column;
  }
  .group-v input[type='number'] {
    width: 75px;
  }
  .group-h {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    gap: 10px;
  }
  .group-h input[type='number'] {
    width: 100px;
  }
</style>