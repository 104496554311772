<template>
    <div>
      <h2>CDN Page</h2>

      <CDNSubpageBasic/>
      <CDNSubpageTextures/>
      <CDNSubpageAnims/>
      <CDNSubpageAudio/>
      
    </div>
  </template>
  
  <script>
  import CDNSubpageTextures from './CDNSubpageTextures.vue';
  import CDNSubpageAnims from './CDNSubpageAnims.vue';
  import CDNSubpageBasic from './CDNSubpageBasic.vue';
  import CDNSubpageAudio from './CDNSubpageAudio.vue';

  export default {
    name: 'CDNPage',
    components: {
      CDNSubpageTextures,
      CDNSubpageAnims,
      CDNSubpageBasic,
      CDNSubpageAudio,
    },
    data() {
      return {
        
      };
    },
    methods: {
      
    },
    mounted() {
      
    },
  };
  </script>
  
  <style scoped>
  
  </style>