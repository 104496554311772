<template>
  <div class="general-settings">
    <div class="group-h" v-if="!$parent.isParSheetEnabled()">
      <label for="precision">Global Precision</label>
      <div class="precision-container">
        <input
            type="number"
            id="precision"
            v-model.number="$parent.symbolsInfo.precision"
            min="5"
            max="100"
        />
        <input
            type="range"
            v-model.number="$parent.symbolsInfo.precision"
            min="5"
            max="100"
        />
      </div>
    </div>
    <div class="group-h">
      <label>Number of symbols: {{ $parent.symbolsInfo.symbolTexs.length }}</label>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SymbolsPageGeneral',
    methods: {
      handleParSheetChange() {
        this.$root.dialogConfig = {
          show: true,
          title: 'Changing PAR Sheet Status',
          content: 'Are you sure you want to change the PAR sheet mode status?\n\nThis will affect all symbols.\n\nIf you accept, the change will be applied immediately.',
          onConfirm: () => {
            this.$parent.parSheet.enabled = !this.$parent.parSheet.enabled
            this.$root.settings.currentConfigHasParSheetEnabled = this.$parent.parSheet.enabled;
            this.$root.dialogConfig.show = false;
          },
          onClose: () => {
            this.$root.dialogConfig.show = false;
          }
        };
      },
    }
  };
</script>

<style scoped>
  .general-settings {
    display: inline-flex;
    flex-direction: column;
    border: 1px solid #787878;
    background-color: #e4e4e4;
    padding: 10px;
    margin-bottom: 10px;
    width: 500px;
  }

  .group-h {
    display: flex;
    align-items: left;
    gap: 10px;
  }
  
  .group-h label {
    display: flex;
    font-weight: bold;
  }

  .precision-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .precision-container input[type="number"] {
    width: 80px;
  }
  
  .precision-container input[type="range"] {
    width: 200px;
  }
</style>