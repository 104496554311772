<template>
  <div
    v-for="(symbol, index) in $parent.symbolsInfo.symbolTexs"
    :key="index"
    class="symbol-settings"
  >
    <div class="group-h">
      <div class="group-v" style="width:500px">
        <SymbolsPageSymbolHeader ref="child" :symbolIndex=index />
        <SymbolsPageSymbolArt ref="child" :symbolIndex=index />
        <SymbolsPageSymbolBaseSetup ref="child" :symbolIndex=index />
        <SymbolsPageSymbolPaySetup ref="child" :symbolIndex=index v-if="!$parent.isParSheetEnabled()" />
      </div>
    </div>
  </div>
  <hr class="separator" />
</template>

<script>
  import SymbolsPageSymbolHeader from './SymbolsPageSymbolHeader.vue';
  import SymbolsPageSymbolArt from './SymbolsPageSymbolArt.vue';
  import SymbolsPageSymbolBaseSetup from './SymbolsPageSymbolBaseSetup.vue';
  import SymbolsPageSymbolPaySetup from './SymbolsPageSymbolPaySetup.vue';
  import { auth } from '@/js/auth.js';
  import { statusCodes } from '@/data/statusCodes.js';

  export default {
    name: 'SymbolsPageSymbols',
    components: {
      SymbolsPageSymbolHeader,
      SymbolsPageSymbolArt,
      SymbolsPageSymbolBaseSetup,
      SymbolsPageSymbolPaySetup,
    },
    data() {
      return {};
    },
    methods: {
      addSymbol() {
        this.symbolsInfo.symbolTexs.push('');
        this.symbolsInfo.symbolInfo.push({
          active: true,
          isWild: false,
          clampPerReel: 0,
          clampTotal: 0,
          perReelFrequency: false,
          linked: false,
          linkName: '',
          link: -1,
          linkPosition: 0
        });
        this.symbolsInfo.reelFrequencies.push({
          freq: Array(this.$parent.reelsNo).fill(1)
        });
        this.symbolsInfo.symbolFrequencies.push(0);
      },
      saveSettings() {
        if (this.$root.getCurrentConfigId() === null) {
          this.$root.handleError('Empty getCurrentConfigId() variable!');
        } else {
          fetch(`${this.$root.envVars.api}/api/admin2/symbols/symbol/write?configId=${this.$root.getCurrentConfigId()}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.autToken()}`
              },
              body: JSON.stringify(this.symbolsInfo),
            })
            .then(response => {
              if (!response.ok) {
                if (response.status === statusCodes.StatusTokenIdMismatch) {
                  auth.readWriteSet("");
                  throw new Error('Ask for Write permissions');
                }
                throw new Error('Network response was not ok');
              }
            })
            .then(() => {
              this.$root.handleSuccess('Data sent to server!');
              this.defaultValues = JSON.parse(JSON.stringify(this.symbolsInfo));
            })
            .catch(error => {
              this.$root.handleError(error);
            });
        }
      }
    },
    mounted() {
    }
  };
</script>

<style scoped>
  .symbol-settings {
    display: inline-flex;
    border: 1px solid #787878;
    background-color: #e4e4e4;
    padding: 10px;
    margin-bottom: 10px;
  }

  .group-h {
    display: flex;
    align-items: left;
    gap: 10px;
  }
</style>