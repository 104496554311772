<template>
    <div class="page-content">
      <div class="clickable-div" @click="isExpandedPanelAudio = !isExpandedPanelAudio">
        <label>{{ isExpandedPanelAudio ? '▼' : '▶' }} Audio section {{ isExpandedPanelAudio ? '' : '[CLOSED]' }}</label>
      </div>

      <div v-if="isExpandedPanelAudio">
        <div class="form-group">
          <label>RAW Audio</label>
          <div class="group-h">
            <button @click="toggleShowModalUploadAudio">Upload new audio</button>
            <transition name="fade">
              <div v-if="showModalUploadAudio" class="modal-overlay" @click="closeModalUploadAudio">
                <div class="modal-content" @click.stop>
                  <input type="file" @change="selectedFile" accept=".zip"/>
                  <button @click="buttonActionModalUploadAudio">Upload</button>
                </div>
              </div>
            </transition>

            <button @click="askDeleteAllShowDialog">Delete all audio</button>
            <ConfirmationDialog 
              v-if="showAskDeleteAll"
              :title="'Delete all audio'"
              :content="'Are you sure you want to delete all audio?'"
              :onConfirm="askDeleteAllHandleConfirm"
              :onClose="askDeleteAllHandleClose"
            />

            <button @click="downloadAllShowDialog">Download all audio</button>
          </div>
          <div class="group-h">
            <button @click="toggleCollapseRawAudio">{{ isCollapsed_rawAudio ? '+' : '-' }}</button>
            <button @click="refreshRawAudio">Refresh Audio</button>
          </div>
          <transition name="fade">
            <ul v-if="!isCollapsed_rawAudio">
              <li v-for="(item, index) in $root.cdn.audio.raw" :key="index">
                <div class="group-h">
                  <button @click="removeFile(item)">remove</button>
                  <label>{{ item }} ({{ $root.cdn.audio.rawSize[index] || 'Loading...' }} KB)</label>
                  <audio :src="`${$root.getCdnPath()}raw/audio/${item}.mp3`" controls>-</audio>
                </div>
              </li>
            </ul>
          </transition>

        </div>
      

        <div class="form-group">
          <label>Check all audio in packages</label>
          <div class="group-h">
            <button @click="toggleCollapseAllAudio">{{ isCollapsed_allAudio ? '+' : '-' }}</button>
            <button @click="refreshAllAudio">Refresh Audio</button>
          </div>
          <transition name="fade">
            <div class="group-h" v-if="!isCollapsed_allAudio">
              <ul>
                <li><label>RAW</label></li>
                <li v-for="(item, index) in $root.cdn.audio.raw" :key="index">
                  <label>{{ item }} ({{ $root.cdn.audio.rawSize[index] || 'Loading...' }} KB)</label>
                  <audio :src="`${$root.getCdnPath()}raw/audio/${item}.mp3`" controls class="small-audio">-</audio>
                </li>
              </ul>
              <ul>
                <li><label>DESKTOP</label></li>
                <li v-for="(item, index) in $root.cdn.audio.d" :key="index">
                  <label>{{ item }} ({{ $root.cdn.audio.dSize[index] || 'Loading...' }} KB)</label>
                  <audio :src="`${$root.getCdnPath()}d/audio/${item}.ogg`" controls class="small-audio">-</audio>
                </li>
              </ul>
              <ul>
                <li><label>MOBILE</label></li>
                <li v-for="(item, index) in $root.cdn.audio.m" :key="index">
                  <label>{{ item }} ({{ $root.cdn.audio.mSize[index] || 'Loading...' }} KB)</label>
                  <audio :src="`${$root.getCdnPath()}m/audio/${item}.ogg`" controls class="small-audio">-</audio>
                </li>
              </ul>
              <ul>
                <li><label>TINY</label></li>
                <li v-for="(item, index) in $root.cdn.audio.t" :key="index">
                  <label>{{ item }} ({{ $root.cdn.audio.tSize[index] || 'Loading...' }} KB)</label>
                  <audio :src="`${$root.getCdnPath()}t/audio/${item}.ogg`" controls class="small-audio">-</audio>
                </li>
              </ul>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { cdn_listAllIn } from '@/js/cdn-apis';
  import ConfirmationDialog from '../shared/ConfirmationDialog.vue';
  import { auth } from '@/js/auth.js';
  import { statusCodes } from '@/data/statusCodes.js';

  export default {
    name: 'CDNSubpageAudio',
    components: {
      ConfirmationDialog,
    },
    data() {
      return {
        showModalUploadAudio: false,
        uploadSelectedFile: null,
        showAskDeleteAll: false,
        isCollapsed_rawAudio: true,
        isCollapsed_allAudio: true,

        isExpandedPanelAudio: false,
      };
    },
    methods: {
      // upload anims
      selectedFile(event) {
        this.uploadSelectedFile = event.target.files[0];
      },
      toggleShowModalUploadAudio() {
        this.showModalUploadAudio = !this.showModalUploadAudio;
      },
      closeModalUploadAudio() {
        this.showModalUploadAudio = false;
      },
      buttonActionModalUploadAudio() {
        if (this.uploadSelectedFile) {
          const formData = new FormData();
          formData.append("file", this.uploadSelectedFile);

          fetch(`${this.$root.envVars.cdnapi}/uploadAudioAsZip`, {
            method: "POST",
            body: formData,
          }).then(response => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            response.json().then(result => {
              this.refreshRawAudio();
              this.$root.handleSuccess("File uploaded successfully", result);
            }).catch(error => {
              this.$root.handleError("Something wrong happened:", error);
            });
          }).catch(error => {
            this.$root.handleError(error);
          });
          this.closeModalUploadAudio();
        } else {
          alert(`Select a ZIP file to upload!`);
        }
      },

      // delete all amins
      askDeleteAllShowDialog() {
        this.showAskDeleteAll = true;
      },
      askDeleteAllHandleConfirm() {
        this.removeAllAudio();
        this.askDeleteAllHandleClose();
      },
      askDeleteAllHandleClose() {
        this.showAskDeleteAll = false;
      },

      removeAllAudio() {
        fetch(`${this.$root.envVars.cdnapi}/clearAllAudio`).then(response => {
            response.json().then(result => {
              this.$root.handleSuccess("Audio successfully removed", result);
              this.refreshRawAudio();
            }).catch(error => {
              this.$root.handleError("Something wrong happened:", error);
            });
          }).catch(error => {
            this.$root.handleError("Something wrong happened:", error);
          });
      },

      removeFile(audioFile) {
        fetch(`${this.$root.envVars.cdnapi}/clearAudio/${audioFile}`).then(response => {
            response.json().then(result => {
              this.$root.handleSuccess("Audio successfully removed", result);
              this.refreshRawAudio();
            }).catch(error => {
              this.$root.handleError("Something wrong happened:", error);
            });
          }).catch(error => {
            this.$root.handleError("Something wrong happened:", error);
          });
      },

      // download all anims
      downloadAllShowDialog() {
        fetch(`${this.$root.envVars.cdnapi}/downloadAll/audio`).then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          response.blob().then(blob => {
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = 'all_resources_audio.zip';
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(downloadUrl); 
            this.$root.handleSuccess("File downloaded successfully" + response);
          });
        });
      },
      // raw anims
      toggleCollapseRawAudio() {
        this.isCollapsed_rawAudio = !this.isCollapsed_rawAudio;
      },
      refreshRawAudio() {
        cdn_listAllIn("raw", this.$root.envVars.cdnapi, "audio")
            .then(
              result => {
                this.$root.cdn.audio.raw = result;
                this.refreshRawAudioSize();
              });
      },
      // packages anims
      toggleCollapseAllAudio() {
        this.isCollapsed_allAudio = !this.isCollapsed_allAudio;
      },
      refreshAllAudio() {
        cdn_listAllIn("raw", this.$root.envVars.cdnapi, "audio")
            .then(
              result => {
                this.$root.cdn.audio.raw = result;
                this.refreshRawAudioSize();
              });

        cdn_listAllIn("d", this.$root.envVars.cdnapi, "audio")
            .then(
              result => {
                this.$root.cdn.audio.d = result;
                this.refreshDesktopAudioSize();
              });

        cdn_listAllIn("m", this.$root.envVars.cdnapi, "audio")
            .then(
              result => {
                this.$root.cdn.audio.m = result;
                this.refreshMobileAudioSize();
              });

        cdn_listAllIn("t", this.$root.envVars.cdnapi, "audio")
            .then(
              result => {
                this.$root.cdn.audio.t = result;
                this.refreshTinyAudioSize();
              });
      },
      async refreshRawAudioSize() {
        const baseUrl = `${this.$root.getCdnPath()}raw/audio/`;
        this.$root.cdn.audio.rawSize = await Promise.all(
          this.$root.cdn.audio.raw.map(async (el) => {
            const s = await this.$root.getFileSize(`${baseUrl}${el}.mp3`);
            return s;
          }),
        );
      },
      async refreshDesktopAudioSize() {
        const baseUrl = `${this.$root.getCdnPath()}d/audio/`;
        this.$root.cdn.audio.dSize = await Promise.all(
          this.$root.cdn.audio.d.map(async (el) => {
            const s = await this.$root.getFileSize(`${baseUrl}${el}.ogg`);
            return s;
          }),
        );
      },
      async refreshMobileAudioSize() {
        const baseUrl = `${this.$root.getCdnPath()}m/audio/`;
        this.$root.cdn.audio.mSize = await Promise.all(
          this.$root.cdn.audio.m.map(async (el) => {
            const s = await this.$root.getFileSize(`${baseUrl}${el}.ogg`);
            return s;
          }),
        );
      },
      async refreshTinyAudioSize() {
        const baseUrl = `${this.$root.getCdnPath()}t/audio/`;
        this.$root.cdn.audio.tSize = await Promise.all(
          this.$root.cdn.audio.t.map(async (el) => {
            const s = await this.$root.getFileSize(`${baseUrl}${el}.ogg`);
            return s;
          }),
        );
      },
    },
    mounted() {
      this.refreshRawAudioSize();
    },
  };
  </script>
  
  <style scoped>
  .page-content {
    padding: 20px;
  }

  .form-group {
    align-items: center;
    margin-bottom: 15px;
    background-color: #d0d0d0;
    padding: 10px;
  }
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .form-group input[type='number'],
  .form-group select,
  .form-group input[type='text'] {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .form-group input[type='range'] {
    width: 100%;
  }
  .group-h {
    display: flex;
    /* align-items: center; */
    margin-bottom: 10px;
    gap: 10px;
  }
  .group-h label {
    display: flex;
  }
  .group-h button {
    font-size: 13px;
  }
  .group-h input[type='number'] {
    width: 100px;
  }
  .group-h input[type='text'] {
    width: 250px;
  }
  .group-h select {
    width: 200px;
  }
  .group-h audio {
    height: 25px
  }

  .small-audio {
    height: 25px;
    width:150px;
  }

  .group-v {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: left;
    gap: 10px
  }
  .group-v label {
    display: flex;
  }
  .group-v input[type='number'] {
    width: 100px;
  }
  .group-v input[type='text'] {
    width: 250px;
  }
  .group-v select {
    width: 250px;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}
.buttons {
  margin-top: 10px;
}
.buttons button {
  margin-right: 10px;
}
/* button {
  margin-top: 10px;
} */
.small-image {
  width: 30px;
  height: auto;
  /* border: 1px solid #ccc; */
}
.tooltip {
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px;
  /* background-color: #333;
  color: #fff; */
  /* padding: 5px; */
  /* border-radius: 4px; */
  font-size: 12px;
  white-space: nowrap;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
  z-index: 1000;
}
  </style>