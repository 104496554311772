<template>
  <div class="group-h">
    <button @click="expandAll">Expand All</button>
    <button @click="collapseAll">Collapse All</button>
    <button @click="toggleShowZeroPays">{{ showZeroPays ? 'Hide Zero Pays' : 'Show Zero Pays' }}</button>
  </div>
  <div class="group-h" style="gap: 0px;">
    <label class="line-header">Symbol name</label>
    <label class="line-header">Matches</label>
    <label class="line-header">Win value</label>
  </div>
  <div v-for="(symbol, index) in symbols" :key="index" class="group-v" style="margin-bottom: 0px; gap: 0px;">
    <template v-if="symbol.typeofSymbol !== 1 && symbol.active">
      <div class="clickable-div" style="margin-bottom: 0px; gap: 0px; top: 0px;" @click="toggleSymbol(index)">
        <label :style="{ color: symbol.pays.pays[0] === 0 ? 'darkred' : 'inherit' }">
          {{ expandedSymbols[index] ? '▼' : '▶' }} {{ symbol.name }}
          <span v-if="!expandedSymbols[index] && symbol.pays.pays.some(p => p > 0)">
            ({{ symbol.pays.pays.map((pay, idx) => pay > 0 ? `x${idx + 1}` : '').filter(Boolean).join(' ') }})
          </span>
        </label>
      </div>
      <div v-if="expandedSymbols[index]">
        <div class="group-h" style="gap: 0px;">
          <label class="line-header">Allow wilds</label>
          <input type="checkbox" class="line-symbol" v-model="symbol.allowWilds"/>
        </div>

        <div v-for="i in numberOfReels" :key="i-1">
          <div class="group-h" style="margin-bottom: 0px; gap: 0px;" v-if="showZeroPays || symbol.pays.pays[i - 1] !== 0"  :style="{ color: symbol.pays.pays[i - 1] === 0 ? 'darkred' : 'inherit' }">
            <label class="line-symbol">{{ symbol.name }}</label>
            <label class="line-symbol">x{{ i }}</label>
            <input type="number" class="line-symbol" v-model.number="symbol.pays.pays[i - 1]"/>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PayTableTreeComponent',
  props: ['symbols', 'numberOfReels'],
  data() {
    return {
      expandedSymbols: [],
      showZeroPays: false
    };
  },
  methods: {
    initExpandedSymbols() {
      if (this.$parent.$parent.symbolsInfo && this.$parent.$parent.symbolsInfo.symbolInfo) {
        this.expandedSymbols = new Array(this.$parent.$parent.symbolsInfo.symbolInfo.length).fill(true);
      }
    },
    expandAll() {
      this.expandedSymbols = this.expandedSymbols.map(() => true);
    },
    collapseAll() {
      this.expandedSymbols = this.expandedSymbols.map(() => false);
    },
    toggleShowZeroPays() {
      this.showZeroPays = !this.showZeroPays;
    },
    toggleSymbol(index) {
      this.expandedSymbols = this.expandedSymbols.map((val, i) => i === index ? !val : val);
    }
  },
  mounted() {
    this.initExpandedSymbols();
  }
}
</script>

<style scoped>
  .group-h {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    gap: 10px;
  }
  .group-h label {
    display: flex;
  }
  .group-v {
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  .group-v label {
    display: flex;
  }
  .line-header {
    border: 1px solid black;
    font-weight: bold;
    width: 150px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .line-symbol {
    border: 1px solid black;
    width: 150px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
</style>