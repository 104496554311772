export const globalData = {
  envVars: {
    env: window.vueConfig ? window.vueConfig.VUE_APP_ENVIRONMENT : process.env.VUE_APP_ENVIRONMENT,
    api: window.vueConfig ? window.vueConfig.VUE_APP_API_ENDPOINT : process.env.VUE_APP_API_ENDPOINT,
    cdn: "http://localhost:local/",
    cdnapp: "_local/",
    cdnapi: "http://localhost:api",
  },

  cdn: {
    texs: {
      raw: [],
      rawSize: [],
      d: [],
      dSize: [],
      m: [],
      mSize: [],
      t: [],
      tSize: [],
    },
    anims: {
      raw: [],
      d: [],
      m: [],
      t: [],
    },
    audio: {
      raw: [],
      rawSize: [],
      d: [],
      dSize: [],
      m: [],
      mSize: [],
      t: [],
      tSize: [],
    },
  },

  settings: {
    currentConfigId: null,
    currentConfigHasParSheetEnabled: false,
    serverConfigId: null,
  },
};