<template>
  <div v-if="getSymbol(symbolIndex).active">
    <div class="group-h">
      <div class="tooltip-wrapper">
        <label :for="'symbolTex' + symbolIndex"
          @mouseover="showPngImgTooltip(symbolIndex)"
          @mouseout="hidePngImgTooltip">
          Texture [VIEW]
        </label>
        <div v-if="visiblePngImgTooltip === symbolIndex" class="tooltip">
          <img :src="`${$root.getCdnPath()}raw/texs/${getSymbolTex(symbolIndex)}.png`"/>
        </div>
      </div>
      <select v-model="$parent.$parent.symbolsInfo.symbolTexs[symbolIndex]">
        <option v-for="(option) in $root.cdn.texs.raw" :key="option" :value="option">
          {{ option }}
        </option>
      </select>
    </div>
    <hr class="separator" />
  </div>
</template>

<script>
  export default {
    name: 'SymbolsPageSymbolArt',
    props: {
      symbolIndex: {
        type: Number,
        required: true
      }
    },
    components: {
    },
    data() {
      return {
        visiblePngImgTooltip: null,
      };
    },
    methods: {
      getSymbolTex(index) {
        return this.$parent.$parent.symbolsInfo.symbolTexs[index];
      },
      getSymbol(index) {
        return this.$parent.$parent.symbolsInfo.symbolInfo[index];
      },
      checkSymbolName() {
        const symbolName = this.getSymbol(this.symbolIndex).name.replace(/[^A-Za-z0-9]/g, '');
        this.getSymbol(this.symbolIndex).name = symbolName;
        if (symbolName === "") {
          this.symbolNameNotAvailable = true;
        } else {
          const symbols = this.$parent.$parent.symbolsInfo.symbolInfo;
          const index = symbols.findIndex(symbol => symbol.name.toLowerCase() === symbolName.toLowerCase());
          this.symbolNameNotAvailable = index !== this.symbolIndex && index >= 0;
        }
      },
      showPngImgTooltip(index) {
        this.visiblePngImgTooltip = index;
      },
      hidePngImgTooltip() {
        this.visiblePngImgTooltip = null;
      },
    },
    mounted() {
    }
  };
</script>

<style scoped>
  
  .form-group {
    align-items: center;
    margin-bottom: 15px;
    background-color: #d0d0d0;
    padding: 10px;
  }
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .form-group input[type='number'],
  .form-group select,
  .form-group input[type='text'] {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .form-group input[type='range'] {
    width: 100%;
  }

  .precision-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .precision-container input[type="number"] {
    width: 80px;
  }
  .precision-container input[type="range"] {
    width: 200px;
  }

  .symbol-settings {
    border: 1px solid #787878;
    background-color: #e4e4e4;
    padding: 10px;
    margin-bottom: 10px;
  }
  .symbol-settings label {
    font-weight: bold;
    margin-right: 10px;
  }
  .symbol-settings input[type='text'] {
    width: 300px;
  }

  .toggles {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .clamping {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    gap: 10px;
  }
  .clamping select {
    display: flex;
    width: 50px;
  }  

  .current-values-freq {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .frequency,
  .reel-frequency
  .linking {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .frequency label,
  .reel-frequency label {
    width: 300px;
    margin-right: 10px;
  }
  .frequency input[type='number'],
  .reel-frequency input[type='number'] {
    width: 30px;
  }
  .frequency input[type='range'],
  .reel-frequency input[type='range'] {
    width: 250px;
  }
  .current-values-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .current-values {
    flex: 1;
    background-color: #f9f9f9;
    padding: 10px;
    margin-right: 20px;
  }
  .current-values p {
    margin: 5px 0;
  }
  .buttons {
    display: flex;
    flex-direction: column;
  }
  .buttons button {
    margin-bottom: 10px;
    padding: 10px 20px;
  }

  .color-red {
    color: red;
  }

  .group-h {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    gap: 10px;
  }
  .group-h label {
    display: flex;
  }
  .group-h button {
    font-size: 13px;
  }
  .group-h input[type='number'] {
    width: 100px;
  }
  .group-h input[type='text'] {
    width: 250px;
  }
  .group-h select {
    width: 250px;
  }
  </style>