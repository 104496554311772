<template>
  <div>
    <div class="group-h">
      <label 
        :class="{ 'color-red': symbolNameNotAvailable }" 
        @mouseover="showTooltipRename = true" 
        @mouseout="showTooltipRename = false">
        Symbol {{ symbolIndex + 1 }}
      </label>
      <div class="tooltip-wrapper">
        <input 
          type="text" 
          v-model="getSymbols(symbolIndex).name" 
          @input="checkSymbolName" 
          :class="{ 'color-red': symbolNameNotAvailable }" 
          @mouseover="showTooltipRename = true" 
          @mouseout="showTooltipRename = false"
          class="tooltip-wrapper"
          pattern="[A-Za-z0-9]*" />
        <div v-if="showTooltipRename && symbolNameNotAvailable" class="tooltip">
          The symbol name is either empty or already in use!
        </div>
      </div>
      <!-- <button 
        @click="renameSymbol"
        :disabled="symbolNameNotAvailable"
        @mouseover="showTooltipRename = true" 
        @mouseout="showTooltipRename = false" >
        Rename symbol
      </button> -->
      <button 
        @click="askDeleteSymbolShowDialog">
        Delete symbol
      </button>

      <ConfirmationDialog 
        v-if="showAskDeleteSymbol"
        :title="'Delete symbol'"
        :content="'Are you sure you want to delete symbol ' + getSymbols(symbolIndex).name + '?'"
        :onConfirm="askDeleteSymbolHandleConfirm"
        :onClose="askDeleteSymbolHandleClose"
      />
    </div>
    <hr class="separator" />
  </div>
</template>

<script>
  import ConfirmationDialog from '/src/components/pages/shared/ConfirmationDialog.vue'

  export default {
    name: 'SymbolsPageSymbolHeader',
    props: {
      symbolIndex: {
        type: Number,
        required: true
      }
    },
    components: {
      ConfirmationDialog
    },
    data() {
      return {
        symbolNameNotAvailable: false,
        showTooltipRename: false,
        showAskDeleteSymbol: false,
      };
    },
    methods: {
      getSymbols(index) {
        return this.$parent.$parent.symbolsInfo.symbolInfo[index];
      },
      checkSymbolName() {
        const symbolName = this.getSymbols(this.symbolIndex).name.replace(/[^A-Za-z0-9]/g, '');
        this.getSymbols(this.symbolIndex).name = symbolName;
        if (symbolName === "") {
          this.symbolNameNotAvailable = true;
        } else {
          const symbols = this.$parent.$parent.symbolsInfo.symbolInfo;
          const index = symbols.findIndex(symbol => symbol.name.toLowerCase() === symbolName.toLowerCase());
          this.symbolNameNotAvailable = index !== this.symbolIndex && index >= 0;
        }
      },
      askDeleteSymbolShowDialog() {
        this.showAskDeleteSymbol = true;
      },
      askDeleteSymbolHandleConfirm() {
        this.askDeleteSymbolHandleClose();
        this.$parent.$parent.deleteSymbol(this.symbolIndex);
      },
      askDeleteSymbolHandleClose() {
        this.showAskDeleteSymbol = false;
      },
    },
    mounted() {
    }
  };
</script>

<style scoped>

  .form-group {
    align-items: center;
    margin-bottom: 15px;
    background-color: #d0d0d0;
    padding: 10px;
  }
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .form-group input[type='number'],
  .form-group select,
  .form-group input[type='text'] {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .form-group input[type='range'] {
    width: 100%;
  }

  .precision-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .precision-container input[type="number"] {
    width: 80px;
  }
  .precision-container input[type="range"] {
    width: 200px;
  }

  .symbol-settings {
    border: 1px solid #787878;
    background-color: #e4e4e4;
    padding: 10px;
    margin-bottom: 10px;
  }
  .symbol-settings label {
    font-weight: bold;
    margin-right: 10px;
  }
  .symbol-settings input[type='text'] {
    width: 300px;
  }

  .toggles {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .clamping {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    gap: 10px;
  }
  .clamping select {
    display: flex;
    width: 50px;
  }  

  .current-values-freq {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .frequency,
  .reel-frequency
  .linking {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .frequency label,
  .reel-frequency label {
    width: 300px;
    margin-right: 10px;
  }
  .frequency input[type='number'],
  .reel-frequency input[type='number'] {
    width: 30px;
  }
  .frequency input[type='range'],
  .reel-frequency input[type='range'] {
    width: 250px;
  }
  .current-values-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .current-values {
    flex: 1;
    background-color: #f9f9f9;
    padding: 10px;
    margin-right: 20px;
  }
  .current-values p {
    margin: 5px 0;
  }
  .buttons {
    display: flex;
    flex-direction: column;
  }
  .buttons button {
    margin-bottom: 10px;
    padding: 10px 20px;
  }

  .color-red {
    color: red;
  }

  .group-h {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    gap: 10px;
  }
  .group-h label {
    display: flex;
  }
  .group-h button {
    font-size: 13px;
  }
  .group-h input[type='number'] {
    width: 100px;
  }
  .group-h input[type='text'] {
    width: 250px;
  }
  .group-h select {
    width: 50px;
  }
  </style>