<template>
  <div class="top-bar">
    <div class="left"
          @mouseover="showTooltipEnvironment = true" 
          @mouseout="showTooltipEnvironment = false">
      Environment: <b>{{ systemEnv }}</b>

      <div v-if="showTooltipEnvironment" class="tooltip">
        <div class="group-v">
          API: <b>{{ systemApi }}</b><br>
          CDN: <b>{{ systemCdn }}</b><br>
          CDNAPP: <b>{{ systemCdnApp }}</b><br>
          CDNAPI: <b>{{ systemCdnApi }}</b>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="bottom-row">
        <p>Current config loaded: <b>{{ $root.getCurrentConfigId() }} {{ $root.isParSheetEnabled() ? '[PAR Sheet - enabled]' : '' }}</b></p>
      </div>
      <div class="bottom-row">
        <p>Current server config: <b>{{ $root.getServerConfigId() }}</b></p>
      </div>
    </div>

    <div class="right">
      <div v-if="authCheck">
        <div v-if="authRW" class="green">{{ authAs }} <button type="button" @click="clearReadWrite">Clear Read/Write</button></div>
        <div v-else class="red">{{ authAs }} <button type="button" @click="reqReadWrite">Ask for Read/Write</button></div>
      </div>
      Local Time: {{ currentTime }}
    </div>
  </div>
</template>

<script>
import { auth } from '@/js/auth.js';

export default {
  name: 'TopBar',
  data() {
    return {
      currentTime: '',
      systemEnv: this.$root.envVars.env || 'No env set',
      systemApi: this.$root.envVars.api || 'No api set',
      systemCdn: this.$root.envVars.cdn || 'No api set',
      systemCdnApp: this.$root.envVars.cdnapp || 'No api set',
      systemCdnApi: this.$root.envVars.cdnapi || 'No api set',
      selectedValue: null,
      showTooltipEnvironment: false,
      authCheck: auth.checkAuth(),
      authAs: auth.authAs(),
      authRW: false,
    };
  },
  methods: {
    updateTime() {
      const now = new Date();
      this.currentTime = now.toLocaleTimeString();
      const a = auth.authAs();
      const b = auth.readWrite();
      this.authRW = a === b;
    },
    clearReadWrite() {
      fetch(`${this.$root.envVars.api}/api/connect/cleanreadwrite`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${auth.autToken()}`
          }
        })
        .then(response => response.json())
        .then(data => {
          this.$root.handleSuccess('Clean Read/Write permissions allow!');
          auth.readWriteSet("");
          auth.login(data.Token);
          auth.authAs(data.Email);
        })
        .catch(error => {
          this.$root.handleError('Error fetching Clean Read/Write permissions: ' + error);
          this.isLoading = false;
        });
    },
    reqReadWrite() {
      fetch(`${this.$root.envVars.api}/api/connect/reqreadwrite`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${auth.autToken()}`
            }
          })
        .then(response => response.json())
        .then(data => {
          this.$root.handleSuccess('Read/Write permissions allow!');
          auth.readWriteSet(data.Email);
          auth.login(data.Token);
        })
        .catch(error => {
          this.$root.handleError('Error fetching Read/Write permissions: ' + error);
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.updateTime();
    setInterval(this.updateTime, 1000);
  },
};
</script>

<style scoped>
.top-bar {
  background-color: #333;
  color: white;
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  font-size: 16px;
}

.green {
  color: green;
}

.red {
  color: red;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-row {
  display: flex;
  align-items: center;
}

.top-row button,
.top-row select {
  margin-right: 10px;
}

.bottom-row {
  margin-top: 5px;
}

.bottom-row p {
  margin: 0;
  padding: 0;
}

.right {
  font-size: 14px;
}
</style>