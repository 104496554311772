<template>
  <div class="page-content">
    <div class="clickable-div" @click="isExpandedPanel = !isExpandedPanel">
      <label>{{ isExpandedPanel ? '▼' : '▶'  }} Pay Scatters {{ isExpandedPanel ? '' : '[CLOSED]' }}</label>
    </div>

    <div class="form-group" v-if="isExpandedPanel">
      <div class="group-h">
        <button @click="expandAll">Expand All</button>
        <button @click="collapseAll">Collapse All</button>
        <button @click="toggleShowZeroPays">{{ showZeroPays ? 'Hide Zero Pays' : 'Show Zero Pays' }}</button>
      </div>
      <div v-for="(symbol, index) in $parent.symbolsInfo.symbolInfo" :key="index" class="group-v" style="margin-bottom: 0px; gap: 0px;">
        <div v-if="symbol.typeofSymbol === 1 && symbol.active">
          <PayTableScatterComponent
            v-if="!$parent.parSheet.enabled"
            ref="scatterComponents"
            :symbols-freq="$parent.symbolsInfo" 
            :symbol="symbol"
            :number-of-reels="$parent.numberOfReels"
            :symbols-per-reel="$parent.symbsPerReel"
            :show-zero-pays="showZeroPays"
            :symbol-index="index"
          />
          <PayTableScatterComponentPARSheet
            v-else
            ref="scatterComponents"
            :symbols-freq="$parent.symbolsInfo" 
            :symbol="symbol"
            :number-of-reels="$parent.numberOfReels"
            :symbols-per-reel="$parent.symbsPerReel"
            :show-zero-pays="showZeroPays"
            :symbol-index="index"
            :isParSheetEnabled="$parent.parSheet.enabled"
          />
          <hr class="separator" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PayTableScatterComponent from './PayTableScatterComponent.vue';
import PayTableScatterComponentPARSheet from './PayTableScatterComponentPARSheet.vue';

export default {
  name: 'PayTableScattersComponent',
  components: {
    PayTableScatterComponent,
    PayTableScatterComponentPARSheet,
  },
  data() {
    return {
      isExpandedPanel: true,
      showZeroPays: true,
    };
  },
  methods: {
    setAllComponentsExpanded(expanded) {
      if (!this.$refs.scatterComponents) return;
      
      const components = Array.isArray(this.$refs.scatterComponents) 
        ? this.$refs.scatterComponents 
        : [this.$refs.scatterComponents];
        
      components.forEach(component => {
        component.expandedSymbol = expanded;
      });
    },
    expandAll() {
      this.setAllComponentsExpanded(true);
    },
    collapseAll() {
      this.setAllComponentsExpanded(false); 
    },
    toggleShowZeroPays() {
      this.showZeroPays = !this.showZeroPays;
    }
  }
};
</script>

<style scoped>
  .page-content {
    padding: 20px;
  }
  .form-group {
    align-items: center;
    margin-bottom: 15px;
    background-color: #d0d0d0;
    padding: 10px;
  }
  .group-h {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    gap: 10px;
  }

  .separator {
    height: 3px;
    margin: 10px 0;
  }
</style>