<template>
  <div>
    <h2>Par Sheet Strips</h2>
    <div v-if="isLoading">
      <p>Loading...</p>
    </div>
    <div v-else class="group-v">
      <div class="group-h">
        <button @click="fetchDefaultValues()" style="width: 150px;">Reload data</button>
        <button @click="saveSettings()" style="width: 150px;">Save PAR Sheet strips</button>
        <button @click="regenerateParSheet()" style="width: 150px;">Regenerate strips</button>
      </div>

      <hr class="separator" />

      <div class="group-v">
        <div v-for="index in reelsNo" :key="index">
          <label style="font-size: 12px; font-weight: normal;">Symbols Count on Reel {{ index }} : {{ symbolsForReel(index - 1) }} </label>
        </div>
      </div>

      <hr class="separator" />

      <div class="group-h">
        <div v-for="(strip, sIndex) in parSheet.strips" :key="sIndex">
          <div class="group-v" style="width: 100px">
            <div v-for="(item, iIndex) in strip.dist" :key="iIndex">
              <select 
                :value="item" 
                @change="e => strip.dist[iIndex] = e.target.value" 
                :style="{ 
                  color: isItemModified(sIndex, iIndex, item) ? 'white' : 'inherit',
                  backgroundColor: isItemModified(sIndex, iIndex, item) ? 'darkred' : getRandomColor(item)
                }"
                style="width: 100%; text-align: center;"
                @mouseover="isItemModified(sIndex, iIndex, item) && $root.showTooltipInfo('Old value: ' + defaultValues.parSheet.strips[sIndex].dist[iIndex], false)"
                @mouseleave="$root.hideTooltipInfo()">
                <option 
                  v-for="(option, index) in symbolsInfo.symbolInfo.filter(symbol => symbol.active).map(symbol => symbol.name).sort()" 
                  :key="index" 
                  :value="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import { auth } from '@/js/auth.js';
  import { statusCodes } from '@/data/statusCodes.js';
  import { generateParSheetStrips, parSheetSymbolsForReel } from '@/js/par-sheet.js';

  export default {
    name: 'SymbolsParSheetPage',
    components: {
    },
    data() {
      return {
        isLoading: true,
        symbolsInfo: {
          precision: 100,
          symbolTexs: [],
          symbolInfo: [],
          reelFrequencies: [],
          symbolFrequencies: [],
        },
        parSheet: null,
        reelsNo: 5,
        symbsPerReel: 3,
        defaultValues: {},
        colorMap: new Map()
      };
    },
    methods: {
      getRandomColor(key) {
        if (!this.colorMap.has(key)) {
          // Use golden ratio to generate evenly distributed hues
          const goldenRatio = 0.618033988749895;
          const hue = (this.colorMap.size * goldenRatio * 360) % 360;
          
          // Keep saturation and lightness relatively high for good visibility
          const saturation = 75; // Fixed saturation for consistency
          const lightness = 85; // Fixed lightness for consistency
          
          this.colorMap.set(key, `hsl(${hue}, ${saturation}%, ${lightness}%)`);
        }
        return this.colorMap.get(key);
      },
      symbolsForReel(reel) {
        return parSheetSymbolsForReel(this.symbolsInfo, reel);
      },
      fetchDefaultValues() {
        if (this.$root.getCurrentConfigId() === null) {
          this.$root.handleError('Empty getCurrentConfigId() variable!');
        } else {
          fetch(`${this.$root.envVars.api}/api/admin2/symbols/symbol/read?configId=${this.$root.getCurrentConfigId()}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.autToken()}`
              },
            })
            .then(response => response.json())
            .then(data => {
              this.symbolsInfo = { ... data.symbols };
              this.parSheet = { ... data.parSheet };
              this.reelsNo = data.reelsNo;
              this.symbsPerReel = data.symbsPerReel;
              this.isLoading = false;
              this.defaultValues = { 
                ... JSON.parse(JSON.stringify(this.symbolsInfo)), 
                parSheet: JSON.parse(JSON.stringify(this.parSheet))
              };
              this.$root.handleSuccess('Data loaded!');
            })
            .catch(error => {
              this.$root.handleError('Error fetching data from server - ' + error);
            });
        }
      },
      saveSettings() {
        if (this.$root.getCurrentConfigId() === null) {
          this.$root.handleError('Empty getCurrentConfigId() variable!');
        } else {
          fetch(`${this.$root.envVars.api}/api/admin2/configs/set/parsheet?configId=${this.$root.getCurrentConfigId()}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.autToken()}`
              },
              body: JSON.stringify(this.parSheet),
            })
            .then(response => {
              if (!response.ok) {
                if (response.status === statusCodes.StatusTokenIdMismatch) {
                  auth.readWriteSet("");
                  throw new Error('Ask for Write permissions');
                }
                throw new Error('Network response was not ok');
              }
            })
            .then(() => {
              this.$root.handleSuccess('Data sent to server!');
              this.defaultValues = { 
                ... JSON.parse(JSON.stringify(this.symbolsInfo)), 
                parSheet: JSON.parse(JSON.stringify(this.parSheet))
              };
            })
            .catch(error => {
              this.$root.handleError(error);
            });
        }
      },
      regenerateParSheet() {
        generateParSheetStrips(this.symbolsInfo, this.parSheet, this.reelsNo);
        if (this.defaultValues.parSheet.strips === null) {
          this.defaultValues.parSheet.strips = this.parSheet.strips.map(strip => ({
            dist: strip.dist.map(() => '-')
          }));
        }
      },
      isDefaultValueAvailable(sIndex, iIndex) {
        if (!this.defaultValues.parSheet.strips ||
            !this.defaultValues.parSheet.strips[sIndex] ||
            !this.defaultValues.parSheet.strips[sIndex].dist[iIndex])
            return false;
        return true;
      },
      isItemModified(sIndex, iIndex, item) {
        if (!this.isDefaultValueAvailable(sIndex, iIndex))
            return true;
        return item !== this.defaultValues.parSheet.strips[sIndex].dist[iIndex];
      }
    },
    mounted() {
      this.fetchDefaultValues();
    }
  };
</script>

<style scoped>
  .group-v {
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  .group-v label {
    display: flex;
  }
  .group-h {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    gap: 10px;
  }
  .group-h label {
    display: flex;
  }
  .separator {
    border: none;
    border-top: 1px solid #000;
    margin: 10px 0;
  }
</style>