<template>
    <div class="page-content">
      <div class="clickable-div" @click="isExpandedPanelBasic = !isExpandedPanelBasic">
        <label>{{ isExpandedPanelBasic ? '▼' : '▶' }} Basic section {{ isExpandedPanelBasic ? '' : '[CLOSED]' }}</label>
      </div>

      <div v-if="isExpandedPanelBasic">
        <div class="form-group">
          <button @click="downloadAllShowDialog">Download all</button>
          <button @click="resetCdnApiCache">Reset CDN API server cache</button>
          <div class="group-h">
            <label>Current app: </label>
            <input type="text" v-model="$root.envVars.cdnapp"/>
            <button @click="setConfigCdnApp">Set</button>
            <button @click="getConfigCdnApp">Get</button>
          </div>
          <div class="group-h">
            <label>Current cdn: </label>
            <input type="text" v-model="$root.envVars.cdn"/>
            <button @click="setConfigCdn">Set</button>
            <button @click="getConfigCdn">Get</button>
          </div>
          <div class="group-h">
            <label>Current api: </label>
            <input type="text" v-model="$root.envVars.cdnapi"/>
            <button @click="setConfigCdnApi">Set</button>
            <button @click="getConfigCdnApi">Get</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script>
  import { auth } from '@/js/auth.js';
  import { statusCodes } from '@/data/statusCodes.js';

  export default {
    name: 'CDNSubpageBasic',
    components: {
    },
    data() {
      return {
        isExpandedPanelBasic: false,
      };
    },
    methods: {
      // set.get cdn-app
      setConfigCdnApp() {
        fetch(`${this.$root.envVars.api}/api/admin2/configs/set/cdnapp?configId=${this.$root.getCurrentConfigId()}&cdnapp=${this.$root.envVars.cdnapp}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
            body: "{}"
          })
          .then((response) => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            this.$root.handleSuccess('Cdnapp set!');
          })
          .catch(error => {
            this.$root.handleError(error);
          })
      },
      getConfigCdnApp() {
        fetch(`${this.$root.envVars.api}/api/admin2/configs/get/cdnapp?configId=${this.$root.getCurrentConfigId()}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
          })
          .then(response => {
            response.json().then(result => {
              this.$root.envVars.cdnapp = result.cdnapp;
              this.$root.handleSuccess('Cdnapp successfully get!');
            })
            .catch(error => {
              this.$root.handleError('Cdnapp get json error: ' + error);
            })
          })
          .catch(error => {
            this.$root.handleError('Cdnapp get error: ' + error);
          })
      },
      // set.get cdn-address
      setConfigCdn() {
        fetch(`${this.$root.envVars.api}/api/admin2/configs/set/cdn?configId=${this.$root.getCurrentConfigId()}&cdn=${this.$root.envVars.cdn}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
            body: "{}"
          })
          .then((response) => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            this.$root.handleSuccess('Cdn-address set!');
          })
          .catch(error => {
            this.$root.handleError(error);
          })
      },
      getConfigCdn() {
        fetch(`${this.$root.envVars.api}/api/admin2/configs/get/cdn?configId=${this.$root.getCurrentConfigId()}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
          })
          .then(response => {
            response.json().then(result => {
              this.$root.envVars.cdn = result.cdn;
              this.$root.handleSuccess('Cdn-address successfully get!');
            })
            .catch(error => {
              this.$root.handleError('Cdn-address get json error: ' + error);
            })
          })
          .catch(error => {
            this.$root.handleError('Cdn-address get error: ' + error);
          })
      },
      // set.get cdn-address
      setConfigCdnApi() {
        fetch(`${this.$root.envVars.api}/api/admin2/configs/set/cdnapi?configId=${this.$root.getCurrentConfigId()}&cdnapi=${this.$root.envVars.cdnapi}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
            body: "{}"
          })
          .then((response) => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            this.$root.handleSuccess('Cdn-api set!');
          })
          .catch(error => {
            this.$root.handleError(error);
          })
      },
      getConfigCdnApi() {
        fetch(`${this.$root.envVars.api}/api/admin2/configs/get/cdnapi?configId=${this.$root.getCurrentConfigId()}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
          })
          .then(response => {
            response.json().then(result => {
              this.$root.envVars.cdnapi = result.cdnapi;
              this.$root.handleSuccess('Cdn-api successfully get!');
            })
            .catch(error => {
              this.$root.handleError('Cdn-api get json error: ' + error);
            })
          })
          .catch(error => {
            this.$root.handleError('Cdn-api get error: ' + error);
          })
      },
      // download all
      downloadAllShowDialog() {
        fetch(`${this.$root.envVars.cdnapi}/downloadAllRes`).then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          response.blob().then(blob => {
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = 'all_resources.zip';
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(downloadUrl); 
            this.$root.handleSuccess("File downloaded successfully" + response);
          });
        });
      },
      resetCdnApiCache() {
        fetch(`${this.$root.envVars.cdnapi}/clearCache`).then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          this.$root.handleSuccess("Cache reset successfully" + response);
        });
      },
    },
    mounted() {
      
    },
  };
</script>

<style scoped>
  .page-content {
    padding: 20px;
  }

  .form-group {
    align-items: center;
    margin-bottom: 15px;
    background-color: #d0d0d0;
    padding: 10px;
  }
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .form-group input[type='number'],
  .form-group select,
  .form-group input[type='text'] {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .form-group input[type='range'] {
    width: 100%;
  }
  .group-h {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 10px;
  }
  .group-h label {
    display: flex;
  }
  .group-h button {
    font-size: 13px;
    height: 22px;
  }
  .group-h input[type='number'] {
    width: 100px;
  }
  .group-h input[type='text'] {
    width: 150px;
  }
  .large input[type='text'] {
    width: 300px;
  }
  .group-h select {
    width: 200px;
  }

  .group-v {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: left;
    gap: 10px
  }
  .group-v label {
    display: flex;
  }
  .group-v input[type='number'] {
    width: 100px;
  }
  .group-v input[type='text'] {
    width: 250px;
  }
  .group-v select {
    width: 250px;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}
.small-image {
  width: 30px;
  height: auto;
  /* border: 1px solid #ccc; */
}
.tooltip {
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px;
  /* background-color: #333;
  color: #fff; */
  /* padding: 5px; */
  /* border-radius: 4px; */
  font-size: 12px;
  white-space: nowrap;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
  z-index: 1000;
}
  </style>