<template>
  <div class="clickable-div" @click="isExpandedPanel = !isExpandedPanel">
    <label>{{ isExpandedPanel ? '▼' : '▶' }} Settings Management {{ isExpandedPanel ? '' : '[CLOSED]' }}</label>
  </div >
  <div class="form-group" v-if="isExpandedPanel">
    <div v-if="!$parent.isLoading">
      <div v-if="isLoadingSettings" class="group-h">
        Loading ...
      </div>
      <div v-else class="group-v">
        <button @click="fetchSettings">Refresh settings data from server</button>
        <div class="group-v" v-if="settingOptions.length">
          <div class="group-h">
            <select v-model="settingSelectedValue" @change="handleSelectionChange">
              <option v-for="item in settingOptions" :key="item.id" :value="item.value">
                {{ item.label }}
              </option>
            </select>
            <button @click="setActiveForServer">Set as Active</button>
          </div>
          <div class="group-h">
            <input type="text" id="renameSetting" v-model="settingNewName" />
            <button @click="renameSettingsForServer">Rename</button>
          </div>
          <div class="group-v">
            <label> 
              Config: {{ settingOptions[settingOptions.findIndex(x => x.value == settingSelectedValue)].cid }}
            </label>
            <label> 
              Status: {{ settingOptions[settingOptions.findIndex(x => x.value == settingSelectedValue)].cid === $root.getServerConfigId() ? 'ACTIVE' : 'INACTIVE' }}
            </label>
            <div class="group-h">
            <input type="text" id="configSetting" v-model="settingConfigName" />
            <button @click="setConfigSettingsForServer">Set config</button>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import { auth } from '@/js/auth.js';
  import { statusCodes } from '@/data/statusCodes.js';

  export default {
    name: 'GeneralPageSettings',
    data() {
      return {
        systemEnv: this.$root.envVars.env || 'No env set',

        isLoadingSettings: false,
        
        settingOptions: [],
        settingSelectedValue: null,
        settingNewName: '',
        settingConfigName: '',

        isExpandedPanel: false,
      };
    },
    methods: {
      handleSelectionChange() {
        this.settingNewName = this.settingSelectedValue;
        this.settingConfigName = this.settingOptions[this.settingOptions.findIndex(x => x.value == this.settingSelectedValue)].cid;
      },
      updateSettingsUI(data) {
        const seletion = data?.data?.length > 0 ? this.$root.getServerConfigId() ? data.data.find(x => x.cid === this.$root.getServerConfigId()).value : data.data[0].value  : null;
        
        this.settingOptions = data.data;

        this.settingSelectedValue = seletion;
        this.handleSelectionChange();

        this.isLoadingSettings = false;
        this.$root.handleSuccess('Data loaded!');
      },
      fetchSettings() {
        this.isLoadingSettings = true;
        fetch(`${this.$root.envVars.api}/api/admin2/settings/read`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            this.updateSettingsUI(data);
          })
          .catch(error => {
            this.$root.handleError('Error fetching options:' + error);
          });
      },
      setActiveForServer() {
        this.isLoadingSettings = true;
        fetch(`${this.$root.envVars.api}/api/admin2/settings/setactive?sid=${this.settingSelectedValue}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
            body: "{}"
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            const sel = this.settingOptions[this.settingOptions.findIndex(x => x.value == this.settingSelectedValue)];
            this.$root.setServerConfigId(sel.cid);
            this.updateSettingsUI(data);
            this.settingSelectedValue = sel.value;
          })
          .catch(error => {
            this.$root.handleError(error);
          });
      },
      renameSettingsForServer() {
        this.isLoadingSettings = true;
        fetch(`${this.$root.envVars.api}/api/admin2/settings/rename?sid=${this.settingSelectedValue}&nsid=${this.settingNewName}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
            body: "{}"
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(() => {
            this.fetchSettings();
          })
          .catch(error => {
            this.$root.handleError(error);
          });
      },
      setConfigSettingsForServer() {
        this.isLoadingSettings = true;
        fetch(`${this.$root.envVars.api}/api/admin2/settings/setconfig?sid=${this.settingSelectedValue}&cid=${this.settingConfigName}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
            body: "{}"
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(() => {
            this.fetchSettings();
          })
          .catch(error => {
            this.$root.handleError(error);
          });
      },
    },
    mounted() {
      
    }
  };
  </script>
  
<style scoped>
  .page-content {
    padding: 20px;
  }
  .form-group {
    align-items: center;
    margin-bottom: 15px;
    background-color: #d0d0d0;
    padding: 10px;
  }
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .form-group input[type='number'],
  .form-group select,
  .form-group input[type='text'] {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .form-group input[type='range'] {
    width: 100%;
  }
  .group-h {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    gap: 10px;
  }
  .group-h label {
    display: flex;
  }
  .group-h button {
    font-size: 13px;
    height: 18px;
  }
  .group-h input[type='number'] {
    width: 100px;
  }
  .group-h input[type='text'] {
    width: 250px;
  }
  .group-h select {
    width: 200px;
  }

  .group-v {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: left;
    gap: 10px
  }
  .group-v label {
    display: flex;
  }
  .group-v input[type='number'] {
    width: 100px;
  }
  .group-v input[type='text'] {
    width: 250px;
  }
  .group-v select {
    width: 250px;
  }
  
  .long-select {
    width: 300px;
  }

  .separator {
    border: none;
    border-top: 1px solid #000000;
    margin: 10px 0;
  }

  .button {
    width: auto;
    white-space: nowrap;
    padding: 5px 10px;
  }
</style>