export const statusCodes = {
    // from StatusCodes
    /// <summary>HTTP status code 200.</summary>
    Status200OK: 200,

    // ST Specific codes
    /// <summary>Token Id mismatch.</summary>
    StatusTokenIdMismatch: 10401,
    /// <summary>Invalid token Id.</summary>
    StatusInvalidTokenId: 10402,
    /// <summary>Missing token Id.</summary>
    StatusMissingTokenId: 10403   
};
