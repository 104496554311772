<template>
    <div v-if="!$parent.isLoading">
      <div class="clickable-div" @click="isExpandedPanel = !isExpandedPanel">
        <label>{{ isExpandedPanel ? '▼' : '▶' }} Config Management {{ isExpandedPanel ? '' : '[CLOSED]' }}</label>
      </div >
      <div class="form-group" v-if="isExpandedPanel">
        <div class="group-v">
          <div class="group-h">
            <button @click="fetchConfigs">Refresh config data from server</button>
          </div>

          <hr class="separator" />

          <div class="group-v" v-if="configOptions.length">
            <div class="group-h">
              <label>Select config: </label>
              <select v-model="configSelectedValue">
                <option v-for="item in configOptions" :key="item.id" :value="item.value">
                  {{ item.label }}
                </option>
              </select>
              <button @click="loadValue">Use</button>
              <button @click="deleteConfig">Delete</button>
              <button @click="cloneConfig">Clone</button>
            </div>

            <hr class="separator" />

            <div class="group-h">
              <label>Rename config: </label>
              <input type="text" id="renameConfig" v-model="configNewName" />
              <button @click="renameValue">Rename config</button>
            </div>

            <hr class="separator" />

            <div class="group-v">
              <div class="group-h">
                <label>Export config: </label>
                <button @click="exportConfig">Execute</button>
              </div>
              <div class="group-h">
                <label>Import config: </label>
                <input type="file" @change="importConfig" accept=".json" />
              </div>
            </div>

            <hr class="separator" />

            <ConfigSettingsComponent />

          </div>
        </div>
      </div>
    </div>
  </template>
  
<script>
  import { cdn_listAllIn } from '@/js/cdn-apis';
  import { auth } from '@/js/auth.js';
  import { statusCodes } from '@/data/statusCodes.js';
  import ConfigSettingsComponent from './components/ConfigSettingsComponent.vue';

  export default {
    name: 'GeneralPageConfigs',
    components: {
      ConfigSettingsComponent
    },
    data() {
      return {
        systemEnv: this.$root.envVars.env || 'No env set',
        configOptions: [],
        configSelectedValue: null,
        configNewName: '',
        isExpandedPanel: true,
      };
    },
    methods: {
      updateIsLoading(loading) {
        this.$parent.updateIsLoading(loading);
      },

      async updateConfigUI(data) {
        this.configOptions = data.data;

        let forceList = false;
        if (this.$root.getCurrentConfigId() === null) {
          this.$root.setCurrentConfigId(data.serverCid);
          this.configSelectedValue = data.serverCid;
          this.configNewName = data.serverCid;

          forceList = true;
        } else {
          this.configSelectedValue = this.$root.getCurrentConfigId();
          this.configNewName = this.$root.getCurrentConfigId();
        }
        this.$root.setServerConfigId(data.serverCid);

        const configData = data.data.find(p => p.value ===  this.$root.getCurrentConfigId());
        if (configData.cdn) {
          this.$root.envVars.cdn = configData.cdn.cdn;
          this.$root.envVars.cdnapp = configData.cdn.app;
          this.$root.envVars.cdnapi = configData.cdn.api;
        }

        if (forceList) {
          await cdn_listAllIn("raw", this.$root.envVars.cdnapi, "texs")
            .then(
              result => {
                this.$root.cdn.texs.raw = result;
              });
          await cdn_listAllIn("raw", this.$root.envVars.cdnapi, "anims")
            .then(
              result => {
                this.$root.cdn.anims.raw = result;
              });
          await cdn_listAllIn("raw", this.$root.envVars.cdnapi, "audio")
            .then(
              result => {
                this.$root.cdn.audio.raw = result;
              });
        }
        
        this.updateIsLoading(false);
        this.$root.handleSuccess('Data loaded!');
      },
      fetchConfigs() {
        this.updateIsLoading(true);
        fetch(`${this.$root.envVars.api}/api/admin2/configs/read`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            this.updateConfigUI(data);
          })
          .catch(error => {
            this.$root.handleError('Error fetching options:' + error);
          });
      },
      loadValue() {
        if (this.configSelectedValue !== null) {
          this.$root.setCurrentConfigId(this.configSelectedValue);
          this.configNewName = this.configSelectedValue;
        }
      },
      deleteConfig() {
        fetch(`${this.$root.envVars.api}/api/admin2/configs/delete?cid=${this.$root.getCurrentConfigId()}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
            body: "{}"
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            this.fetchConfigs();
          })
          .catch(error => {
            this.$root.handleError(error);
          });
      },
      cloneConfig() {
        fetch(`${this.$root.envVars.api}/api/admin2/configs/clone?cid=${this.$root.getCurrentConfigId()}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
            body: "{}"
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            this.fetchConfigs();
          })
          .catch(error => {
            this.$root.handleError(error);
          });
      },
      renameValue() {
        fetch(`${this.$root.envVars.api}/api/admin2/configs/rename?configId=${this.$root.getCurrentConfigId()}&newConfigId=${this.configNewName}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
            body: "{}"
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            this.$root.setCurrentConfigId(null);
            this.updateConfigUI(data);
          })
          .catch(error => {
            this.$root.handleError(error);
          });
      },
      exportConfig() {
        fetch(`${this.$root.envVars.api}/api/admin2/configs/export?cid=${this.$root.getCurrentConfigId()}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            const dataStr = JSON.stringify(data, null, 2);
            const blob = new Blob([dataStr], { type: "application/json" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = this.$root.getCurrentConfigId() + ".json";
            link.click();
            URL.revokeObjectURL(url);
          })
          .catch(error => {
            this.$root.handleError('Error fetching options:' + error);
          });
      },
      importConfig(event) {
        const file = event.target.files[0];
        if (file && file.type === "application/json") {
          const reader = new FileReader();
          reader.onload = (e) => {
            try {
              const json = JSON.parse(e.target.result);
              fetch(`${this.$root.envVars.api}/api/admin2/configs/import`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.autToken()}`
                  },
                  body: JSON.stringify(json),
                })
                .then(response => {
                  if (!response.ok) {
                    if (response.status === statusCodes.StatusTokenIdMismatch) {
                      auth.readWriteSet("");
                      throw new Error('Ask for Write permissions');
                    }
                    throw new Error('Network response was not ok');
                  }
                })
                .then(() => {
                  this.fetchConfigs();
                })
                .catch(error => {
                  this.$root.handleError(error);
                });
            } catch (error) {
              this.$root.handleError('Invalid JSON file.');
            }
          };
          reader.readAsText(file);
        } else {
          this.$root.handleError('Please select a valid JSON file.');
        }
      },
    },
    mounted() {
      this.fetchConfigs();
    }
  };
  </script>
  
<style scoped>
  .page-content {
    padding: 20px;
  }
  .form-group {
    align-items: center;
    margin-bottom: 15px;
    background-color: #d0d0d0;
    padding: 10px;
  }
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .form-group input[type='number'],
  .form-group select,
  .form-group input[type='text'] {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .form-group input[type='range'] {
    width: 100%;
  }
  .group-h {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    gap: 10px;
  }
  .group-h label {
    display: flex;
  }
  .group-h button {
    font-size: 13px;
    height: 18px;
  }
  .group-h input[type='number'] {
    width: 100px;
  }
  .group-h input[type='text'] {
    width: 250px;
  }
  .group-h select {
    width: 200px;
  }

  .group-v {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: left;
    gap: 10px
  }
  .group-v label {
    display: flex;
  }
  .group-v input[type='number'] {
    width: 100px;
  }
  .group-v input[type='text'] {
    width: 250px;
  }
  .group-v select {
    width: 250px;
  }
  
  .separator {
    width: 100%;
    margin: 0;
  }
  .button {
    width: auto;
    white-space: nowrap;
    padding: 5px 10px;
  }
  .normal-field {
    font-weight: normal !important;
  }
</style>