<template>
    <div class="page-content">
      <div class="clickable-div" @click="isExpandedPanelTextures = !isExpandedPanelTextures">
        <label>{{ isExpandedPanelTextures ? '▼' : '▶' }} Textures section {{ isExpandedPanelTextures ? '' : '[CLOSED]' }}</label>
      </div>

      <div v-if="isExpandedPanelTextures">
        <div class="form-group">
          <label>RAW Textures</label>
          <div class="group-h">
            <button @click="toggleShowModalUploadTexture">Upload new texture</button>
            <transition name="fade">
              <div v-if="showModalUploadTexture" class="modal-overlay" @click="closeModalUploadTexture">
                <div class="modal-content" @click.stop>
                  <input type="file" @change="selectedFile" accept=".png"/>
                  <button @click="buttonActionModalUploadTexture">Upload</button>
                </div>
              </div>
            </transition>

            <button @click="toggleShowModalUploadTextures">Upload textures</button>
            <transition name="fade">
              <div v-if="showModalUploadTextures" class="modal-overlay" @click="closeModalUploadTextures">
                <div class="modal-content" @click.stop>
                  <input type="file" @change="selectedFile" accept=".zip"/>
                  <button @click="buttonActionModalUploadTextures">Upload</button>
                </div>
              </div>
            </transition>

            <button @click="askDeleteAllShowDialog">Delete all textures</button>
            <ConfirmationDialog 
              v-if="showAskDeleteAll"
              :title="'Delete all textures'"
              :content="'Are you sure you want to delete all textures?'"
              :onConfirm="askDeleteAllHandleConfirm"
              :onClose="askDeleteAllHandleClose"
            />

            <button @click="downloadAllShowDialog">Download all textures</button>
          </div>
          <div class="group-h">
            <button @click="toggleCollapseRawTexs">{{ isCollapsed_rawTexs ? '+' : '-' }}</button>
            <button @click="refreshRawTextures">Refresh Textures</button>
          </div>
          <transition name="fade">
            <ul v-if="!isCollapsed_rawTexs">
              <li v-for="(item, index) in $root.cdn.texs.raw" :key="index">
                <div class="group-h">
                  <button @click="removeTexture(item)">remove</button>
                  <label>{{ item }} ({{ $root.cdn.texs.rawSize[index] || 'Loading...' }} KB)</label>
                  <div class="tooltip-wrapper">
                    <img 
                      :src="`${$root.getCdnPath()}raw/texs/${item}.png`" 
                      class="small-image"
                      @mouseover="showPngImgTooltip(index)"
                      @mouseout="hidePngImgTooltip" />
                    <div v-if="visiblePngImgTooltip === index" class="tooltip">
                      <img :src="`${$root.getCdnPath()}raw/texs/${item}.png`"/>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </transition>
        </div>
      
        <div class="form-group">
          <label>Check all textures in packages</label>
          <div class="group-h">
            <button @click="toggleCollapseAllTexs">{{ isCollapsed_allTexs ? '+' : '-' }}</button>
            <button @click="refreshAllTextures">Refresh Textures</button>
          </div>
          <transition name="fade">
            <div class="group-h" v-if="!isCollapsed_allTexs">
              <ul>
                <li><label>RAW</label></li>
                <li v-for="(item, index) in $root.cdn.texs.raw" :key="index">
                  <div class="tooltip-wrapper">
                    <label 
                      @mouseover="showPngImgTooltip(index)"
                      @mouseout="hidePngImgTooltip">
                      {{ item }} ({{ $root.cdn.texs.rawSize[index] || 'Loading...' }} KB)
                    </label>
                    <div v-if="visiblePngImgTooltip === index" class="tooltip">
                      <img :src="`${$root.getCdnPath()}raw/texs/${item}.png`"/>
                    </div>
                  </div>
                </li>
              </ul>
              <ul>
                <li><label>DESKTOP</label></li>
                <li v-for="(item, index) in $root.cdn.texs.d" :key="index">
                  <div class="tooltip-wrapper">
                    <label 
                      @mouseover="showPngImgTooltip(index)"
                      @mouseout="hidePngImgTooltip">
                      {{ item }} ({{ $root.cdn.texs.dSize[index] || 'Loading...' }} KB)
                    </label>
                    <div v-if="visiblePngImgTooltip === index" class="tooltip">
                      <img :src="`${$root.getCdnPath()}d/texs/${item}.webp`"/>
                    </div>
                  </div>
                </li>
              </ul>
              <ul>
                <li><label>MOBILE</label></li>
                <li v-for="(item, index) in $root.cdn.texs.m" :key="index">
                  <div class="tooltip-wrapper">
                    <label 
                      @mouseover="showPngImgTooltip(index)"
                      @mouseout="hidePngImgTooltip">
                      {{ item }} ({{ $root.cdn.texs.mSize[index] || 'Loading...' }} KB)
                    </label>
                    <div v-if="visiblePngImgTooltip === index" class="tooltip">
                      <img :src="`${$root.getCdnPath()}m/texs/${item}.webp`"/>
                    </div>
                  </div>
                </li>
              </ul>
              <ul>
                <li><label>TINY</label></li>
                <li v-for="(item, index) in $root.cdn.texs.t" :key="index">
                  <div class="tooltip-wrapper">
                    <label 
                      @mouseover="showPngImgTooltip(index)"
                      @mouseout="hidePngImgTooltip">
                      {{ item }} ({{ $root.cdn.texs.tSize[index] || 'Loading...' }} KB)
                    </label>
                    <div v-if="visiblePngImgTooltip === index" class="tooltip">
                      <img :src="`${$root.getCdnPath()}t/texs/${item}.webp`"/>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { cdn_listAllIn } from '@/js/cdn-apis';
  import ConfirmationDialog from '../shared/ConfirmationDialog.vue';
  import { auth } from '@/js/auth.js';
  import { statusCodes } from '@/data/statusCodes.js';

  export default {
    name: 'CDNPageTextures',
    components: {
      ConfirmationDialog,
    },
    data() {
      return {
        isCollapsed_rawTexs: true,
        isCollapsed_allTexs: true,
        showModalUploadTexture: false,
        showModalUploadTextures: false,
        uploadSelectedFile: null,
        visiblePngImgTooltip: null,

        showAskDeleteAll: false,
        isExpandedPanelTextures: false,
      };
    },
    methods: {
      askDeleteAllShowDialog() {
        this.showAskDeleteAll = true;
      },
      askDeleteAllHandleConfirm() {
        this.removeAllTextures();
        this.askDeleteAllHandleClose();
      },
      askDeleteAllHandleClose() {
        this.showAskDeleteAll = false;
      },

      downloadAllShowDialog() {
        fetch(`${this.$root.envVars.cdnapi}/downloadAll/texs`).then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          response.blob().then(blob => {
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = 'all_resources_texs.zip';
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(downloadUrl); 
            this.$root.handleSuccess("File downloaded successfully: " + response);
          });
        });
      },

      showPngImgTooltip(index) {
        this.visiblePngImgTooltip = index;
      },
      hidePngImgTooltip() {
        this.visiblePngImgTooltip = null;
      },

      selectedFile(event) {
        this.uploadSelectedFile = event.target.files[0];
      },
      // upload texture
      toggleShowModalUploadTexture() {
        this.showModalUploadTexture = !this.showModalUploadTexture;
      },
      closeModalUploadTexture() {
        this.showModalUploadTexture = false;
      },
      buttonActionModalUploadTexture() {
        if (this.uploadSelectedFile) {
          const formData = new FormData();
          formData.append("file", this.uploadSelectedFile);

          fetch(`${this.$root.envVars.cdnapi}/uploadTex`, {
            method: "POST",
            body: formData,
          }).then(response => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            response.json().then(result => {
              this.$root.handleSuccess("File uploaded successfully", result);
              this.refreshAllTextures();
            }).catch(error => {
              this.$root.handleError("Something wrong happened:", error);
            });
          }).catch(error => {
            this.$root.handleError(error);
          });
          this.closeModalUploadTexture();
        } else {
          alert(`Select a PNG file to upload!`);
        }
      },

      // upload zipped textures
      toggleShowModalUploadTextures() {
        this.showModalUploadTextures = !this.showModalUploadTextures;
      },
      closeModalUploadTextures() {
        this.showModalUploadTextures = false;
      },
      buttonActionModalUploadTextures() {
        if (this.uploadSelectedFile) {
          const formData = new FormData();
          formData.append("file", this.uploadSelectedFile);

          fetch(`${this.$root.envVars.cdnapi}/uploadTexsAsZip`, {
            method: "POST",
            body: formData,
          }).then(response => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            response.json().then(result => {
              this.$root.handleSuccess("File uploaded successfully", result);
              this.refreshAllTextures();
            }).catch(error => {
              this.$root.handleError("Something wrong happened:", error);
            });
          }).catch(error => {
            this.$root.handleError(error);
          });
          this.closeModalUploadTextures();
        } else {
          alert(`Select a ZIP file to upload!`);
        }
      },

      // remove textures
      removeAllTextures() {
        fetch(`${this.$root.envVars.cdnapi}/clearAllTextures`).then(response => {
            response.json().then(result => {
              this.$root.handleSuccess("Textures successfully removed", result);
            }).catch(error => {
              this.$root.handleError("Something wrong happened:", error);
            });
          }).catch(error => {
            this.$root.handleError("Something wrong happened:", error);
          });
      },
      removeTexture(tex) {
        fetch(`${this.$root.envVars.cdnapi}/clearTexture/${tex}`).then(response => {
            response.json().then(result => {
              this.$root.handleSuccess("Texture successfully removed", result);
              this.refreshAllTextures();
            }).catch(error => {
              this.$root.handleError("Something wrong happened:", error);
            });
          }).catch(error => {
            this.$root.handleError("Something wrong happened:", error);
          });
      },

      // raw textures
      toggleCollapseRawTexs() {
        this.isCollapsed_rawTexs = !this.isCollapsed_rawTexs;
      },
      refreshRawTextures() {
        cdn_listAllIn("raw", this.$root.envVars.cdnapi, "texs")
            .then(
              async result => {
                this.$root.cdn.texs.raw = result;
                this.refreshRawTexturesSize();
              });
      },
      async refreshRawTexturesSize() {
        const baseUrl = `${this.$root.getCdnPath()}raw/texs/`;
        this.$root.cdn.texs.rawSize = await Promise.all(
          this.$root.cdn.texs.raw.map(async (el) => {
            const s = await this.$root.getFileSize(`${baseUrl}${el}.png`);
            return s;
          }),
        );
      },
      async refreshDesktopTexturesSize() {
        const baseUrl = `${this.$root.getCdnPath()}d/texs/`;
        this.$root.cdn.texs.dSize = await Promise.all(
          this.$root.cdn.texs.d.map(async (el) => {
            const s = await this.$root.getFileSize(`${baseUrl}${el}.webp`);
            return s;
          }),
        );
      },
      async refreshMobileTexturesSize() {
        const baseUrl = `${this.$root.getCdnPath()}m/texs/`;
        this.$root.cdn.texs.mSize = await Promise.all(
          this.$root.cdn.texs.m.map(async (el) => {
            const s = await this.$root.getFileSize(`${baseUrl}${el}.webp`);
            return s;
          }),
        );
      },
      async refreshTinyTexturesSize() {
        const baseUrl = `${this.$root.getCdnPath()}t/texs/`;
        this.$root.cdn.texs.tSize = await Promise.all(
          this.$root.cdn.texs.t.map(async (el) => {
            const s = await this.$root.getFileSize(`${baseUrl}${el}.webp`);
            return s;
          }),
        );
      },

      // all textures
      toggleCollapseAllTexs() {
        this.isCollapsed_allTexs = !this.isCollapsed_allTexs;
      },
      refreshAllTextures() {
        cdn_listAllIn("raw", this.$root.envVars.cdnapi, "texs")
            .then(
              result => {
                this.$root.cdn.texs.raw = result;
                this.refreshRawTexturesSize();
              });

        cdn_listAllIn("d", this.$root.envVars.cdnapi, "texs")
            .then(
              result => {
                this.$root.cdn.texs.d = result;
                this.refreshDesktopTexturesSize();
              });

        cdn_listAllIn("m", this.$root.envVars.cdnapi, "texs")
            .then(
              result => {
                this.$root.cdn.texs.m = result;
                this.refreshMobileTexturesSize();
              });

        cdn_listAllIn("t", this.$root.envVars.cdnapi, "texs")
            .then(
              result => {
                this.$root.cdn.texs.t = result;
                this.refreshTinyTexturesSize();
              });
      },
    },
    mounted() {
      this.refreshRawTexturesSize();
    },
  };
  </script>
  
  <style scoped>
  .page-content {
    padding: 20px;
  }

  .form-group {
    align-items: center;
    margin-bottom: 15px;
    background-color: #d0d0d0;
    padding: 10px;
  }
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .form-group input[type='number'],
  .form-group select,
  .form-group input[type='text'] {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .form-group input[type='range'] {
    width: 100%;
  }
  .group-h {
    display: flex;
    
    margin-bottom: 10px;
    gap: 10px;
  }
  .group-h label {
    display: flex;
  }
  .group-h button {
    font-size: 13px;
    height: 18px;
  }
  .group-h input[type='number'] {
    width: 100px;
  }
  .group-h input[type='text'] {
    width: 250px;
  }
  .group-h select {
    width: 200px;
  }

  .group-v {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: left;
    gap: 10px
  }
  .group-v label {
    display: flex;
  }
  .group-v input[type='number'] {
    width: 100px;
  }
  .group-v input[type='text'] {
    width: 250px;
  }
  .group-v select {
    width: 250px;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}
.buttons {
  margin-top: 10px;
}
.buttons button {
  margin-right: 10px;
}
button {
  margin-top: 10px;
}
.small-image {
  width: 30px;
  height: auto;
  /* border: 1px solid #ccc; */
}
.tooltip {
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px;
  /* background-color: #333;
  color: #fff; */
  /* padding: 5px; */
  /* border-radius: 4px; */
  font-size: 12px;
  white-space: nowrap;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
  z-index: 1000;
}
  </style>