<template>
  <div class="page-content">
    <h2>Pay Table Settings</h2>

    <div v-if="isLoading">
      <p>Loading...</p>
    </div>
    <div v-else>
      <div class="form-group">
        <PayTableComponent ref="child"/>
      </div>
    </div>
  </div>
  <div class="fixed-box">
    <button type="button" @click="fetchDefaultValues">Refresh from server</button>
    <button type="button" @click="saveSettings">Save pay table</button>
  </div>
</template>

<script>
import { auth } from '@/js/auth.js';
import { statusCodes } from '@/data/statusCodes.js';
import PayTableComponent from './PayTableComponent.vue';

export default {
  name: 'PayTablePage',
  components: {
    PayTableComponent,
  },
  data() {
    return {
      isLoading: true,

      symbolsInfo: {
        precision: 100,
        symbolTexs: [],
        symbolInfo: [],
        reelFrequencies: [],
        symbolFrequencies: []
      },
      
      defaultValues: {},
      
      numberOfReels: 5,
      symbsPerReel: 3,
    };
  },
  methods: {
    fetchDefaultValues() {
      this.isLoading = true;
      fetch(`${this.$root.envVars.api}/api/admin2/paytable/read?configId=${this.$root.getCurrentConfigId()}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
          })
        .then(response => response.json())
        .then(data => {
          this.symbolsInfo = data.symbols;
          this.symbsPerReel = data.symbsPerReel;
          this.numberOfReels = data.numberOfReels;

          this.defaultValues = JSON.parse(JSON.stringify(data.symbols));
          
          this.isLoading = false;
          this.$root.handleSuccess('Data loaded!');
        })
        .catch(error => {
          this.$root.handleError('Error fetching data from server - ' + error);
          this.isLoading = false;
        });
    },
    saveSettings() {
      fetch(`${this.$root.envVars.api}/api/admin2/paytable/write?configId=${this.$root.getCurrentConfigId()}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.autToken()}`
        },
        body: JSON.stringify(this.symbolsInfo)
      })
        .then(response => {
          if (!response.ok) {
            if (response.status === statusCodes.StatusTokenIdMismatch) {
              auth.readWriteSet("");
              throw new Error('Ask for Write permissions');
            }
            throw new Error('Network response was not ok');
          }
          this.defaultValues = JSON.parse(JSON.stringify(this.symbolsInfo));
          this.$root.handleSuccess('Settings saved successfully.');
        })
        .catch(error => {
          this.$root.handleError(error);
        });
    },
  },
  mounted() {
    this.fetchDefaultValues();
  }
};
</script>

<style scoped>
  .fixed-box {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 70px;
    right: 20px;
    background-color: green;
    color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    gap: 10px;
  }
</style>