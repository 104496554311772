export function parSheetSymbolsForReel(symbolsInfo, reel) {
    return symbolsInfo.reelFrequencies.reduce((sum, reelFreq) => sum + reelFreq.freq[reel], 0);
}

export function generateParSheetStrips(symbolsInfo, parSheet, reelsNo) {
    if (!parSheet) {
        console.error("ParSheet object doesn't exist!");
        return;
    }
    
    if (!parSheet.enabled) {
        console.warn('ParSheet mode is not enabled!');
        return;
    }
    
    const parSheetStrips = [];

    for (let i = 0; i < reelsNo; i++) {
        const dist = [];
        
        for (let j = 0; j < symbolsInfo.symbolInfo.length; j++) {
            const symbol = symbolsInfo.symbolInfo[j];
            if (symbol.active) {
                for (let k = 0; k < symbolsInfo.reelFrequencies[j].freq[i]; k++) {
                    dist.push(symbol.name);
                }
            }
        }

        // Shuffle the strip array using Fisher-Yates algorithm
        for (let m = dist.length - 1; m > 0; m--) {
            const n = Math.floor(Math.random() * (m + 1));
            [dist[m], dist[n]] = [dist[n], dist[m]];
        }
        parSheetStrips.push({ dist });
    }

    parSheet.strips = parSheetStrips;
}
