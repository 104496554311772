<template>
  <div v-if="!getSymbol(symbolIndex).active">
    <div class="group-h">
      <label>Activated
        <input
          type="checkbox"
          v-model="getSymbol(symbolIndex).active"
        />
      </label>
    </div>
  </div>
  <div v-if="getSymbol(symbolIndex).active">
    <div class="group-h">
      <label class="right-border">Activated
        <input
          type="checkbox"
          v-model="getSymbol(symbolIndex).active"
        />
      </label>
      <label class="right-border">Wild
        <input
          type="checkbox"
          v-model="getSymbol(symbolIndex).isWild"
          @change="validateClamp()"
        />
      </label>
      <label v-if="!getSymbol(symbolIndex).isWild"  class="right-border">
        Allow Wilds
        <input 
          type="checkbox" 
          v-model="getSymbol(symbolIndex).allowWilds" />
      </label>
      <label v-if="!getSymbol(symbolIndex).isWild">Set Type:
        <select v-model="getSymbol(symbolIndex).typeofSymbol" style="width: 75px;" @change="validateClamp()">
          <option v-for="type in setTypes" :key="type.value" :value="type.value">{{ type.label }}</option>
        </select>
      </label>
    </div>
    <div class="clamping" v-if="!$parent.$parent.isParSheetEnabled()">
      <label for="clampPerReel">Clamping</label>
      <select v-model.number="getSymbol(symbolIndex).clampPerReel" @change="validateClamp()">
        <option v-for="(option, index) in clampOptions" :key="index" :value="index">
          {{ option }}
        </option>
      </select>
      <label for="clampTotal">max per reel</label>
      <label v-if="getSymbol(symbolIndex).clampPerReel > 0" for="clampTotal">but not more than</label>
      <select v-if="getSymbol(symbolIndex).clampPerReel > 0" v-model.number="getSymbol(symbolIndex).clampTotal" @change="validateClamp()">
        <option v-for="(option, index) in clampTotalOptions(getSymbol(symbolIndex).clampPerReel * getReelNo())" :key="index" :value="index">
          {{ option }}
        </option>
      </select>
    </div>
    <div class="frequency">
      <hr class="separator" />

      <label v-if="!$parent.$parent.isParSheetEnabled()">
        Enable Frequency Per Reel
        <input
          type="checkbox"
          v-model="getSymbol(symbolIndex).perReelFrequency"
        />
      </label>
      <label v-else style="font-weight: bold;">
        Frequencies
      </label>
      <div v-if="getSymbol(symbolIndex).perReelFrequency || $parent.$parent.isParSheetEnabled()">
        <div
          v-for="reel in getReelNo()"
          :key="reel"
          class="group-v"
        >
          <label :for="'reelFreq' + symbolIndex + '-' + reel" class="reel-frequency-label">
            Reel {{ reel }}
          </label>
          <input
            type="number"
            :id="'reelFreq' + symbolIndex + '-' + reel"
            v-model.number="getSymbolsInfo().reelFrequencies[symbolIndex].freq[reel - 1]"
            min="0"
            :max="getPrecision()"
          />
          <input
            type="range"
            v-model.number="getSymbolsInfo().reelFrequencies[symbolIndex].freq[reel - 1]"
            min="0"
            :max="getPrecision()"
          />
        </div>
      </div>

      <div v-else>
        <label class="reel-frequency-label">Frequency</label>
        <input
          type="number"
          id="frequency"
          v-model.number="getSymbolsInfo().symbolFrequencies[symbolIndex]"
          min="0"
          :max="getPrecision()"
          class="reel-frequency-label"
        />
        <input
          type="range"
          v-model.number="getSymbolsInfo().symbolFrequencies[symbolIndex]"
          min="0"
          :max="getPrecision()"
        />
      </div>
    </div>
    <hr class="separator" />
  </div>
</template>

<script>
  export default {
    name: 'SymbolsPageSymbolBaseSetup',
    props: {
      symbolIndex: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        clampOptions: ["off"],
        setTypes: [
          { value: 0, label: 'Normal' },
          { value: 1, label: 'Scatter' }
        ],
      };
    },
    methods: {
      getPrecision() {
        if (this.$parent.$parent.isParSheetEnabled())
        {
          return 20;
        }
        return this.getSymbolsInfo().precision;
      },
      getSymbolsInfo() {
        return this.$parent.$parent.symbolsInfo;
      },
      getSymbol(index) {
        const symbol = this.$parent.$parent.symbolsInfo.symbolInfo[index];
        return symbol;
      },
      validateClamp() {
        const symbol = this.getSymbol(this.symbolIndex);
        const maxPerReel = this.getSymbolsPerReel();
        const reelsNo = this.getReelNo();
                
        const pay = symbol.pays;
        let max = reelsNo;
        if (!this.$parent.$parent.isParSheetEnabled())
        {
          if (symbol.typeofSymbol == 1) {
              var smax = (symbol.clampPerReel == 0 ? maxPerReel : symbol.clampPerReel) * reelsNo;
              var maxtotal = symbol.clampPerReel == 0 ? 0 : symbol.clampTotal;
              if (maxtotal > 0 && maxtotal < smax) smax = maxtotal;
              if (smax > 0) max = smax;
          }
        }
        pay.pays = this.adjustSize(pay.pays, max, 0);
        pay.anticipate = this.adjustSize(pay.pays, max, 0);
      },
      adjustSize(array, targetSize, defaultValue = 0) {
        const currentSize = array.length;
        if (currentSize < targetSize) {
          array.push(...Array(targetSize - currentSize).fill(defaultValue));
        } else if (currentSize > targetSize) {
          array.length = targetSize;
        }
        return array;
      },
      getReelNo() {
        return this.$parent.$parent.reelsNo;
      },
      getSymbolsPerReel() {
        return this.$parent.$parent.symbsPerReel;
      },
      clampTotalOptions(max) {
        const options = ["off"];
        for (let i = 1; i <= max; i++) {
          options.push(i.toString());
        }
        return options;
      },
      updateClampOptions(perReel) {
        this.clampOptions = ['off'];
        for (let i = 1; i <= perReel; i++) {
          this.clampOptions.push(i.toString());
        }
      },
    },
    mounted() {
      this.updateClampOptions(this.getSymbolsPerReel());
    }
  };
</script>

<style scoped>
  .separator {
    border: none;
    border-top: 1px solid #000;
    margin: 10px 0;
  }

  .clamping {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    gap: 10px;
  }
  .clamping select {
    display: flex;
    width: 50px;
  }  

  .frequency {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .reel-frequency {
    display: flex;
    flex-direction: column;
  }
  .frequency label {
    width: 300px;
    margin-right: 10px;
  }
  .reel-frequency label {
    width: 100px;
    margin-right: 10px;
  }
  .reel-frequency-label {
    width: 60px;
    margin-right: 10px;
  }
  .frequency input[type='number'],
  .reel-frequency input[type='number'] {
    width: 100px;
  }
  .frequency input[type='range'],
  .reel-frequency input[type='range'] {
    width: 200px;
  }

  .group-h {
    display: flex;
    align-items: left;
    gap: 10px;
  }
  .group-h button {
    font-size: 13px;
  }
  .group-h input[type='number'] {
    width: 100px;
  }
  .group-h input[type='text'] {
    width: 250px;
  }
  .group-h select {
    width: 50px;
  }

  .right-border {
    border-right: 1px solid #000;
  }
</style>