<template>
  <div class="clickable-div" @click="isExpandedPanel = !isExpandedPanel">
    <label>{{ isExpandedPanel ? '▼' : '▶' }} Server Management {{ isExpandedPanel ? '' : '[CLOSED]' }}</label>
  </div >
  <div class="form-group" v-if="isExpandedPanel">
    <div class="group-h">
      <button @click="fetchConfigs">RESET SERVER CACHE</button>
    </div>
  </div>
</template>

<script>
  import { auth } from '@/js/auth.js';
  import { statusCodes } from '@/data/statusCodes.js';
  
  export default {
    name: 'GeneralPageServer',
    data() {
      return {
        isExpandedPanel: false
      };
    },
    methods: {
      fetchConfigs() {
        fetch(`${this.$root.envVars.api}/api/admin2/server/resetcache`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
            body: "{}"
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            this.$root.handleSuccess('Server cache reseted!');
          })
          .catch(error => {
            this.$root.handleError(error);
          });
      },
    },
    mounted() {
      
    }
  };
</script>
  
<style scoped>
  .form-group {
    align-items: center;
    margin-bottom: 15px;
    background-color: #d0d0d0;
    padding: 10px;
  }
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .form-group input[type='number'],
  .form-group select,
  .form-group input[type='text'] {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .form-group input[type='range'] {
    width: 100%;
  }
  .group-h {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    gap: 10px;
  }
  .group-h label {
    display: flex;
  }
  .group-h button {
    font-size: 13px;
    height: 18px;
  }
  .group-h input[type='number'] {
    width: 100px;
  }
  .group-h input[type='text'] {
    width: 250px;
  }
  .group-h select {
    width: 200px;
  }
</style>