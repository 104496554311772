<template>
  <div class="page-content">
    <h2>Math Settings</h2>

    <div v-if="isLoading">
      <p>Loading...</p>
    </div>
    <div v-else>
      <div class="form-group">
        <div class="group-h">
          <label>Iterations {{ estimation }}</label>
          <input type="number" v-model.number="iterations" :min="10" :max="1000000" />
          <input type="range" v-model.number="iterations" :min="10" :max="1000000" />
          <span>{{ iterations }}</span>
        </div>
        <hr class="separator" />
        <div class="group-h">
          <label>RandSeed</label>
          <input type="number" v-model.number="randSeed" :min="10" :max="1000000" />
          <input type="range" v-model.number="randSeed" :min="10" :max="1000000" />
          <span>{{ randSeed }}</span>
        </div>
        <hr class="separator" />
        <div class="group-h">
          <label>Bet Per Line</label>
          <select v-model="betPerLine">
            <option v-for="(option, index) in betOptions" :key="index" :value="index">
              {{ option }}
            </option>
          </select>
        </div>
        <hr class="separator" />
        <div class="group-h">
          <label>Lines Played</label>
          <input type="range" v-model.number="linesPlayed" :min="1" :max="maxLinesPlayed" />
          <span>{{ linesPlayed }}</span>
        </div>
        <div class="group-h">
          <button @click="fetchCompute(false)">Compute</button>
          <button @click="fetchCompute(true)">Compute with results</button>
          <button @click="fetchDefaultValues">Refresh from Server</button>
        </div>
      </div>
      
      <div v-if="resultCounts.length > 0" class="form-group">
        <div class="return-percentage">
          <h3>Return Percentage</h3>
          <p
            @mouseover="$root.showTooltipInfo('RTP bet: ' + 
                    (returnPercent * 100).toFixed(4) + 
                    '%\nRPT free spins: ' + 
                    (returnFSPercent * 100).toFixed(4) + 
                    '%\nRTP total: ' +
                    ((returnPercent + returnFSPercent) * 100).toFixed(4) + '%')"
            @mouseleave="$root.hideTooltipInfo()">
            This slot sits at {{ (returnPercent * 100).toFixed(4) }}% (total: {{ ((returnPercent + returnFSPercent) * 100).toFixed(4) }}%) based on {{ iterations }} spins.
          </p>
          <p v-if="returnPercent <= 1">
            This means on average, the house will take approximately {{ 100 - Math.round(returnPercent * 100) }} credits for every 100 credits bet.
          </p>
          <p v-else>
            This means on average, the house will pay out approximately {{ Math.abs(Math.round(returnPercent * 100)) }} credits for every 100 credits bet.
          </p>
        </div>

        <!-- Show symbol hit probability per reel -->
        <div class="group-h">
          <div class="return-table">
            <h3>Symbol hit success probability per reel based on simulation</h3>
            <div class="table-header">
              <span style="text-align: center;">Symbol</span>
              <div v-for="reel in reelsNo" :key="reel" class="table-row">
                <span style="text-align: center;">Reel {{ reel }}</span>
              </div>  
            </div>
            
            <div v-for="(symbol, index) in symbols" :key="index" class="table-row">
              <template v-if="symbol.typeofSymbol !== 1">
                <span class="table-header">{{ symbol.name }}</span>
                <div v-for="reel in reelsNo" :key="reel" class="table-row">
                  <span style="text-align: center" 
                    @mouseover="$root.showTooltipInfo('Total Hits: ' + resultCounts.filter(item => item.symbolIndex === index && item.matches >= reel).reduce((acc, curr) => acc + curr.occurenceCount, 0) + ' out of ' + iterations)"
                    @mouseleave="$root.hideTooltipInfo()">
                    {{ ((resultCounts.filter(item => item.symbolIndex === index && item.matches >= reel).reduce((acc, curr) => acc + curr.occurenceCount, 0) / iterations) * 100).toFixed(2) }}%
                  </span>
                </div>
              </template>
            </div>
          </div>


          <div class="return-table" v-if="parSheet">
            <h3>Symbol hit probability per reel based on theory</h3>
            <div class="table-header">
              <div v-for="reel in reelsNo" :key="reel" class="table-row">
                <span style="text-align: center;"
                  @mouseover="$root.showTooltipInfo(parSheet.strips[reel - 1].distIndexes.length + ' total occurrences')"
                  @mouseleave="$root.hideTooltipInfo()"> 
                  Reel {{ reel }} [ {{ parSheet.strips[reel - 1].distIndexes.length }} ]
                </span>
              </div>  
            </div>
            
            <div v-for="(symbol, index) in symbols" :key="index" class="table-row">
              <template v-if="symbol.typeofSymbol !== 1">
                <div v-for="reel in reelsNo" :key="reel" class="table-row">
                  <span style="text-align: center" 
                    @mouseover="$root.showTooltipInfo(parSheet.strips[reel - 1].distIndexes.filter(distIndex => distIndex === index).length + ' occurrences')"
                    @mouseleave="$root.hideTooltipInfo()">
                    {{ ((parSheet.strips[reel - 1].distIndexes.filter(distIndex => distIndex === index).length / parSheet.strips[reel - 1].distIndexes.length) * 100).toFixed(2) }}%
                  </span>
                </div>
              </template>
            </div>
          </div>
        </div>

        <!-- {{ resultCounts }} -->
        <div class="return-table">
          <h3>Return Table based on {{ iterations }} spins.</h3>
          <div class="table-header">
            <span>Set</span>
            <span>Hits</span>
            <span>Paid</span>
            <span>% Total</span>
            <span>Prob%</span>
            <span>Return%</span>
          </div>
          <div v-for="(item, index) in resultCounts.filter(item => item.freeSpin === false)" :key="index" class="table-row">
            <span>{{ item.matches }} {{ item.name }}</span>
            <span>{{ item.occurenceCount }}</span>
            <span>{{ item.winTotal }}</span>
            <span>{{ (item.winTotal / returnTotalWon).toFixed(4) }}</span>
            <span>{{ (item.occurenceCount / iterations).toFixed(4) }}</span>
            <span>{{ (item.winTotal / returnTotalBet).toFixed(4) }}</span>
          </div>
          <div class="totals">
            <span>RTP: {{ (returnPercent * 100).toFixed(4) }}%</span>
            <span>Total bet: {{ returnTotalBet }}</span>
            <span>Total Won: {{ returnTotalWon }}</span>
          </div>
        </div>

        <div class="return-table">
          <h3>Free spins results.</h3>
          <div class="table-header">
            <span>Set</span>
            <span>Hits</span>
            <span>Paid</span>
            <span>% Total</span>
            <span>Prob%</span>
            <span>Return%</span>
          </div>
          <div v-for="(item, index) in resultCounts.filter(item => item.freeSpin === true)" :key="index" class="table-row">
            <span>{{ item.matches }} {{ item.name }}</span>
            <span>{{ item.occurenceCount }}</span>
            <span>{{ item.winTotal }}</span>
            <span>{{ (item.winTotal / returnFSTotalCreditWon).toFixed(4) }}</span>
            <span>{{ (item.occurenceCount / returnFSItterations).toFixed(4) }}</span>
            <span>{{ (item.winTotal / returnFSTotalCreditWon).toFixed(4) }}</span>
          </div>
          <div class="totals">
            <span>Free spins percent: {{ (returnFSPercent * 100).toFixed(4) }}%</span>
            <span>Total spins: {{ returnFSItterations }}</span>
            <span>Total Won: {{ returnFSTotalCreditWon }}</span>
          </div>
        </div>

        <div class="volatility-breakdown">
          <h3>Theoretical Volatility Breakdown based on {{ iterations }} spins.</h3>
          <p>The volatility index of this slot sits at {{ volatility }} with a standard deviation of {{ standardDeviation }}.</p>
          <p>Below is a list of lower and upper limit return percentages for a given number of spins:</p>
          <div>
            <div class="table-header">
              <span>Spins</span>
              <span>Lower</span>
              <span>Upper</span>
            </div>
            <div v-for="(range, index) in volatilityRanges" :key="index" class="table-row">
              <span>{{ range.spins }}</span>
              <span>{{ range.lower.toFixed(4) }}%</span>
              <span>{{ range.upper.toFixed(4) }}%</span>
            </div>
          </div>
        </div>


        <input type="checkbox" v-model="displayWinsOnly" /> View wins only
        <div class="results-table">
          <div v-for="(reels, spinIndex) in results" :key="spinIndex">
            <div class="table-header" v-if="(displayWinsOnly && resultsPairs[spinIndex].length > 0) || !displayWinsOnly">
              <span v-for="(reel, index) in reels" :key="index" style="margin-bottom: 10px;">
                Reel {{ index + 1 }}
                <div v-for="(symbol, sindex) in reel" :key="sindex" class="table-row">
                  <span 
                    :class="{ 'color-green': getSymbolHit(spinIndex, index, sindex, symbol) }" >{{ getSymbolName(symbol) }}</span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/js/auth.js';

export default {
  name: 'MathCompute',
  data() {
    return {
      isLoading: true,

      iterations: 10,
      estimatedTime: 0,

      betPerLine: null,
      betOptions: [],

      linesPlayed: 1,
      maxLinesPlayed: 1,
      
      timeToComputeIterations: 0,
      payLinesInfo: {
        lines: []
      },
      resultCounts: [],

      returnPercent: 0,
      returnTotalWon: 0,
      returnTotalBet: 0,

      returnFSPercent: 0,
      returnFSTotalCreditWon: 0,
      returnFSItterations: 0,

      volatility: 0,
      standardDeviation: 0,
      volatilityRanges: [],

      results: [],
      resultsPairs: [],
      symbols: [],
      paylines: [],

      parSheet: null,
      reelsNo: 5,

      displayWinsOnly: true,
      randSeed: 1000,

      defaultValues: {},
      showComparison: true
    };
  },
  computed: {
    estimation() {
      if (this.timeToComputeIterations > 0) {
        return `(est. ${(this.returnPercentItterations / this.timeToComputeIterations * this.estimatedTimeToCompute).toFixed(1)}s)`;
      }
      return '';
    },
  },
  methods: {
    getSymbolHit(spinIndex, reel, sindex, symbol) {
      // console.log(`spinIndex = ${spinIndex}; reel = ${reel}; sindex = ${sindex}`);
      const r = this.resultsPairs[spinIndex];
      for (let i = 0; i < r.length; i++) {
        if (r[i].occurenceCount == -1) {
          if (this.isSymbolScatter(symbol))
          {
            return true;
          }
          return false;
        }
        if (r[i].matches <= reel)
        {
          return false;
        }
        if (this.paylines[r[i].occurenceCount].positions[reel] === sindex) {
          return true;
        }
      }
      return false;
    },
    isSymbolScatter(index) {
      return this.symbols[index].typeofSymbol == 1;
    },
    getSymbolName(index) {
      return this.symbols[index].name;
    },
    fetchDefaultValues() {
      this.isLoading = true;
      fetch(`${this.$root.envVars.api}/api/admin2/math/read?configId=${this.$root.getCurrentConfigId()}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
          })
        .then(response => response.json())
        .then(data => {
          const opts = data.betsPerLine.map(o => `${o.value}`);
          this.betOptions = opts;
          this.betPerLine = 0;
          this.maxLinesPlayed = data.paylinesNo;
          
          this.isLoading = false;
          this.$root.handleSuccess('Data loaded!');
        })
        .catch(error => {
          this.$root.handleError('Error fetching data from server - ' + error);
          this.isLoading = false;
        });
    },
    getHandsVolatility(i) {
      let hands = Math.pow(10, i);
      let low = (this.returnPercent - this.volatility / (Math.sqrt(hands))) * 100;
      if (low < 0) low = 0;
      let high = (this.returnPercent + this.volatility / (Math.sqrt(hands))) * 100;
      hands = hands * 10;
      return { spins: hands, lower: low, upper: high };
    },
    fetchCompute(getResults) {
      this.isLoading = true;
      fetch(`${this.$root.envVars.api}/api/admin2/math/compute?configId=${this.$root.getCurrentConfigId()}&seed=${this.randSeed}&iterations=${this.iterations}&betPerLine=${this.betPerLine}&linesPlayed=${this.linesPlayed}&getResults=${getResults}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
          })
        .then(response => response.json())
        .then(data => {
          if (data.StatusCode){
            this.$root.handleError(`${data.StatusCode} - ${data.Message}`);
            this.isLoading = false;
            return;
          }

          this.resultCounts = data.resultCounts;
          this.returnPercent = data.returnPercent;
          this.returnTotalWon = data.returnTotalWon;
          this.returnTotalBet = data.returnTotalBet;
          
          this.returnFSPercent = data.returnFSPercent;
          this.returnFSTotalCreditWon = data.returnFSTotalCreditWon;
          this.returnFSItterations = data.returnFSItterations;

          this.volatility = data.volatility;
          this.standardDeviation = data.standardDeviation;
          if (data.results) {
            this.results = data.results;
            this.resultsPairs = data.resultsPairs;
            this.paylines = data.paylines;
          } else {
            this.results = [];
            this.resultsPairs = [];
            this.paylines = [];
          }

          this.symbols = data.symbols.symbolInfo;
          this.reelsNo = data.reelsNo;
          this.parSheet = data.parSheet;

          this.volatilityRanges = [];
          [0, 1, 2, 3, 4, 5, 6].forEach(x => this.volatilityRanges.push(this.getHandsVolatility(x)));

          this.isLoading = false;
          this.$root.handleSuccess('Computation completed.');
        })
        .catch(error => {
          this.$root.handleError('Error fetching data from server - ' + error);
          this.isLoading = false;
        });
      }
    },
  mounted() {
    this.fetchDefaultValues();
  }
};
</script>

<style scoped>
  .page-content {
    padding: 20px;
  }
  .form-group {
    align-items: center;
    margin-bottom: 15px;
    background-color: #d0d0d0;
    padding: 10px;
  }
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .form-group input[type='number'],
  .form-group select,
  .form-group input[type='text'] {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .form-group input[type='range'] {
    width: 100%;
  }
  .color-green {
    background-color: green;
  }
  .group-h {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    gap: 10px;
  }
  .group-h label {
    display: flex;
  }
  .group-h button {
    font-size: 13px;
    height: 18px;
  }
  .group-h input[type='number'] {
    width: 100px;
  }
  .group-h input[type='text'] {
    width: 250px;
  }
  .group-h select {
    width: 50px;
  }

  .group-v {
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  .group-v label {
    display: flex;
  }
  .group-v input[type='number'] {
    width: 100px;
  }
  .group-v input[type='text'] {
    width: 250px;
  }
  .group-v select {
    width: 50px;
  }
  .long-select {
    width: 300px;
  }




.controls button {
  width: 50px;
}
.result-section {
  margin-top: 20px;
}
.return-percentage, .return-table, .volatility-breakdown, .results-table {
  margin-bottom: 20px;
}

.results-table .table-header,
.return-table .table-header, 
.volatility-breakdown .table-header {
  display: flex;
  font-weight: bold;
}

.results-table .table-header span {
  width: 100px;
  border: 2px solid #000;
}

.return-table .table-header span, 
.volatility-breakdown .table-header span {
  width: 100px;
  border: 2px solid #000;
}

.results-table .table-row,
.return-table .table-row, 
.volatility-breakdown .table-row {
  display: flex;
}

.return-table .table-row span, 
.volatility-breakdown .table-row span {
  width: 100px;
  border: 2px solid #000;
}

.results-table .table-row span
{
  width: 100px;
  border: none;
  margin: 1px;
}

.totals {
  margin-top: 10px;
  font-weight: bold;
}
.totals span {
  margin-right: 10px;
}
.separator {
  border: none;
  border-top: 1px solid #000;
  margin: 10px 0;
}
.current-values-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.current-values {
  flex: 1;
  background-color: #f9f9f9;
  padding: 10px;
  margin-right: 20px;
}
.current-values p {
  margin: 5px 0;
}
.buttons {
  display: flex;
  flex-direction: column;
}
.buttons button {
  margin-bottom: 10px;
  padding: 10px 20px;
}
.changed {
  color: red;
}
.same {
  color: green;
}
</style>