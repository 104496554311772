<template>
    <div :class="['side-menu', { collapsed: isCollapsed }]">
      <div class="toggle-button" @click="toggleMenu">
        ☰
      </div>
      <ul>
        <li v-for="(page, index) in filteredPages" 
              :key="index"
              :title="isCollapsed ? page.title : ''"
              :class="{
                'selected-item': $root.currentPage == page.name,
                'parent-item': page.children
              }">
          <span class="menu-icon" @click="handlePageClick(page)">{{ page.icon }}</span>
          <span v-if="!isCollapsed" class="menu-label" @click="handlePageClick(page)">
            {{ page.label }}
            <span v-if="page.children" class="expand-icon">{{ expandedPages.includes(page.name) ? '▼' : '▶' }}</span>
          </span>
          <template v-if="page.children && expandedPages.includes(page.name)">
            <li v-for="child in page.children"
              :key="child.name"
              :title="isCollapsed ? child.title : ''"
              :class="{
                'selected-item': $root.currentPage == child.name,
                'child-item': true
              }"
              v-show="child.show()">
              <span class="menu-icon">{{ child.icon }}</span>
              <span v-if="!isCollapsed" @click="() => { selectPage(child.name) }">{{ child.label }}</span>
            </li>
          </template>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import { auth } from '@/js/auth.js';

  export default {
    name: 'SideMenu',
    data() {
      return {
        isCollapsed: false,
        expandedPages: [],
        pages: [
          { name: 'UserPage', title: 'User Page', icon: '👤', label: 'User Page', authRequired: false, configRequired: false, show: () => true },
          { name: 'GeneralPage', title: 'General Settings', icon: '🔧', label: 'General Settings', authRequired: true, configRequired: false, show: () => true },
          { name: 'CDNPage', title: 'CDN Settings', icon: '🌐', label: 'CDN Settings', authRequired: true, configRequired: true, show: () => true },
          { 
            name: 'TweaksInfo', title: 'Tweaks Info', icon: '⚡', label: 'Tweaks Info', authRequired: true, configRequired: true,
            children: [
              { name: 'ReelSettings', title: 'Reel Settings', icon: '🎲', label: 'Reel Settings', authRequired: true, configRequired: true, show: () => true },
              { name: 'BetSettings', title: 'Bet Settings', icon: '💰', label: 'Bet Settings', authRequired: true, configRequired: true, show: () => true },
              { name: 'TimingSettings', title: 'Timing Settings', icon: '⏱️', label: 'Timing Settings', authRequired: true, configRequired: true, show: () => true },
            ]
          }, { 
            name: 'SymbolsInfo', title: 'Symbols Info', icon: '🎯', label: 'Symbols Info', authRequired: true, configRequired: true,
            children: [
              { name: 'SymbolsPage', title: 'Symbols', icon: '🎨', label: 'Symbols', authRequired: true, configRequired: true, show: () => true },
              { name: 'SymbolsParSheetPage', title: 'PAR Sheet', icon: '✨', label: 'PAR Sheet', authRequired: true, configRequired: true, show: () => this.$root.isParSheetEnabled() },
              { name: 'PayLinePage', title: 'Pay Lines', icon: '📈', label: 'Pay Lines', authRequired: true, configRequired: true, show: () => true },
              { name: 'PayTablePage', title: 'Pay Tabel', icon: '📊', label: 'Pay Tabel', authRequired: true, configRequired: true, show: () => true },
              { name: 'PayTableScattersPage', title: 'Pay Scatters', icon: '✨', label: 'Pay Scatters', authRequired: true, configRequired: true, show: () => true },
            ]
          }, { 
            name: 'LayoutsInfo', title: 'Layouts', icon: '📑', label: 'Layouts', authRequired: true, configRequired: true,
            children: [
              { name: 'LayoutBaseSettings', title: 'Layout Base', icon: '📋', label: 'Layout Base', authRequired: true, configRequired: true, show: () => true },
              { name: 'LayoutDynImageSettings', title: 'Layout Dyn Image', icon: '🖼️', label: 'Layout Dyn Image', authRequired: true, configRequired: true, show: () => true },
              { name: 'LayoutDynAnimSettings', title: 'Layout Dyn Anim', icon: '🎬', label: 'Layout Dyn Anim', authRequired: true, configRequired: true, show: () => true },
            ]
          },
          { name: 'MathCompute', title: 'Math Compute', icon: '🧮', label: 'Math Compute', authRequired: true, configRequired: true, show: () => true },
        ],
      };
    },
    computed: {
      filteredPages() {
        return this.pages.filter(page => {
          return !page.authRequired || (this.isAuthenticated() && (!page.configRequired || this.$root.getCurrentConfigId() !== null));
        });
      },
    },
    methods: {
      isAuthenticated() {
        return auth.checkAuth();
      },
      toggleMenu() {
        console.log("$root.currentPage: " + this.$root.currentPage);
        this.isCollapsed = !this.isCollapsed;
      },
      handlePageClick(page) {
        if (page.children) {
          const index = this.expandedPages.indexOf(page.name);
          if (index === -1) {
            this.expandedPages.push(page.name);
          } else {
            this.expandedPages.splice(index, 1);
          }
        } else {
          this.selectPage(page.name);
        }
      },
      selectPage(page) {
        this.$emit('select-page', page);
        this.expandParentOfCurrentPage(page);
      },
      expandParentOfCurrentPage(page) {
        const currentPage = page ?? this.$root.currentPage;
        this.pages.forEach(parentPage => {
          if (parentPage.children && parentPage.children.some(child => child.name === currentPage)) {
            if (!this.expandedPages.includes(parentPage.name)) {
              this.expandedPages.push(parentPage.name);
            }
          }
        });
      }
    },
    mounted() {
      this.expandParentOfCurrentPage();
    },
  };
  </script>
  
  <style scoped>
  .side-menu {
    background-color: #f4f4f4;
    width: 200px;
    transition: width 0.3s;
    overflow: hidden;
    position: relative;
  }
  
  .side-menu.collapsed {
    width: 50px;
  }
  
  .side-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 20px;
  }
  
  .side-menu li {
    padding: 15px;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .side-menu li:hover {
    background-color: #ddd;
  }
  
  .side-menu ul .selected-item {
    background-color: #9e9e9e;
  }

  .toggle-button {
    position: relative;
    top: 5px;
    right: -5px;
    color: rgb(0, 0, 0);
    width: 200px;
    height: 15px;
    text-align: left;
    line-height: 15px;
    cursor: pointer;
    z-index: 999;
  }
  
  .menu-icon {
    display: inline-block;
    width: 20px;
    text-align: center;
    margin-right: 10px;
  }

  .child-item {
    line-height: 15px;
    height: 15px;
    padding-left: 5px !important;
    background-color: #e8e8e8;
  }

  .parent-item {
    font-weight: bold;
  }

  .expand-icon {
    float: right;
    font-size: 0.8em;
  }

  .menu-label {
    width: 150px;
    display: inline-block;
  }
  </style>