<template>
    <div class="page-content">
      <h2>Layout Dyn Anim Settings</h2>

      <div v-if="isLoading">
        <p>Loading...</p>
      </div>
      <form v-else @submit.prevent>

        <div class="form-group">
          <label for="animLayerFilter">Anims Layer Filter</label>
          <select id="animLayerFilter" v-model="layoutLayerFilterAnims">
            <option v-for="(label, value) in layoutLayerOptions" :key="value" :value="value">
            {{ label }}
            </option>
          </select>
        </div>
        <div class="form-group">
            <div v-for="(anim, index) in filteredDynAnims" :key="index" class="dynamic-item">
            <div class="header">
              <label>[{{ index }}]</label>
              <button @click="removeDynAnim(index)">Remove anim</button>
            </div>
            <div class="group-h">
              <label for="animUrl">URL</label>
              <!-- <input type="text" id="animUrl" v-model="anim.url" /> -->
              <select v-model="anim.url">
                <option v-for="(option) in $root.cdn.anims.raw" :key="option" :value="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div class="group-h">
              <label for="animExt">EXTENSION</label>
              <input type="text" id="animExt" v-model="anim.ext" />
            </div>
            <div class="group-h">
              <label for="animName">ANIM</label>
              <input type="text" id="animName" v-model="anim.anim" />
            </div>
            <div class="group-h">
              <label for="animPosX">Position X</label>
              <input type="number" id="animPosX" v-model.number="anim.x" />
            </div>
            <div class="group-h">
              <label for="animPosY">Position Y</label>
              <input type="number" id="animPosY" v-model.number="anim.y" />
            </div>
            <div class="group-h">
              <label for="animScaleX">Scale X</label>
              <input type="number" step="0.01" id="animScaleX" v-model.number="anim.sx" />
            </div>
            <div class="group-h">
              <label for="animScaleY">Scale Y</label>
              <input type="number" step="0.01" id="animScaleY" v-model.number="anim.sy" />
            </div>
            <div class="group-h">
              <label for="animLayer">Layer</label>
              <select id="animLayer" v-model.number="anim.layer">
                <option v-for="(label, value) in layoutLayerOptions" :key="value" :value="value">
                    {{ label }}
                </option>
              </select>
            </div>
            <div class="group-h">
              <label for="animOrder">Draw Order In Layer</label>
              <input type="number" id="animOrder" v-model.number="anim.order" />
            </div>
            <hr class="separator" />
          </div>
          <button @click="addDynAnim">Add dynamic anim</button>
        </div>
    
        <div class="current-values-container">
          <div class="current-values">
            <h3>Current values:</h3>
              <div v-for="(anim, index) in layoutInfo.dynAnims" :key="index">
                <p>Dynamic Anim {{ index + 1 }}</p>
                <p>
                URL:
                <span :class="{'changed': isChangedDynamicAnim(index, 'url'), 'same': !isChangedDynamicAnim(index, 'url') && showComparison}">
                    {{ anim.url }}
                </span>
                <span v-if="showComparison">/ {{ defaultValues.dynAnims[index] ? defaultValues.dynAnims[index].url : "N/A" }}</span>
                </p>
                <p>
                Extension:
                <span :class="{'changed': isChangedDynamicAnim(index, 'ext'), 'same': !isChangedDynamicAnim(index, 'ext') && showComparison}">
                    {{ anim.ext }}
                </span>
                <span v-if="showComparison">/ {{ defaultValues.dynAnims[index] ? defaultValues.dynAnims[index].ext : "N/A" }}</span>
                </p>
                <p>
                ANIM:
                <span :class="{'changed': isChangedDynamicAnim(index, 'anim'), 'same': !isChangedDynamicAnim(index, 'anim') && showComparison}">
                    {{ anim.anim }}
                </span>
                <span v-if="showComparison">/ {{ defaultValues.dynAnims[index] ? defaultValues.dynAnims[index].anim : "N/A" }}</span>
                </p>
                <p>
                Position X:
                <span :class="{'changed': isChangedDynamicAnim(index, 'x'), 'same': !isChangedDynamicAnim(index, 'x') && showComparison}">
                    {{ anim.x }}
                </span>
                <span v-if="showComparison">/ {{ defaultValues.dynAnims[index] ? defaultValues.dynAnims[index].x : "N/A" }}</span>
                </p>
                <p>
                Position Y:
                <span :class="{'changed': isChangedDynamicAnim(index, 'y'), 'same': !isChangedDynamicAnim(index, 'y') && showComparison}">
                    {{ anim.y }}
                </span>
                <span v-if="showComparison">/ {{ defaultValues.dynAnims[index] ? defaultValues.dynAnims[index].y : "N/A" }}</span>
                </p>
                <p>
                Scale X:
                <span :class="{'changed': isChangedDynamicAnim(index, 'sx'), 'same': !isChangedDynamicAnim(index, 'sx') && showComparison}">
                    {{ anim.sx }}
                </span>
                <span v-if="showComparison">/ {{ defaultValues.dynAnims[index] ? defaultValues.dynAnims[index].sx : "N/A" }}</span>
                </p>
                <p>
                Scale Y:
                <span :class="{'changed': isChangedDynamicAnim(index, 'sy'), 'same': !isChangedDynamicAnim(index, 'sy') && showComparison}">
                    {{ anim.sy }}
                </span>
                <span v-if="showComparison">/ {{ defaultValues.dynAnims[index] ? defaultValues.dynAnims[index].sy : "N/A" }}</span>
                </p>
                <p>
                Layer:
                <span :class="{'changed': isChangedDynamicAnim(index, 'layer'), 'same': !isChangedDynamicAnim(index, 'layer') && showComparison}">
                    {{ layoutLayerOptions[anim.layer] }}
                </span>
                <span v-if="showComparison">/ {{ defaultValues.dynAnims[index] ? layoutLayerOptions[defaultValues.dynAnims[index].layer] : "N/A" }}</span>
                </p>
                <p>
                Draw Order In Layer:
                <span :class="{'changed': isChangedDynamicAnim(index, 'order'), 'same': !isChangedDynamicAnim(index, 'order') && showComparison}">
                    {{ anim.order }}
                </span>
                <span v-if="showComparison">/ {{ defaultValues.dynAnims[index] ? defaultValues.dynAnims[index].ordder : "N/A" }}</span>
                </p>
                <hr class="separator" />
              </div>
            </div>
            <div class="buttons">
              <button @click="fetchDefaultValues">Refresh from Server</button>
              <button @click="saveSettings">Save settings</button>
            </div>
        </div>
        </form>
    </div>
  </template>
  
<script>
  import { auth } from '@/js/auth.js';
  import { statusCodes } from '@/data/statusCodes.js';

  export default {
    name: 'LayoutDynAnimSettings',
    data() {
      return {
        isLoading: true,
        layoutInfo: {
          symbol: {
            image: {
              x: 200,
              y: 136,
              w: 200,
              h: 136,
              preserveAspect: false,
              nativeSize: false
            }
          },
          background: {
            image: {
              x: 200,
              y: 136,
              w: 200,
              h: 136,
              preserveAspect: false,
              nativeSize: false
            }
          },
          backMask: {
            image: {
              x: 200,
              y: 136,
              w: 200,
              h: 136,
              preserveAspect: false,
              nativeSize: false
            }
          },
          dynImages: [],
          dynAnims: []
        },
        defaultValues: {},
        showComparison: true,
        layoutLayerFilterImages: 0,
        layoutLayerFilterAnims: 0,
        layoutLayerOptions: {
          0: 'All',
          1: 'Back',
          2: 'Mid',
          3: 'Front'
        }
      };
    },
    computed: {
      filteredDynImages() {
        if (this.layoutLayerFilterImages === 0) {
          return this.layoutInfo.dynImages;
        }
        return this.layoutInfo.dynImages.filter(image => image.layer === this.layoutLayerFilterImages);
      },
      filteredDynAnims() {
        if (this.layoutLayerFilterAnims === 0) {
          return this.layoutInfo.dynAnims;
        }
        return this.layoutInfo.dynAnims.filter(anim => anim.layer === this.layoutLayerFilterAnims);
      }
    },
    methods: {
      addDynImage() {
        this.layoutInfo.dynImages.push({
          image: {
            x: 200,
            y: 136,
            w: 200,
            h: 136,
            preserveAspect: false,
            nativeSize: false
          },
          url: '',
          layer: 0,
          order: 0
        });
      },
      removeDynImage(index) {
        this.layoutInfo.dynImages.splice(index, 1);
      },
      addDynAnim() {
        this.layoutInfo.dynAnims.push({
          url: '',
          ext: 'webp',
          layer: 0,
          x: 200,
          y: 136,
          sx: 1.0,
          sy: 1.0,
          anim: 'idle',
          order: 0
        });
      },
      removeDynAnim(index) {
        this.layoutInfo.dynAnims.splice(index, 1);
      },
      fetchDefaultValues() {
        if (this.$root.getCurrentConfigId() === null) {
          this.$root.handleError('Empty getCurrentConfigId() variable!');
        } else {
          fetch(`${this.$root.envVars.api}/api/admin2/layout/read?configId=${this.$root.getCurrentConfigId()}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.autToken()}`
              },
            })
            .then(response => response.json())
            .then(data => {
              this.layoutInfo.symbol.image.h = data.symbol.image.h;
              this.layoutInfo.symbol.image.w = data.symbol.image.w;
              this.layoutInfo.symbol.image.x = data.symbol.image.x;
              this.layoutInfo.symbol.image.y = data.symbol.image.y;
              this.layoutInfo.symbol.image.nativeSize = data.symbol.image.nativeSize;
              this.layoutInfo.symbol.image.preserveAspect = data.symbol.image.preserveAspect;

              this.layoutInfo.background.image.h = data.background.image.h;
              this.layoutInfo.background.image.w = data.background.image.w;
              this.layoutInfo.background.image.x = data.background.image.x;
              this.layoutInfo.background.image.y = data.background.image.y;
              this.layoutInfo.background.image.nativeSize = data.background.image.nativeSize;
              this.layoutInfo.background.image.preserveAspect = data.background.image.preserveAspect;

              this.layoutInfo.backMask.image.h = data.backMask.image.h;
              this.layoutInfo.backMask.image.w = data.backMask.image.w;
              this.layoutInfo.backMask.image.x = data.backMask.image.x;
              this.layoutInfo.backMask.image.y = data.backMask.image.y;
              this.layoutInfo.backMask.image.nativeSize = data.backMask.image.nativeSize;
              this.layoutInfo.backMask.image.preserveAspect = data.backMask.image.preserveAspect;

              this.layoutInfo.dynImages = data.dynImages;
              this.layoutInfo.dynAnims = data.dynAnims;

              this.showComparison = true;
                this.isLoading = false;
                this.defaultValues = JSON.parse(JSON.stringify(this.layoutInfo));
                this.$root.handleSuccess('Data loaded!');
            })
            .catch(error => {
              this.$root.handleError('Error fetching data from server - ' + error);
            });
        }
      },
      saveSettings() {
        fetch(`${this.$root.envVars.api}/api/admin2/layout/write?configId=${this.$root.getCurrentConfigId()}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.autToken()}`
          },
          body: JSON.stringify(this.layoutInfo)
        })
          .then(response => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
            this.$root.handleSuccess('Data sent to server!');
            this.defaultValues = JSON.parse(JSON.stringify(this.layoutInfo));
          })
          .catch(error => {
            this.$root.handleError(error);
          });
      },
      isChanged(field) {
        const fields = field.split('.');
        let defaultVal = this.defaultValues;
        let currentVal = this.layoutInfo;
        fields.forEach(f => {
          defaultVal = defaultVal[f];
          currentVal = currentVal[f];
        });
        return currentVal !== defaultVal;
      },
      isChangedDynamicImage(index, field) {
        const fields = field.split('.');
        if (index >= this.defaultValues.dynImages.length) {
          return true;
        }
        if (index >= this.layoutInfo.dynImages.length) {
          return true;
        }
        let defaultVal = this.defaultValues.dynImages[index];
        let currentVal = this.layoutInfo.dynImages[index];
        fields.forEach(f => {
          defaultVal = defaultVal[f];
          currentVal = currentVal[f];
        });
        return currentVal !== defaultVal;
      },
      isChangedDynamicAnim(index, field) {
        const fields = field.split('.');
        if (index >= this.defaultValues.dynAnims.length) {
          return true;
        }
        if (index >= this.layoutInfo.dynAnims.length) {
          return true;
        }
        let defaultVal = this.defaultValues.dynAnims[index];
        let currentVal = this.layoutInfo.dynAnims[index];
        fields.forEach(f => {
          defaultVal = defaultVal[f];
          currentVal = currentVal[f];
        });
        return currentVal !== defaultVal;
      }
    },
    mounted() {
      this.fetchDefaultValues();
    }
  };
  </script>
  
  <style scoped>
  .page-content {
    padding: 20px;
  }
  .form-group {
    align-items: center;
    margin-bottom: 15px;
    background-color: #d0d0d0;
    padding: 10px;
  }
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .form-group input[type='number'],
  .form-group select,
  .form-group input[type='text'] {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .form-group input[type='range'] {
    width: 100%;
  }

  .group-h {
    display: flex;
    align-items: left;
    gap: 10px;
  }
  .group-h label {
    display: flex;
  }
  .group-h input[type='number'] {
    width: 100px;
  }
  .group-h input[type='text'] {
    width: 250px;
  }
  .group-h select {
    width: 100px;
  }

  .separator {
    border: none;
    border-top: 1px solid #000000;
    margin: 10px 0;
  }
  .dynamic-item {
    margin-bottom: 15px;
  }
  .header {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 10px;
  }
  .header button {
    padding: 5px 10px;
    margin-left: 10px;
  }
  button {
    padding: 8px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  button:hover {
    background-color: #0056b3;
  }
  .current-values-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .current-values {
    flex: 1;
    background-color: #f9f9f9;
    padding: 10px;
    margin-right: 20px;
  }
  .current-values p {
    margin: 5px 0;
  }
  .buttons {
    display: flex;
    flex-direction: column;
  }
  .buttons button {
    margin-bottom: 10px;
    padding: 10px 20px;
  }
  .changed {
    color: red;
  }
  .same {
    color: green;
  }
  </style>