<template>
<div class="group-h" style="gap: 0px;">
    <div class="payline-grid">
      <div v-for="row in gridHeight" :key="row" class="grid-row">
        <span class="grid-cell">{{ row + minPosition - 1 }}</span>  
      </div>
    </div>
    <div class="payline-grid">
      <div v-for="row in gridHeight" :key="row" class="grid-row">
        <div v-for="(pos, colIndex) in line.positions" :key="colIndex" 
            class="grid-cell"
            :class="{ 'active': (pos - minPosition + 1) === row }"
            @click="updateCellValue(colIndex, row + minPosition -1)">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PayLineGridComponent',
  props: ['index', 'line', 'minPosition', 'gridHeight'],
  methods: {
    updateCellValue(colIndex, value) {
        this.$emit('cell-updated', {
            lineIndex: this.index,
            colIndex: colIndex,
            newValue: value
        });
    }
  }
}
</script>

<style scoped>
.payline-grid {
  display: flex;
  flex-direction: column;
  border: 1px solid #999;
  background: #fff;
  margin-right: 0px;
}
.grid-row {
  display: flex;
}
.grid-cell {
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  align-items: center;
  align-content: center;
  text-align: center;
}
.grid-cell.active {
  background-color: #4CAF50;
}
</style>