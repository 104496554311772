<template>
    <div>
      <h2>General Settings</h2>
      <div v-if="isLoading">
        <p>Loading...</p>
      </div>
      <GeneralPageServer />
      <GeneralPageConfigs ref="child" />
      <GeneralPageSettings ref="child" />
    </div>
  </template>
  
<script>
  import GeneralPageServer from './GeneralPageServer.vue'
  import GeneralPageConfigs from './GeneralPageConfigs.vue'
  import GeneralPageSettings from './GeneralPageSettings.vue'

  export default {
    name: 'GeneralPage',
    components: {
      GeneralPageConfigs,
      GeneralPageSettings,
      GeneralPageServer,
    },
    data() {
      return {
        isLoading: false,
      };
    },
    methods: {
      updateIsLoading(loading) {
        this.isLoading = loading;
      },
    }
  };
  </script>
  
<style scoped>
  .page-content {
    padding: 20px;
  }
  .form-group {
    align-items: center;
    margin-bottom: 15px;
    background-color: #d0d0d0;
    padding: 10px;
  }
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .form-group input[type='number'],
  .form-group select,
  .form-group input[type='text'] {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .form-group input[type='range'] {
    width: 100%;
  }
  .group-h {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    gap: 10px;
  }
  .group-h label {
    display: flex;
  }
  .group-h button {
    font-size: 13px;
    height: 18px;
  }
  .group-h input[type='number'] {
    width: 100px;
  }
  .group-h input[type='text'] {
    width: 250px;
  }
  .group-h select {
    width: 200px;
  }

  .group-v {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: left;
    gap: 10px
  }
  .group-v label {
    display: flex;
  }
  .group-v input[type='number'] {
    width: 100px;
  }
  .group-v input[type='text'] {
    width: 250px;
  }
  .group-v select {
    width: 250px;
  }
  
  .long-select {
    width: 300px;
  }

  .separator {
    border: none;
    border-top: 1px solid #000000;
    margin: 10px 0;
  }

  .button {
    width: auto;
    white-space: nowrap;
    padding: 5px 10px;
  }
</style>