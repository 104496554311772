<template>
  <div class="page-content">
    <h2>Bet Settings</h2>
    <div v-if="isLoading">
      <p>Loading...</p>
    </div>
    <form v-else @submit.prevent>
      <!-- Bet Per Line Default Index -->
      <div class="form-group">
        <label for="betPerLineDefaultIndex">Bet Per Line Default</label>
        <input
          type="number"
          id="betPerLineDefaultIndex"
          v-model.number="betPerLineDefaultIndex"
          :min="1"
          :max="betsPerLine.length"
        />
        <input
          type="range"
          v-model.number="betPerLineDefaultIndex"
          :min="1"
          :max="betsPerLine.length"
        />
      </div>

      <!-- Bets Per Line -->
      <div class="form-group">
        <label>Bets Per Line</label>
        <div class="bets-per-line">
          <div
            v-for="(bet, index) in betsPerLine"
            :key="bet.value + '-' + index"
            class="bet-item"
          >
            <input
              type="number"
              v-model.number="bet.value"
              :min="1"
              :max="1000"
              @change="validateBet(index)"
            />
            <input
              type="checkbox"
              v-model="bet.canBet"
              :id="'canBet-' + index"
            />
            <label :for="'canBet-' + index">Can Bet</label>
            <button type="button" @click="removeBetPerLine(index)">
              Remove
            </button>
          </div>
          <button type="button" @click="addBetPerLine">
            Add Bet
          </button>
        </div>
      </div>

      <!-- Lines Played -->
      <div class="form-group">
        <label for="linesPlayed">Lines Played</label>
        <input
          type="number"
          id="linesPlayed"
          v-model.number="linesPlayed"
          :min="1"
          :max="payLines"
        />
        <input
          type="range"
          v-model.number="linesPlayed"
          :min="1"
          :max="payLines"
        />
      </div>

      <!-- Current values and Save button -->
      <div class="current-values-container">
        <!-- Current values -->
        <div class="current-values">
          <h3>Current values:</h3>
          <p>
            Bet Per Line Default:
            <span :class="{'changed': isChanged('betPerLineDefaultIndex'), 'same': !isChanged('betPerLineDefaultIndex')}">
              {{ betPerLineDefaultIndex }}
            </span>
            <span v-if="showComparison">/ {{ defaultValues.betPerLineDefaultIndex }}</span>
          </p>
          <p>
            Lines Played:
            <span :class="{'changed': isChanged('linesPlayed'), 'same': !isChanged('linesPlayed')}">
              {{ linesPlayed }}
            </span>
            <span v-if="showComparison">/ {{ defaultValues.linesPlayed }}</span>
          </p>
          <div v-for="(bet, index) in betsPerLine" :key="index">
            <p>
              Bet {{ index + 1 }}:
              <span :class="{'changed': isChangedBet('betsPerLine', index), 'same': !isChangedBet('betsPerLine', index)}">
                Value: {{ bet.value }}
              </span>
              <span v-if="showComparison" :class="{'changed': !defaultValues.betsPerLine[index]}">
                / {{ defaultValues.betsPerLine[index] ? defaultValues.betsPerLine[index].value : 'N/A' }}
              </span>
              <span :class="{'changed': isChangedBet('betsPerLine', index), 'same': !isChangedBet('betsPerLine', index)}">
                Can Bet: {{ bet.canBet ? 'Yes' : 'No' }}
              </span>
              <span v-if="showComparison" :class="{'changed': !defaultValues.betsPerLine[index]}">
                / {{ defaultValues.betsPerLine[index] ? (defaultValues.betsPerLine[index].canBet ? 'Yes' : 'No') : 'N/A' }}
              </span>
            </p>
          </div>
        </div>

        <!-- Save Button -->
        <div class="buttons">
          <button type="button" @click="fetchDefaultValues">Refresh from Server</button>
          <button type="button" @click="saveSettings">Save settings</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { auth } from '@/js/auth.js';
import { statusCodes } from '@/data/statusCodes.js';

export default {
  name: 'BetSettings',
  data() {
    return {
      isLoading: true,
      defaultValues: {},
      showComparison: true,

      betsPerLine: [],
      betPerLineDefaultIndex: 1,
      linesPlayed: 10,
      payLines: 11,
    };
  },
  watch: {
    betPerLineDefaultIndex(newVal) {
      if (newVal < 1) this.betPerLineDefaultIndex = 1;
      if (newVal > this.betsPerLine.length) this.betPerLineDefaultIndex = this.betsPerLine.length;
    },
    linesPlayed(newVal) {
      if (newVal < 1) this.linesPlayed = 1;
      if (newVal > this.payLines) this.linesPlayed = this.payLines;
    },
  },
  methods: {
    fetchDefaultValues() {
      if (this.$root.getCurrentConfigId() === null) {
        this.$root.handleError('Empty getCurrentConfigId() variable!');
      } else {
        fetch(`${this.$root.envVars.api}/api/admin2/basic/bet/read?configId=${this.$root.getCurrentConfigId()}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
          })
          .then(response => response.json())
          .then(data => {
            const betInfo = data.betInfo;
            this.betPerLineDefaultIndex = betInfo.betPerLineDefaultIndex;
            this.linesPlayed = betInfo.linesPlayed;
            this.payLines = data.payLines;

            this.betsPerLine = betInfo.betsPerLine.map(bet => ({ ...bet }));

            this.defaultValues = {
              betPerLineDefaultIndex: betInfo.betPerLineDefaultIndex,
              linesPlayed: betInfo.linesPlayed,
              betsPerLine: betInfo.betsPerLine.map(bet => ({ ...bet })),
            };
            this.isLoading = false;
            this.$root.handleSuccess('Data loaded!');
          })
          .catch(error => {
            this.$root.handleError('Error fetching data from server - ' + error);
          });
      }
    },
    isChanged(field) {
      if (!this.showComparison) {
        return false;
      }
      return this[field] !== this.defaultValues[field];
    },
    isChangedBet(field, index) {
      if (!this.showComparison) {
        return false;
      }
      const defaultBet = this.defaultValues[field] && this.defaultValues[field][index];
      if (!defaultBet) {
        return false;
      }
      return (
        this[field][index].value !== defaultBet.value ||
        this[field][index].canBet !== defaultBet.canBet
      );
    },
    saveSettings() {
      if (this.$root.getCurrentConfigId() === null) {
        this.$root.handleError('Empty getCurrentConfigId() variable!');
        return;
      }

      const payload = {
        betsPerLine: this.betsPerLine,
        betPerLineDefaultIndex: this.betPerLineDefaultIndex,
        linesPlayed: this.linesPlayed,
      };

      fetch(`${this.$root.envVars.api}/api/admin2/basic/bet/write?configId=${this.$root.getCurrentConfigId()}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.autToken()}`
        },
        body: JSON.stringify(payload),
      })
        .then(response => {
          if (!response.ok) {
            if (response.status === statusCodes.StatusTokenIdMismatch) {
              auth.readWriteSet("");
              throw new Error('Ask for Write permissions');
            }
            throw new Error('Network response was not ok');
          }
        })
        .then(() => {
          this.$root.handleSuccess('Data sent to server!');
          this.defaultValues = {
              betPerLineDefaultIndex: this.betPerLineDefaultIndex,
              linesPlayed: this.linesPlayed,
              betsPerLine: this.betsPerLine.map(bet => ({ ...bet })),
            };
        })
        .catch(error => {
          this.$root.handleError(error);
        });
    },
    addBetPerLine() {
      this.betsPerLine.push({ value: 1, canBet: true });
      if (this.betPerLineDefaultIndex > this.betsPerLine.length) {
        this.betPerLineDefaultIndex = this.betsPerLine.length;
      }
    },
    removeBetPerLine(index) {
      this.betsPerLine.splice(index, 1);
      if (this.betPerLineDefaultIndex > this.betsPerLine.length) {
        this.betPerLineDefaultIndex = this.betsPerLine.length || 1;
      }
    },
    validateBet(index) {
      if (this.betsPerLine[index].value < 1) {
        this.betsPerLine[index].value = 1;
      }
      if (this.betsPerLine[index].value > 1000) {
        this.betsPerLine[index].value = 1000;
      }
    },
  },
  mounted() {
    this.fetchDefaultValues();
  },
};
</script>

<style scoped>
.page-content {
  position: relative;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: #d0d0d0;
  padding: 10px;
}

.form-group label {
  width: 150px;
  font-weight: bold;
}

.form-group input[type='number'],
.form-group select {
  width: 200px;
  margin-right: 10px;
}

input[type='range'] {
  flex: 1;
}

.current-values-container {
  display: flex;
  align-items: flex-start;
}

.current-values {
  flex: 1;
  background-color: #f9f9f9;
  padding: 10px;
}

.current-values p {
  margin: 5px 0;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 20px;
}

.buttons button {
  margin-bottom: 10px;
  padding: 10px 20px;
}

.bets-per-line {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bets-per-line button {
  padding: 5px 10px;
  width: 160px;
}

.bet-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.bet-item input[type='number'] {
  width: 100px;
  margin-right: 10px;
}

.bet-item input[type='checkbox'] {
  margin-right: 5px;
}

.bet-item button {
  padding: 5px 10px;
  width: 80px;
}
</style>