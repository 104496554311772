<template>
  <div id="app">
    <!-- Global floating error message -->
    <transition name="fade">
      <div v-if="showErrorMessage" class="floating-message-error">
        {{ errorMessage }}
      </div>
    </transition>

    <!-- Global floating success message -->
    <transition name="fade">
      <div v-if="showSuccessMessage" class="floating-message-success">
        {{ successMessage }}
      </div>
    </transition>

    <ConfirmationDialog
      v-if="dialogConfig.show"
      :title="dialogConfig.title"
      :content="dialogConfig.content" 
      :onConfirm="dialogConfig.onConfirm"
      :onClose="dialogConfig.onClose"
    />

    <!-- Top Bar -->
    <TopBar/>

    <!-- Main container -->
    <div class="container">
      <!-- Side Menu -->
      <SideMenu @select-page="selectPage" />

      <!-- Content -->
      <div class="content">
        <component 
          :is="currentPage" 
          :key="currentPage !== 'GeneralPage' ? `${currentPage}-${getCurrentConfigId()}` : currentPage"></component>
      </div>
    </div>
  </div>
</template>

<script>
import { globalData } from './data/globalData';
import { auth } from '@/js/auth.js';

import ConfirmationDialog from './components/pages/shared/ConfirmationDialog.vue';

import TopBar from './components/layout/TopBar.vue';
import SideMenu from './components/layout/SideMenu.vue';

import UserPage from './components/pages/general/UserPage.vue';
import GeneralPage from './components/pages/general/settings/GeneralPage.vue';
import CDNPage from './components/pages/cdn/CDNPage.vue';

import ReelSettings from './components/pages/basic/ReelSettings.vue';
import BetSettings from './components/pages/basic/BetSettings.vue';
import TimingSettings from './components/pages/basic/TimingSettings.vue';

import PayPage from './components/pages/pays/PayPage.vue';
import PayLinePage from './components/pages/pays/lines/PayLinePage.vue';
import PayTablePage from './components/pages/pays/table/PayTablePage.vue';
import PayTableScattersPage from './components/pages/pays/scatters/PayTableScattersPage.vue';

import MathCompute from './components/pages/basic/MathCompute.vue';

import SymbolsPage from './components/pages/symbols/SymbolsPage';
import SymbolsParSheetPage from './components/pages/parsheet/SymbolsParSheetPage.vue';

import LayoutBaseSettings from './components/pages/layout/LayoutBaseSettings.vue';
import LayoutDynImageSettings from './components/pages/layout/LayoutDynImageSettings.vue';
import LayoutDynAnimSettings from './components/pages/layout/LayoutDynAnimSettings.vue';

export default {
  name: 'App',
  components: {
    ConfirmationDialog,

    TopBar,
    SideMenu,
    UserPage,
    CDNPage,
    GeneralPage,
    
    ReelSettings,
    BetSettings,
    TimingSettings,

    SymbolsPage,
    SymbolsParSheetPage,

    LayoutBaseSettings,
    LayoutDynImageSettings,
    LayoutDynAnimSettings,

    PayPage,
    PayLinePage,
    PayTablePage,
    PayTableScattersPage,

    MathCompute,
  },
  data() {
    return {
        ...globalData, 
        
        currentPage: auth.checkAuth() ? 'GeneralPage' : 'UserPage',

        showErrorMessage: false,
        errorMessage: 'default error',

        showSuccessMessage: false,
        successMessage: 'default success',

        dialogConfig: {
          show: false,
          title: '',
          content: '',
          onConfirm: () => {},
          onClose: () => {}
        }
      }
  },
  methods: {
    getCurrentConfigId() {
      return this.settings.currentConfigId;
    },
    setCurrentConfigId(configId) {
      this.settings.currentConfigId = configId;
    },
    
    getServerConfigId() {
      return this.settings.serverConfigId;
    },
    setServerConfigId(configId) {
      this.settings.serverConfigId = configId;
    },
    isParSheetEnabled() {
      return this.$root.settings.currentConfigHasParSheetEnabled;
    },    

    getCdnPath() {
      return this.envVars.cdn + this.envVars.cdnapp;
    },
    selectPage(page) {
      this.currentPage = page;
    },
    handleError(message) {
      this.errorMessage = message;
      console.error(message);
      this.showErrorMessage = true;

      setTimeout(() => {
        this.showErrorMessage = false;
      }, 5000);
    },
    handleSuccess(message) {
      this.successMessage = message;
      // console.log('App: ', message);
      this.showSuccessMessage = true;

      setTimeout(() => {
        this.showSuccessMessage = false;
      }, 2500);
    },
    async getFileSize(url) {
      try {
        const response = await fetch(url, { method: 'HEAD' });
        if (response.ok) {
          const contentLength = response.headers.get('Content-Length');
          if (contentLength) {
            return `${(contentLength / 1024).toFixed(2)}`;
          } else {
            return '-';
          }
        }
        return '-';
      }
      catch {
        return '-';
      }
    },
    showTooltipInfo(message, good = true) {
      let tooltip = document.getElementById('tooltip-info');
      
      if (!tooltip) {
        tooltip = document.createElement('div');
        tooltip.id = 'tooltip-info';
        tooltip.className = 'tooltip';
        tooltip.style.whiteSpace = "pre-line";
        tooltip.style.position = 'fixed';
        tooltip.style.zIndex = '1000';
        tooltip.style.backgroundColor = good ? '#1d854d' : '#672626';
        document.body.appendChild(tooltip);
      }

      tooltip.textContent = message;
      
      this.handleMouseMove = (e) => {
        tooltip.style.left = e.clientX + 10 + 'px';
        tooltip.style.top = e.clientY + 10 + 'px';
      };

      document.addEventListener('mousemove', this.handleMouseMove);
    },
    hideTooltipInfo(){
      let tooltip = document.getElementById('tooltip-info');
      document.removeEventListener('mousemove', this.handleMouseMove);
        if (tooltip && tooltip.parentNode) {
          document.body.removeChild(tooltip);
        }
    },
  },
  mounted() {
    this.currentPage = auth.checkAuth() ? 'GeneralPage' : 'UserPage';
  },
};
</script>

<style>

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.container {
  display: flex;
  height: calc(100vh - 50px);
}

.content {
  flex: 1;
  padding: 20px;
}

.floating-message-error {
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f44336;
  color: white;
  padding: 15px 30px;
  border-radius: 5px;
  opacity: 0.9;
  z-index: 1000;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.floating-message-success {
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1d854d;
  color: white;
  padding: 15px 30px;
  border-radius: 5px;
  opacity: 0.9;
  z-index: 1000;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.changed {
  color: red;
}
.same {
  color: green;
}
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}
.tooltip {
  position: absolute;
  top: 40px;
  left: 0;
  background-color: #1d854d;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  width: flex;
  text-align: center;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.panel-content {
    margin-bottom: 5px;
    background-color: #f8f8f8;
    padding: 10px;
  }

.clickable-div {
  cursor: pointer;
  position: relative;
  top: -10px;
  left: -5px;
}
</style>