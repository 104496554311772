import { getFilename } from "./helpers";

export async function cdn_listAllIn(path, baseUrl, restype) {
    try {
      const response = await fetch(`${baseUrl}/listAllIn/${path}/${restype}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      const fileNames = result.urls.map(filePath => {
            if (restype === 'anims') {
                let e = getFilename(filePath);
                if (e.includes('.atlas')) {
                    e = getFilename(e);
                }
                return e;
            }
            return getFilename(filePath);
      });

      return Array.from(new Set(fileNames));
    } catch (error) {
      console.error("Error in listAllIn", error);
      throw error;
    }
  }