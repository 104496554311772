<template>
  <div class="page-content">
    <div class="clickable-div" @click="isExpandedPanel = !isExpandedPanel">
      <label>{{ isExpandedPanel ? '▼' : '▶'  }} Pay Lines {{ isExpandedPanel ? '' : '[CLOSED]' }}</label>
    </div>

    <div class="form-group" v-if="isExpandedPanel">
      <div v-for="(line, index) in $parent.payLinesInfo.lines" :key="index" class="group-v">
        <div class="group-h">
          <label class="line-header">Line #{{ index + 1 }}</label>
          <PayLineGridComponent 
            @cell-updated="updateCellValue"
            :index="index"
            :line="line"
            :min-position="minPosition()"
            :grid-height="gridHeight()"
          />
          <div v-for="(position, pIndex) in line.positions" :key="pIndex" class="position-item">
            <select v-model.number="$parent.payLinesInfo.lines[index].positions[pIndex]">
              <option v-for="(option, oIndex) in clampOptions" :key="oIndex" :value="option">
                {{ option }}
              </option>
            </select>
          </div>
          <div class="group-h">
            <button @click.prevent="decrementAll(index)">-</button>
            <button @click.prevent="incrementAll(index)">+</button>
            <button @click="removeLine(index)">Remove</button>
          </div>
        </div>
      </div>

      <div class="add-remove">
        <button @click.prevent="addLine">Add Pay Line</button>
        <button @click.prevent="removeLastLine" :disabled="$parent.payLinesInfo.lines.length === 0">Remove Last</button>
      </div>
    </div>
  </div>
</template>

<script>
import PayLineGridComponent from './PayLineGridComponent.vue';
export default {
  name: 'PayLineComponent',
  data() {
    return {
      clampOptions: [],
      isExpandedPanel: true
    };
  },
  components: {
    PayLineGridComponent
  },
  computed: {
    reelHeight() {
      return this.$parent.reelHeight;
    }
  },
  methods: {
    updateCellValue(params) {
      this.$parent.payLinesInfo.lines[params.lineIndex].positions[params.colIndex] = params.newValue;
    },
    minPosition() {
      let minPos = Number.MAX_VALUE;
      this.$parent.payLinesInfo.lines.forEach(line => {
        const min = Math.min(...line.positions);
        if (min < minPos) minPos = min;
      });
      return minPos;
    },
    maxPosition() {
      let maxPos = Number.MIN_VALUE;
      this.$parent.payLinesInfo.lines.forEach(line => {
        const max = Math.max(...line.positions);
        if (max > maxPos) maxPos = max;
      });
      return maxPos;
    },
    gridHeight() {
      let minPos = Number.MAX_VALUE;
      let maxPos = Number.MIN_VALUE;
      
      // Search through all lines
      this.$parent.payLinesInfo.lines.forEach(line => {
        const min = Math.min(...line.positions);
        const max = Math.max(...line.positions);
        if (min < minPos) minPos = min;
        if (max > maxPos) maxPos = max;
      });
      
      return maxPos - minPos + 1;
    },
    addLine() {
      const newLine = {
        positions: Array(this.$parent.numberOfReels).fill(this.$parent.$parent.reelIndent)
      };
      this.$parent.payLinesInfo.lines.push(newLine);
    },
    removeLine(index) {
      this.$parent.payLinesInfo.lines.splice(index, 1);
    },
    removeLastLine() {
      const last = this.$parent.payLinesInfo.lines.length - 1;
      this.removeLine(last);
    },
    incrementAll(index) {
      this.$parent.payLinesInfo.lines[index].positions = this.$parent.payLinesInfo.lines[index].positions.map(pos => pos + 1);
      this.clampPosition(index);
    },
    decrementAll(index) {
      this.$parent.payLinesInfo.lines[index].positions = this.$parent.payLinesInfo.lines[index].positions.map(pos => pos - 1);
      this.clampPosition(index);
    },
    clampPosition(index) {
      this.$parent.payLinesInfo.lines[index].positions = this.$parent.payLinesInfo.lines[index].positions.map(pos => {
        if (pos < this.$parent.reelIndent) return this.$parent.reelIndent;
        if (pos > (this.$parent.reelHeight - this.$parent.reelIndent)) return this.$parent.reelHeight - this.$parent.reelIndent;
        return pos;
      });
    },
    generateClampOptions() {
      this.clampOptions = [];
      for (let i = this.$parent.reelIndent; i < (this.$parent.reelHeight - this.$parent.reelIndent); i++) {
        this.clampOptions.push(i.toString());
      }
    }
  },
  mounted() {
    this.generateClampOptions();
  }
};
</script>

<style scoped>
.page-content {
  padding: 20px;
}
.form-group {
  align-items: center;
  margin-bottom: 15px;
  background-color: #d0d0d0;
  padding: 10px;
}
.group-h {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}
.group-h button {
  font-size: 13px;
  height: 18px;
}
.group-h select {
  width: 50px;
}
.group-v {
  display: flex;
  flex-direction: column;
  align-items: left;
}
.line-header {
  width: 75px;
}
.position-item {
  display: inline-block;
  margin-right: 5px;
}
.add-remove {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.payline-grid {
  display: flex;
  flex-direction: column;
  border: 1px solid #999;
  background: #fff;
  margin-right: 0px;
}
.grid-row {
  display: flex;
}
.grid-cell {
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  align-items: center;
  align-content: center;
  text-align: center;
}
.grid-cell.active {
  background-color: #4CAF50;
}
</style>