<template>
    <div v-if="visible" class="modal-overlay" @click.self="closeDialog">
      <div class="modal">
        <h3>{{ title }}</h3>
        <p style="white-space: pre-line">{{ content }}</p>
        <div class="actions">
          <button @click="confirmAction" class="button-c">OK</button>
          <button @click="closeDialog" class="button-c">Cancel</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      content: {
        type: String,
        required: true
      },
      onConfirm: {
        type: Function,
        required: true
      },
      onClose: {
        type: Function,
        required: true
      }
    },
    data() {
      return {
        visible: true
      };
    },
    methods: {
      confirmAction() {
        this.onConfirm();
        this.closeDialog();
      },
      closeDialog() {
        this.visible = false;
        this.onClose();
      }
    }
  };
  </script>
  
  <style>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
  }
  
  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }
  
  .button-c button {
    padding: 8px 16px;
    border: none;
    cursor: pointer;
  }
  
  .button-c button:hover {
    background-color: #f2f2f2;
  }
  </style>