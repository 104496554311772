export const auth = {
    isAuthenticated: false,
    login(token) {
        this.isAuthenticated = true;
        localStorage.setItem('auth_token', token);
    },
    logout() {
        this.isAuthenticated = false;
        localStorage.removeItem('auth_token');
    },
    
    checkAuth() {
        const ret = !!localStorage.getItem('auth_token');
        return ret;
    },
    autToken() {
        return localStorage.getItem('auth_token');
    },

    authAsSet(email) {
        return localStorage.setItem('auth_as', email);
    },
    authAs() {
        return localStorage.getItem('auth_as');
    },

    readWriteSet(email) {
        return localStorage.setItem('auth_rw_as', email);
    },
    readWrite() {
        return localStorage.getItem('auth_rw_as');
    },
};
