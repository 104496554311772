<template>
  <div class="page-content">
    <h2>Reel Settings</h2>
    <div v-if="isLoading">
      <p>Loading...</p>
    </div>
    <form v-else @submit.prevent>
      <!-- Reel Count -->
      <div class="form-group">
        <label for="reelCount">Reel Count</label>
        <input
          type="number"
          id="reelCount"
          v-model.number="reelCount"
          :min="3"
          :max="10"
        />
        <input
          type="range"
          v-model.number="reelCount"
          :min="3"
          :max="10"
        />
      </div>

      <!-- Symbols per Reel -->
      <div class="form-group">
        <label for="symbolsPerReel">Symbols per Reel</label>
        <input
          type="number"
          id="symbolsPerReel"
          v-model.number="symbolsPerReel"
          :min="3"
          :max="20"
        />
        <input
          type="range"
          v-model.number="symbolsPerReel"
          :min="3"
          :max="20"
        />
      </div>

      <!-- Symbol Indent -->
      <div class="form-group">
        <label for="symbolIndent">Symbol Indent</label>
        <input
          type="number"
          id="symbolIndent"
          v-model.number="symbolIndent"
          :min="0"
          :max="maxSymbolIndent"
        />
        <input
          type="range"
          v-model.number="symbolIndent"
          :min="0"
          :max="maxSymbolIndent"
        />
      </div>

      <!-- Reel Padding -->
      <div class="form-group">
        <label for="reelPadding">Reel Padding</label>
        <input
          type="number"
          id="reelPadding"
          v-model.number="reelPadding"
          :min="0"
          :max="100"
        />
      </div>

      <!-- Symbol Padding -->
      <div class="form-group">
        <label for="symbolPadding">Symbol Padding</label>
        <input
          type="number"
          id="symbolPadding"
          v-model.number="symbolPadding"
          :min="0"
          :max="100"
        />
      </div>

      <!-- Reels Snapping -->
      <div class="form-group">
        <label for="reelsSnapping">Reels Snapping</label>
        <select id="reelsSnapping" v-model="reelsSnapping">
          <option value=0>None</option>
          <option value=1>Snap All</option>
          <option value=2>Snap Per Reel</option>
        </select>
      </div>

      <!-- Current values and buttons -->
      <div class="current-values-container">
        <!-- Current values -->
        <div class="current-values">
          <h3>Current values:</h3>
          <p>
            Reel Count: 
            <span :class="{'changed': isChanged('reelCount'), 'same': !isChanged('reelCount') && showComparison}">
              {{ reelCount }}
            </span>
            <span v-if="showComparison">/ {{ defaultValues.reelCount }}</span>
          </p>
          <!-- Repetă pentru celelalte câmpuri -->
          <p>
            Symbols per Reel: 
            <span :class="{'changed': isChanged('symbolsPerReel'), 'same': !isChanged('symbolsPerReel') && showComparison}">
              {{ symbolsPerReel }}
            </span>
            <span v-if="showComparison">/ {{ defaultValues.symbolsPerReel }}</span>
          </p>
          <p>
            Symbol Indent: 
            <span :class="{'changed': isChanged('symbolIndent'), 'same': !isChanged('symbolIndent') && showComparison}">
              {{ symbolIndent }}
            </span>
            <span v-if="showComparison">/ {{ defaultValues.symbolIndent }}</span>
          </p>
          <p>
            Reel Padding: 
            <span :class="{'changed': isChanged('reelPadding'), 'same': !isChanged('reelPadding') && showComparison}">
              {{ reelPadding }}
            </span>
            <span v-if="showComparison">/ {{ defaultValues.reelPadding }}</span>
          </p>
          <p>
            Symbol Padding: 
            <span :class="{'changed': isChanged('symbolPadding'), 'same': !isChanged('symbolPadding') && showComparison}">
              {{ symbolPadding }}
            </span>
            <span v-if="showComparison">/ {{ defaultValues.symbolPadding }}</span>
          </p>
          <p>
            Reels Snapping: 
            <span :class="{'changed': isChanged('reelsSnapping'), 'same': !isChanged('reelsSnapping') && showComparison}">
              {{ reelsSnapping }}
            </span>
            <span v-if="showComparison">/ {{ defaultValues.reelsSnapping }}</span>
          </p>
        </div>

        <!-- Buttons -->
        <div class="buttons">
          <button type="button" @click="fetchDefaultValues">Refresh from Server</button>
          <button type="button" @click="saveSettings">Save settings</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { auth } from '@/js/auth.js';
import { statusCodes } from '@/data/statusCodes.js';

export default {
  name: 'ReelSettings',
  data() {
    return {
      isLoading: true,
      reelCount: 5,
      symbolsPerReel: 7,
      symbolIndent: 1,
      reelPadding: 0,
      symbolPadding: 0,
      reelsSnapping: 0,
      defaultValues: {},
      showComparison: true,
    };
  },
  computed: {
    maxSymbolIndent() {
      const adjustedReelCount = this.reelCount % 2 === 0 ? this.reelCount - 1 : this.reelCount;
      return Math.floor(adjustedReelCount / 2);
    },
  },
  watch: {
    reelCount() {
      if (this.symbolIndent > this.maxSymbolIndent) {
        this.symbolIndent = this.maxSymbolIndent;
      }
    },
  },
  methods: {
    fetchDefaultValues() {
      if (this.$root.getCurrentConfigId() === null) {
        this.$root.handleError('Empty getCurrentConfigId() variable!');
      } else {
        fetch(`${this.$root.envVars.api}/api/admin2/basic/reel/read?configId=${this.$root.getCurrentConfigId()}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
          })
          .then(response => response.json())
          .then(data => {
            this.reelCount = data.numberOfReels;
            this.symbolsPerReel = data.reelHeight;
            this.symbolIndent = data.reelIndent;
            this.reelPadding = data.horizontalReelPaddingPercent;
            this.symbolPadding = data.verticalSymbolPaddingPercent;
            this.reelsSnapping = data.snapping;
            this.isLoading = false;

            this.defaultValues = {
              reelCount: this.reelCount,
              symbolsPerReel: this.symbolsPerReel,
              symbolIndent: this.symbolIndent,
              reelPadding: this.reelPadding,
              symbolPadding: this.symbolPadding,
              reelsSnapping: this.reelsSnapping,
            };

            this.$root.handleSuccess('Data loaded!');
          })
          .catch(error => {
            this.$root.handleError('Error fetching data from server - ' + error);
          });
      }
    },
    isChanged(field) {
      return this[field] !== this.defaultValues[field];
    }, 
    saveSettings() {
      if (this.$root.getCurrentConfigId() === null) {
        this.$root.handleError('Empty getCurrentConfigId() variable!');
      } else {
        const payload = {
          numberOfReels: this.reelCount,
          reelHeight: this.symbolsPerReel,
          reelIndent: this.symbolIndent,
          horizontalReelPaddingPercent: this.reelPadding,
          verticalSymbolPaddingPercent: this.symbolPadding,
          snapping: this.reelsSnapping,
        }; 

        fetch(`${this.$root.envVars.api}/api/admin2/basic/reel/write?configId=${this.$root.getCurrentConfigId()}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
            body: JSON.stringify(payload),
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
          })
          .then(() => {
            this.$root.handleSuccess('Data sent to server!');
            this.defaultValues = {
              reelCount: this.reelCount,
              symbolsPerReel: this.symbolsPerReel,
              symbolIndent: this.symbolIndent,
              reelPadding: this.reelPadding,
              symbolPadding: this.symbolPadding,
              reelsSnapping: this.reelsSnapping,
            };
          })
          .catch(error => {
            this.$root.handleError(error);
          });
      }
    },
  },
  mounted() {
    this.fetchDefaultValues();
  },
};
</script>

<style scoped>
.page-content {
  position: relative;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: #d0d0d0;
  padding: 10px;
}

.form-group label {
  width: 150px;
  font-weight: bold;
}

.form-group input[type='number'],
.form-group select {
  width: 200px;
  margin-right: 10px;
}

input[type='range'] {
  flex: 1;
}

.current-values-container {
  display: flex;
}

.current-values {
  flex: 1;
  background-color: #f9f9f9;
  padding: 10px;
}

.current-values p {
  margin: 5px 0;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 20px;
}

.buttons button {
  margin-bottom: 10px;
  padding: 10px 20px;
}

.loading-message {
  font-size: 18px;
  color: #555;
}
</style>