<template>
    <div>
      <h2>User Page</h2>
      <button v-if="!isAuthenticated" @click="handleGoogleLogin">Login with Google</button>
      <button v-if="isAuthenticated" @click="handleGoogleLogout">Logout</button>
    </div>
  </template>
  
<script>
  import { auth } from '@/js/auth.js';
  import { statusCodes } from '@/data/statusCodes.js';

  export default {
    name: 'UserPage',
    components: {
    },
    data() {
      return {
        clientId: '339928696075-elru49bnnees2kbjk5o47all4ffvhlq1.apps.googleusercontent.com',
        isAuthenticated: auth.checkAuth(),
      };
    },
    methods: {
      handleGoogleLogout() {
        auth.logout();
        window.location.reload();
      },
      handleGoogleLogin() {
        // eslint-disable-next-line
        google.accounts.id.initialize({
          client_id: this.clientId,
          callback: this.handleCallbackResponse,
          ux_mode: 'redirect',
          login_uri: `${this.$root.envVars.api}/api/connect/google`,
        });

        // eslint-disable-next-line
        google.accounts.id.prompt();
      },
      handleCallbackResponse(response) {
        auth.logout();

        fetch(`${this.$root.envVars.api}/api/connect/google`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: response.credential,
        })
        .then(response => {
          if (!response.ok) {
            if (response.status === statusCodes.StatusTokenIdMismatch) {
              auth.readWriteSet("");
              throw new Error('Ask for Write permissions');
            }
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          auth.login(data.Token);
          auth.authAsSet(data.Email);
          this.$root.handleSuccess('Validating google login: done! ');
          window.location.reload();
        })
        .catch(error => {
          this.$root.handleError(error);
        });
      },
    },
  };
</script>

<style scoped>

</style>