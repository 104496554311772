<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="isSaving">
    Saving ...
  </div>
  <div v-else>
    <div class="group-v">
      <div class="group-h config-settings-margin">
        <label 
          class="normal-field" 
          :class="{'changed': isParSheetModified()}"
          @mouseover="isParSheetModified() && $root.showTooltipInfo(defaultValues.enabled ? 'Old value: Enabled' : 'Old value: Disabled', false)"
          @mouseleave="$root.hideTooltipInfo()">
          Enable PAR Sheet: 
        </label>
        <input 
          type="checkbox" 
          v-model="parSheet.enabled"
          @click.prevent="handleParSheetChange(); $event.target.checked = parSheet.enabled"
        />
        <button v-if="isParSheetModified()" @click="handleSaveStatusAction">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/js/auth.js';
import { statusCodes } from '@/data/statusCodes.js';

export default {
  name: 'ConfigSettingsParSheetComponent',
  props: {
    parSheet: Object,
    defaultValues: Object,
  },
  data() {
    return {
      isSaving: false,
    };
  },
  methods: {
    isParSheetModified() {
      return this.parSheet.enabled !== this.defaultValues.enabled;
    },
    handleSaveStatusAction() {
      this.$root.dialogConfig = {
        show: true,
        title: 'Save PAR Sheet Status',
        content: 'Are you sure you want to save the PAR sheet status?',
        onConfirm: () => {
          this.setParSheetStatus();
          this.$root.dialogConfig.show = false;
        },
        onClose: () => {
          this.$root.dialogConfig.show = false;
        }
      };
    },
    handleParSheetChange() {
      this.$root.dialogConfig = {
        show: true,
        title: 'Changing PAR Sheet Status',
        content: 'Are you sure you want to change the PAR sheet mode status?\n\nThis will affect all symbols.',
        onConfirm: () => {
          this.$parent.toggleParSheetStatusValue();
          this.$root.dialogConfig.show = false;
        },
        onClose: () => {
          
          this.$root.dialogConfig.show = false;
        }
      };
    },
    setParSheetStatus() {
      this.isSaving = true;
      const config = this.$root.getCurrentConfigId();
      fetch(`${this.$root.envVars.api}/api/admin2/configs/set/parsheet?configId=${config}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.autToken()}`
          },
          body: JSON.stringify(this.parSheet),
        })
        .then(response => {
          if (!response.ok) {
            if (response.status === statusCodes.StatusTokenIdMismatch) {
              auth.readWriteSet("");
              throw new Error('Ask for Write permissions');
            }
            throw new Error('Network response was not ok');
          }

          this.$parent.updateParSheetDefaultValues();
          this.isSaving = false;
          this.$root.handleSuccess('Data loaded!');
        })
        .catch(error => {
          this.$root.handleError('Error saving par sheet status:' + error);
          this.isSaving = false;
        });
    }
  }
}
</script>

<style scoped>
  .group-h {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    gap: 10px;
  }
  .config-settings-margin {
    margin-top: 10px;
  }
</style>
