<template>
  <div class="page-content">
    <h2>Timing Settings</h2>
    <div v-if="isLoading">
      <p>Loading...</p>
    </div>
    <form v-else @submit.prevent>
      <div class="form-group">
        <label for="spinTime">Spin Time (seconds)</label>
        <input
          type="number"
          id="spinTime"
          v-model.number="timings.spinTime"
          step="0.1"
          min="0"
        />
        <input
          type="range"
          v-model.number="timings.spinTime"
          step="0.1"
          min="0"
        />
      </div>

      <div class="form-group">
        <label for="spinTimeIncPerReel">Spin Time Increment Per Reel (seconds)</label>
        <input
          type="number"
          id="spinTimeIncPerReel"
          v-model.number="timings.spinTimeIncPerReel"
          step="0.1"
          min="0"
        />
        <input
          type="range"
          v-model.number="timings.spinTimeIncPerReel"
          step="0.1"
          min="0"
        />
      </div>

      <div class="form-group">
        <label for="easeOutTime">Ease Out Time (seconds)</label>
        <input
          type="number"
          id="easeOutTime"
          v-model.number="timings.easeOutTime"
          step="0.1"
          min="0"
        />
        <input
          type="range"
          v-model.number="timings.easeOutTime"
          step="0.1"
          min="0"
        />
      </div>

      <div class="form-group">
        <label for="reelOutEase">Reel Out Ease</label>
        <select id="reelOutEase" v-model.number="timings.reelOutEase">
          <option v-for="(label, value) in easeOptions" :key="value" :value="value">
            {{ label }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="showWinTime">Show Win Time (seconds)</label>
        <input
          type="number"
          id="showWinTime"
          v-model.number="timings.showWinTime"
          step="0.1"
          min="0"
        />
        <input
          type="range"
          v-model.number="timings.showWinTime"
          step="0.1"
          min="0"
        />
      </div>

      <div class="form-group">
        <label for="delayBetweenShowingWins">Delay Between Showing Wins (seconds)</label>
        <input
          type="number"
          id="delayBetweenShowingWins"
          v-model.number="timings.delayBetweenShowingWins"
          step="0.1"
          min="0"
        />
        <input
          type="range"
          v-model.number="timings.delayBetweenShowingWins"
          step="0.1"
          min="0"
        />
      </div>
      
      <div class="form-group">
        <label>
          <input type="checkbox" v-model="useDefaultSpinningSpeed" />
          Spinning Speed Per Reels
        </label>
        <div v-if="useDefaultSpinningSpeed">
          <div
            v-for="(speed, index) in timings.spinningSpeedPerReels"
            :key="index"
            class="reel-speed"
          >
            <label :for="'spinningSpeedReel' + index">Spin speed reel {{ index + 1 }}</label>
            <input
              type="number"
              :id="'spinningSpeedReel' + index"
              v-model.number="timings.spinningSpeedPerReels[index]"
              step="0.001"
              min="0"
            />
            <input
              type="range"
              v-model.number="timings.spinningSpeedPerReels[index]"
              step="0.001"
              min="0"
              max="10"
            />
            <!-- <button type="button" @click="removeSpinningSpeed(index)">Remove</button> -->
          </div>
          <!-- <button type="button" @click="addSpinningSpeed">Add Spinning Speed</button> -->
        </div>
        <div v-if="!useDefaultSpinningSpeed">
          <label for="spinningSpeed">Spinning Speed</label>
          <input
            type="number"
            id="spinningSpeed"
            v-model.number="timings.spinningSpeed"
            step="0.001"
            min="0"
          />
          <input
            type="range"
            v-model.number="timings.spinningSpeed"
            step="0.001"
            min="0"
            max="10"
          />
        </div>
      </div>

      <div class="form-group">
        <label for="easeInTime">Ease In Time (seconds)</label>
        <input
          type="number"
          id="easeInTime"
          v-model.number="timings.easeInTime"
          step="0.1"
          min="0"
        />
        <input
          type="range"
          v-model.number="timings.easeInTime"
          step="0.1"
          min="0"
        />
      </div>

      <div class="form-group">
        <label for="reelInEase">Reel In Ease</label>
        <select id="reelInEase" v-model.number="timings.reelInEase">
          <option v-for="(label, value) in easeOptions" :key="value" :value="value">
            {{ label }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="reelInDelayTime">Reel In Delay Time (seconds)</label>
        <input
          type="number"
          id="reelInDelayTime"
          v-model.number="timings.reelInDelayTime"
          step="0.1"
          min="0"
        />
        <input
          type="range"
          v-model.number="timings.reelInDelayTime"
          step="0.1"
          min="0"
        />
      </div>

      <div class="current-values-container">
        <div class="current-values">
          <h3>Current values:</h3>
          <p>
            Spin Time:
            <span :class="{'changed': isChanged('spinTime'), 'same': !isChanged('spinTime') && showComparison}">
              {{ timings.spinTime }}
            </span>
            <span v-if="showComparison">/ {{ defaultValues.spinTime }}</span>
          </p>
          <p>
            Spin Time Increment Per Reel:
            <span :class="{'changed': isChanged('spinTimeIncPerReel'), 'same': !isChanged('spinTimeIncPerReel') && showComparison}">
              {{ timings.spinTimeIncPerReel }}
            </span>
            <span v-if="showComparison">/ {{ defaultValues.spinTimeIncPerReel }}</span>
          </p>
          <p>
            Spinning Speed:
            <span :class="{'changed': isChanged('spinningSpeed'), 'same': !isChanged('spinningSpeed') && showComparison}">
              {{ timings.spinningSpeed }}
            </span>
            <span v-if="showComparison">/ {{ defaultValues.spinningSpeed }}</span>
          </p>
          <p>
            Ease Out Time:
            <span :class="{'changed': isChanged('easeOutTime'), 'same': !isChanged('easeOutTime') && showComparison}">
              {{ timings.easeOutTime }}
            </span>
            <span v-if="showComparison">/ {{ defaultValues.easeOutTime }}</span>
          </p>
          <p>
            Reel Out Ease:
            <span :class="{'changed': isChanged('reelOutEase'), 'same': !isChanged('reelOutEase') && showComparison}">
              {{ easeOptions[timings.reelOutEase] }}
            </span>
            <span v-if="showComparison">/ {{ easeOptions[defaultValues.reelOutEase] }}</span>
          </p>
          <p>
            Show Win Time:
            <span :class="{'changed': isChanged('showWinTime'), 'same': !isChanged('showWinTime') && showComparison}">
              {{ timings.showWinTime }}
            </span>
            <span v-if="showComparison">/ {{ defaultValues.showWinTime }}</span>
          </p>
          <p>
            Delay Between Showing Wins:
            <span :class="{'changed': isChanged('delayBetweenShowingWins'), 'same': !isChanged('delayBetweenShowingWins') && showComparison}">
              {{ timings.delayBetweenShowingWins }}
            </span>
            <span v-if="showComparison">/ {{ defaultValues.delayBetweenShowingWins }}</span>
          </p>
          <div v-for="(speed, index) in timings.spinningSpeedPerReels" :key="index">
            <p>
              Spinning Speed {{ index + 1 }}:
              <span :class="{'changed': isChangedSpinningSpeed(index), 'same': !isChangedSpinningSpeed(index) && showComparison}">
                {{ speed }}
              </span>
              <span v-if="showComparison">/ {{ defaultValues.spinningSpeedPerReels[index] || 'N/A' }}</span>
            </p>
          </div>
          <p>
            Ease In Time:
            <span :class="{'changed': isChanged('easeInTime'), 'same': !isChanged('easeInTime') && showComparison}">
              {{ timings.easeInTime }}
            </span>
            <span v-if="showComparison">/ {{ defaultValues.easeInTime }}</span>
          </p>
          <p>
            Reel In Ease:
            <span :class="{'changed': isChanged('reelInEase'), 'same': !isChanged('reelInEase') && showComparison}">
              {{ easeOptions[timings.reelInEase] }}
            </span>
            <span v-if="showComparison">/ {{ easeOptions[defaultValues.reelInEase] }}</span>
          </p>
          <p>
            Reel In Delay Time:
            <span :class="{'changed': isChanged('reelInDelayTime'), 'same': !isChanged('reelInDelayTime') && showComparison}">
              {{ timings.reelInDelayTime }}
            </span>
            <span v-if="showComparison">/ {{ defaultValues.reelInDelayTime }}</span>
          </p>
        </div>

        <div class="buttons">
          <button type="button" @click="fetchDefaultValues">Refresh from Server</button>
          <button type="button" @click="saveSettings">Save settings</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { auth } from '@/js/auth.js';
import { statusCodes } from '@/data/statusCodes.js';

export default {
  name: 'TimingSettings',
  data() {
    return {
      isLoading: true,
      timings: {
        spinTime: 1.0,
        spinTimeIncPerReel: 0.5,
        spinningSpeed: 0.302,
        easeOutTime: 0.5,
        reelOutEase: 24,
        showWinTime: 1.0,
        delayBetweenShowingWins: 0.2,
        spinningSpeedPerReels: [],
        easeInTime: 0.5,
        reelInEase: 26,
        reelInDelayTime: 0.4,
      },
      reelsNo: 5,
      defaultValues: {},
      showComparison: true,
      useDefaultSpinningSpeed: false,
      easeOptions: {
        0: "Unset",
        1: "Linear",
        2: "InSine",
        3: "OutSine",
        4: "InOutSine",
        5: "InQuad",
        6: "OutQuad",
        7: "InOutQuad",
        8: "InCubic",
        9: "OutCubic",
        10: "InOutCubic",
        11: "InQuart",
        12: "OutQuart",
        13: "InOutQuart",
        14: "InQuint",
        15: "OutQuint",
        16: "InOutQuint",
        17: "InExpo",
        18: "OutExpo",
        19: "InOutExpo",
        20: "InCirc",
        21: "OutCirc",
        22: "InOutCirc",
        23: "InElastic",
        24: "OutElastic",
        25: "InOutElastic",
        26: "InBack",
        27: "OutBack",
        28: "InOutBack",
        29: "InBounce",
        30: "OutBounce",
        31: "InOutBounce",
        32: "Flash",
        33: "InFlash",
        34: "OutFlash",
        35: "InOutFlash",
        36: "INTERNAL_Zero",
        37: "INTERNAL_Custom",
      },
    };
  },
  watch: {
    useDefaultSpinningSpeed(newVal) {
      if (newVal) {
        
        this.timings.spinningSpeedPerReels = Array(this.reelsNo).fill(0.01);
      } else {
        this.timings.spinningSpeedPerReels = [];
      }
    },
  },
  methods: {
    // addSpinningSpeed() {
    //   this.timings.spinningSpeedPerReels.push(0.0);
    // },
    // removeSpinningSpeed(index) {
    //   this.timings.spinningSpeedPerReels.splice(index, 1);
    // },
    fetchDefaultValues() {
      if (this.$root.getCurrentConfigId() === null) {
        this.$root.handleError('Empty getCurrentConfigId() variable!');
      } else {
        fetch(`${this.$root.envVars.api}/api/admin2/basic/timing/read?configId=${this.$root.getCurrentConfigId()}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
          })
          .then(response => response.json())
          .then(dataIn => {
            const { timing, reels } = dataIn;
            this.timings.spinTime = timing.spinTime;
            this.timings.spinTimeIncPerReel = timing.spinTimeIncPerReel;
            this.timings.spinningSpeed = timing.spinningSpeed;
            this.timings.easeOutTime = timing.easeOutTime;
            this.timings.reelOutEase = timing.reelOutEase;
            this.timings.showWinTime = timing.showWinTime;
            this.timings.delayBetweenShowingWins = timing.delayBetweenShowingWins;
            this.timings.spinningSpeedPerReels = timing.spinningSpeedPerReels || [];
            this.timings.easeInTime = timing.easeInTime;
            this.timings.reelInEase = timing.reelInEase;
            this.timings.reelInDelayTime = timing.reelInDelayTime;
            this.isLoading = false;
            this.reelsNo = reels;
            this.defaultValues = { ...this.timings };
            this.$root.handleSuccess('Data loaded!');
          })
          .catch(error => {
            this.$root.handleError('Error fetching data from server - ' + error);
          });
      }
    },
    isChanged(field) {
      return this.timings[field] !== this.defaultValues[field];
    },
    isChangedSpinningSpeed(index) {
      return this.timings.spinningSpeedPerReels[index] !== (this.defaultValues.spinningSpeedPerReels[index] || 0);
    },
    saveSettings() {
      if (this.$root.getCurrentConfigId() === null) {
        this.$root.handleError('Empty getCurrentConfigId() variable!');
      } else {
        fetch(`${this.$root.envVars.api}/api/admin2/basic/timing/write?configId=${this.$root.getCurrentConfigId()}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.autToken()}`
            },
            body: JSON.stringify(this.timings),
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === statusCodes.StatusTokenIdMismatch) {
                auth.readWriteSet("");
                throw new Error('Ask for Write permissions');
              }
              throw new Error('Network response was not ok');
            }
          })
          .then(() => {
            this.$root.handleSuccess('Data sent to server!');
            this.defaultValues = { ...this.timings };
          })
          .catch(error => {
            this.$root.handleError(error);
          });
      }
    },
  },
  mounted() {
    this.fetchDefaultValues();
  },
};
</script>

<style scoped>
.page-content {
  position: relative;
}
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: #d0d0d0;
  padding: 10px;
}
.form-group label {
  width: 200px;
  font-weight: bold;
}
.form-group input[type='number'],
.form-group select {
  width: 200px;
  margin-right: 10px;
  margin-left: 10px;
}
input[type='range'] {
  flex: 1;
}

.reel-speeds {
  display: flex;
  flex-direction: column;
}
.reel-speed {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.reel-speed label {
  margin-right: 10px;
  width: 150px;
  font-weight: bold;
}
.reel-speed input {
  margin-right: 10px;
  padding: 8px;
  width: 50px;
}
.reel-speed input[type='range'] {
  flex: 1;
  margin-right: 10px;
  padding: 8px;
  width: 200px;
}
.reel-speed button {
  padding: 5px 10px;
}

.current-values-container {
  display: flex;
}
.current-values {
  flex: 1;
  background-color: #f9f9f9;
  padding: 10px;
}
.current-values p {
  margin: 5px 0;
}
.buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 20px;
}
.buttons button {
  margin-bottom: 10px;
  padding: 10px 20px;
}
</style>