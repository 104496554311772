<template>
  <div class="page-content">
    <div class="clickable-div" @click="isExpandedPanel = !isExpandedPanel">
      <label>{{ isExpandedPanel ? '▼' : '▶'  }} Pay Table {{ isExpandedPanel ? '' : '[CLOSED]' }}</label>
    </div>

    <div class="form-group" v-if="isExpandedPanel">
      <div class="group-h">
        <button @click="showPayTableAsTree = !showPayTableAsTree">{{ showPayTableAsTree ? 'Display as Grid' : 'Display as Tree' }}</button>
        <span style="font-weight: bold"> Active view: {{ showPayTableAsTree ? 'Tree' : 'Grid' }}</span>
      </div>

      <PayTablePreviewComponent
        :symbols="$parent.symbolsInfo.symbolInfo"
        :number-of-reels="$parent.numberOfReels"
      />

      <PayTableTreeComponent 
        v-if="showPayTableAsTree"
        :symbols="$parent.symbolsInfo.symbolInfo"
        :number-of-reels="$parent.numberOfReels"
      />

      <PayTableGridComponent 
        v-if="!showPayTableAsTree"
        :symbols="$parent.symbolsInfo.symbolInfo"
        :number-of-reels="$parent.numberOfReels"
      />

    </div>
  </div>
</template>

<script>
import PayTableTreeComponent from './PayTableTreeComponent.vue';  
import PayTableGridComponent from './PayTableGridComponent.vue';  
import PayTablePreviewComponent from './PayTablePreviewComponent.vue';

export default {
  name: 'PayTableComponent',
  components: {
    PayTableTreeComponent,
    PayTableGridComponent,
    PayTablePreviewComponent
  },
  data() {
    return {
      isExpandedPanel: true,
      showPayTableAsTree: false
    };
  }
};
</script>

<style scoped>
  .page-content {
    padding: 20px;
  }
  .form-group {
    align-items: center;
    margin-bottom: 15px;
    background-color: #d0d0d0;
    padding: 10px;
  }
  .group-h {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    gap: 10px;
  }
</style>